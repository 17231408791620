import * as ActionTypes from './actionTypes';

export const restoreAuthAction: () => ActionTypes.IRestoreAuthAction = () => ({
  type: ActionTypes.AUTH_RESTORE,
});

export const restoreAuthFailAction: () => ActionTypes.IRestoreAuthFailedAction = () => ({
  type: ActionTypes.AUTH_RESTORE_FAIL,
});

export const updateUserAction: (
  userId: number,
  isAdmin: boolean,
  expires: number,
  session: string,
  tokenExpires: number,
  email?: string
) => ActionTypes.IUpdateUserAction = (userId, isAdmin, expires, session, tokenExpires, email) => ({
  type: ActionTypes.AUTH_UPDATE_USER,
  userId,
  isAdmin,
  expires,
  session,
  email,
  tokenExpires,
});

export const restoreAuthSuccessAction: () => ActionTypes.IRestoreAuthSucceedAction = () => ({
  type: ActionTypes.AUTH_RESTORE_SUCCESS,
});

export const logoutAction: () => ActionTypes.ILogoutAction = () => ({
  type: ActionTypes.AUTH_LOGOUT,
});

export const loginAction: () => ActionTypes.ILoginAction = () => ({
  type: ActionTypes.AUTH_LOGIN,
});

export const startTokenRefreshAction: (expires: number) => ActionTypes.IStartTokenRefreshAction = (expires) => ({
  type: ActionTypes.AUTH_TOKEN_REFRESH_START,
  expires,
});

export const refreshTokenAction: () => ActionTypes.IRefreshTokenAction = () => ({
  type: ActionTypes.AUTH_TOKEN_REFRESH,
});
