/**
 * Saga implementing messages related api calls using MessagesService
 */
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import { NotificationActions } from '../notifications';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import MessagesService from './messageService';
import * as Selectors from './selectors';

export function* handleServiceInit({ userId }: ActionTypes.IInitMessagesAction) {
  yield call(MessagesService.init, ApiService, userId);
}

export function* handleMessageSend() {
  try {
    const recepient = yield select(Selectors.getConversationUserId);
    const message = yield select(Selectors.getAdminMessageText);
    yield call(MessagesService.sendMessage, recepient, message);
    yield put(NotificationActions.showSuccess('Message sent successfully'));
  } catch (error) {
    yield put(NotificationActions.showError(`Message was not send: ${error.message}`));
  }
  yield put(Actions.openUserConversationAction());
}

export default function* messagesWatcher() {
  yield all([
    takeEvery(ActionTypes.MESSAGES_INIT, handleServiceInit),
    takeEvery(ActionTypes.MESSAGES_ADMIN_MESSAGE_SEND, handleMessageSend),
  ]);
}
