import * as ActionTypes from './actionTypes';
import { ICycleStatus } from './types';

export const initCycles: () => ActionTypes.IInitCyclesAction = () => ({
  type: ActionTypes.CYCLES_INIT,
});

export const loadCycles: (userId: number) => ActionTypes.ILoadCyclesAction = (userId) => ({
  type: ActionTypes.CYCLES_LOAD,
  userId,
});

export const updateCycleStats: (
  finishedCycles: number,
  finishedSessions: number,
  currentCycleId: number,
  currentCycleLastSessionId: number,
  currentCycleLength: number
) => ActionTypes.ICyclesStatsAction = (
  finishedCycles,
  finishedSessions,
  currentCycleId,
  currentCycleLastSessionId,
  currentCycleLength
) => ({
  type: ActionTypes.CYCLES_STATS,
  finishedCycles,
  finishedSessions,
  currentCycleId,
  currentCycleLastSessionId,
  currentCycleLength,
});

export const resetTraining: () => ActionTypes.IResetTrainingAction = () => ({
  type: ActionTypes.RESET_TRAINING,
});

export const loadSession: (cycleId: number, sessionId: number) => ActionTypes.ILoadSessionAction = (
  cycleId,
  sessionId
) => ({
  type: ActionTypes.LOAD_SESSION,
  cycleId,
  sessionId,
});

export const updateSession: (cycle: ICycleStatus) => ActionTypes.IUpdateSessionAction = (cycle) => ({
  type: ActionTypes.SESSION_UPDATE,
  cycle,
});
