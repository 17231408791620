import { CombinedState } from 'redux';
import { ICouponState, statePropName } from './reducer';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [statePropName]: ICouponState;
}

export const getCouponState = (state: IAppState) => state[statePropName];

export const isLoading = (state: IAppState) => getCouponState(state).loading;

export const isCouponSubmitted = (state: IAppState) => !!getCouponState(state).coupon;

export const getCoupon = (state: IAppState) => getCouponState(state).coupon;

export const isStatusPresent = (state: IAppState) => !!getCouponState(state).status;

export const getCouponStatus = (state: IAppState) => getCouponState(state).status;
