import { IApiService } from 'web_core_library';
import { TRAINING_ID } from './constants';
import { ICycleStatus, ICycleStatusApiResponse, ITrainingStatisticsApiResponse } from './types';

export function createCyclesService() {
  let ApiService: IApiService;

  function getUserStatistic(userId: number) {
    const filter = `trainingId/${TRAINING_ID}`;
    return ApiService.action<ITrainingStatisticsApiResponse>('training_statistics', {
      method: 'get',
      urlParams: {
        userId,
        filter,
      },
    });
  }

  function getCycleSession(userId: number, cycleId: number, sessionId: number) {
    const filter = `trainingId/${TRAINING_ID}/cycleId/${cycleId}/sessionId/${sessionId}`;
    return ApiService.action<ICycleStatusApiResponse>('training_cycles', {
      method: 'get',
      urlParams: {
        userId,
        filter,
      },
    });
  }

  // this call updates information about current/given cycle
  function updateCycles(userId: number, cycles: ICycleStatus[]) {
    return ApiService.action('training_cycles', {
      method: 'patch',
      urlParams: {
        userId,
      },
      data: {
        trainingcycles: cycles,
      },
    });
  }
  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getUserStatistic,
    getCycleSession,
    updateCycles,
  };
  return service;
}

const CyclesService = createCyclesService();
export default CyclesService;
