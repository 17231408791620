import { Callout } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import * as AuthSelectors from './selectors';

export interface IUserAccessWarningProps {
  isLoggedIn: boolean;
  isAdmin: boolean;
}

export function UserAccessWarning({ isLoggedIn, isAdmin }: IUserAccessWarningProps) {
  if (!isLoggedIn || isAdmin) {
    return null;
  }
  return (
    <Callout intent="danger" icon="error" title="Error!">
      You do not have enough rights to use this application!
    </Callout>
  );
}

export const mapStateToProps = (state: AuthSelectors.IAppState) => {
  const isAdmin = AuthSelectors.isUserAdmin(state);
  const isLoggedIn = AuthSelectors.isUserLoggedIn(state);
  return {
    isLoggedIn,
    isAdmin,
  };
};

export default connect(mapStateToProps)(UserAccessWarning);
