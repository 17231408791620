import { IOrderData, IAddress } from '../state/types/base';
import * as ActionTypes from './actionTypes';

export interface OrdersState {
  loading: boolean;
  orders: IOrderData[];
  selectedOrder: string | null;
  dispute: string | null;
  addresses?: IAddress[];
}

export const initialUserState: OrdersState = {
  loading: true,
  orders: [],
  selectedOrder: null,
  dispute: null,
};

export interface UsersOrdersState {
  [userId: number]: OrdersState;
}

export const initialState: UsersOrdersState = {};

export default function orders(state = initialState, action: ActionTypes.IOrdersActions) {
  switch (action.type) {
    case ActionTypes.ORDERS_GET_USER_ORDERS: {
      const userId = action.id;
      return {
        ...state,
        [userId]: {
          ...initialUserState,
        },
      };
    }
    case ActionTypes.ORDERS_GET_USER_ORDERS_SUCCESS: {
      const userId = action.id;
      return {
        ...state,
        [userId]: {
          ...initialUserState,
          loading: false,
          orders: action.orders,
        },
      };
    }
    case ActionTypes.ORDERS_GET_USER_ORDERS_FAILED: {
      const userId = action.id;
      return {
        ...state,
        [userId]: {
          ...initialUserState,
          loading: false,
        },
      };
    }
    case ActionTypes.ORDERS_SELECT_ORDER: {
      const userId = action.id;
      const userState = state[userId];
      const referenceId = action.referenceId;
      return {
        ...state,
        [userId]: {
          ...userState,
          selectedOrder: referenceId,
          dispute: null,
        },
      };
    }
    case ActionTypes.ORDERS_DESELECT_ORDER: {
      const userId = action.id;
      const userState = state[userId];
      return {
        ...state,
        [userId]: {
          ...userState,
          selectedOrder: null,
          dispute: null,
        },
      };
    }
    case ActionTypes.ORDERS_UPDATE_DISPUTE: {
      const { id: userId, dispute } = action;
      const userState = state[userId];
      return {
        ...state,
        [userId]: {
          ...userState,
          dispute,
        },
      };
    }
    case ActionTypes.ORDERS_USER_ADDRESSES: {
      const { addresses, userId } = action;
      const userState = state[userId];
      return {
        ...state,
        [userId]: {
          ...userState,
          addresses,
        },
      };
    }
  }
  return state;
}
