import { Action } from 'redux';
import { TAppliedFilter, TFeedbackTabs, TFilters, TReportData } from './types';

export const FEEDBACK_INIT = 'FEEDBACK_INIT';
export type FEEDBACK_INIT_TYPE = typeof FEEDBACK_INIT;
export interface IInitFeedbackAction extends Action<FEEDBACK_INIT_TYPE> {
  type: FEEDBACK_INIT_TYPE;
}

export const FEEDBACK_READY = 'FEEDBACK_READY';
export type FEEDBACK_READY_TYPE = typeof FEEDBACK_READY;
export interface IFeedbackReadyAction extends Action<FEEDBACK_READY_TYPE> {
  type: FEEDBACK_READY_TYPE;
}

export const FEEDBACK_OPEN_TAB = 'FEEDBACK_OPEN_TAB';
export type FEEDBACK_OPEN_TAB_TYPE = typeof FEEDBACK_OPEN_TAB;
export interface IOpenFeedbackTabAction extends Action<FEEDBACK_OPEN_TAB_TYPE> {
  type: FEEDBACK_OPEN_TAB_TYPE;
  tab: TFeedbackTabs;
  reload?: boolean;
}

export const FEEDBACK_UPDATE_FILTERS = 'FEEDBACK_UPDATE_FILTERS';
export type FEEDBACK_UPDATE_FILTERS_TYPE = typeof FEEDBACK_UPDATE_FILTERS;
export interface IUpdateUserFeedbackFiltersAction extends Action<FEEDBACK_UPDATE_FILTERS_TYPE> {
  type: FEEDBACK_UPDATE_FILTERS_TYPE;
  filters: TFilters;
}

export const FEEDBACK_LOAD_REPORTS = 'FEEDBACK_LOAD_REPORTS';
export type FEEDBACK_LOAD_REPORTS_TYPE = typeof FEEDBACK_LOAD_REPORTS;
export interface ILoadReportsAction extends Action<FEEDBACK_LOAD_REPORTS_TYPE> {
  type: FEEDBACK_LOAD_REPORTS_TYPE;
  tab: TFeedbackTabs;
  filters: TAppliedFilter;
}

export const FEEDBACK_UPDATE_REPORTS = 'FEEDBACK_UPDATE_REPORTS';
export type FEEDBACK_UPDATE_REPORTS_TYPE = typeof FEEDBACK_UPDATE_REPORTS;
export interface IUpdateReportsAction extends Action<FEEDBACK_UPDATE_REPORTS_TYPE> {
  type: FEEDBACK_UPDATE_REPORTS_TYPE;
  reports: TReportData[];
}

export const FEEDBACK_LOADING_FINISHED = 'FEEDBACK_LOADING_FINISHED';
export type FEEDBACK_LOADING_FINISHED_TYPE = typeof FEEDBACK_LOADING_FINISHED;
export interface ILoadingFinishedAction extends Action<FEEDBACK_LOADING_FINISHED_TYPE> {
  type: FEEDBACK_LOADING_FINISHED_TYPE;
}

export type IFeedbackActions =
  | IInitFeedbackAction
  | IFeedbackReadyAction
  | IOpenFeedbackTabAction
  | IUpdateUserFeedbackFiltersAction
  | ILoadReportsAction
  | IUpdateReportsAction
  | ILoadingFinishedAction;
