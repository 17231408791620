import { IApiService } from 'web_core_library';

export function createProfileService() {
  let ApiService: IApiService;
  let _ready = false;

  function saveUser(userId: number, userData: any) {
    return ApiService.action('users', {
      method: 'patch',
      urlParams: {
        userId,
      },
      data: {
        ...userData,
        form: 'admin',
      },
    });
  }

  function isReady() {
    return _ready;
  }

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
      _ready = true;
    },
    isReady,
    saveUser,
  };
  return service;
}

const ProfileService = createProfileService();
export default ProfileService;
