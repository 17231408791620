import { Action } from 'redux';

import { ApiResponse } from '../services/apiResponse';
import { IOrderData, IAddress } from '../state/types/base';

export const ORDERS_GET_USER_ORDERS = 'ORDERS_GET_USER_ORDERS';
export type ORDERS_GET_USER_ORDERS = typeof ORDERS_GET_USER_ORDERS;
export interface IGetUserOrdersAction extends Action<ORDERS_GET_USER_ORDERS> {
  type: ORDERS_GET_USER_ORDERS;
  id: number;
}

export const ORDERS_GET_USER_ORDERS_SUCCESS = 'ORDERS_GET_USER_ORDERS_SUCCESS';
export type ORDERS_GET_USER_ORDERS_SUCCESS = typeof ORDERS_GET_USER_ORDERS_SUCCESS;
export interface IGetUserOrdersSuccessAction extends Action<ORDERS_GET_USER_ORDERS_SUCCESS> {
  type: ORDERS_GET_USER_ORDERS_SUCCESS;
  id: number;
  orders: IOrderData[];
}

export const ORDERS_GET_USER_ORDERS_FAILED = 'ORDERS_GET_USER_ORDERS_FAILED';
export type ORDERS_GET_USER_ORDERS_FAILED = typeof ORDERS_GET_USER_ORDERS_FAILED;
export interface IGetUserOrdersFailedAction extends Action<ORDERS_GET_USER_ORDERS_FAILED> {
  type: ORDERS_GET_USER_ORDERS_FAILED;
  id: number;
}

export type IGetUserOrdersResultAction = IGetUserOrdersSuccessAction | IGetUserOrdersFailedAction;

export const ORDERS_SELECT_ORDER = 'ORDERS_SELECT_ORDER';
export type ORDERS_SELECT_ORDER = typeof ORDERS_SELECT_ORDER;
export interface ISelectOrderAction extends Action<ORDERS_SELECT_ORDER> {
  type: ORDERS_SELECT_ORDER;
  id: number;
  referenceId: string;
}

export const ORDERS_DESELECT_ORDER = 'ORDERS_DESELECT_ORDER';
export type ORDERS_DESELECT_ORDER = typeof ORDERS_DESELECT_ORDER;
export interface IDeselectOrderAction extends Action<ORDERS_DESELECT_ORDER> {
  type: ORDERS_DESELECT_ORDER;
  id: number;
}

export const ORDERS_COMPLETE_ORDER = 'ORDERS_COMPLETE_ORDER';
export type ORDERS_COMPLETE_ORDER = typeof ORDERS_COMPLETE_ORDER;
export interface ICompleteOrderAction extends Action<ORDERS_COMPLETE_ORDER> {
  type: ORDERS_COMPLETE_ORDER;
  referenceId: string;
}

export const ORDERS_COMPLETE_ORDER_REQUEST = 'ORDERS_COMPLETE_ORDER_REQUEST';
export type ORDERS_COMPLETE_ORDER_REQUEST = typeof ORDERS_COMPLETE_ORDER_REQUEST;
export interface ICompleteOrderRequestAction extends Action<ORDERS_COMPLETE_ORDER_REQUEST> {
  type: ORDERS_COMPLETE_ORDER_REQUEST;
  referenceId: string;
  userId: number;
}

export const ORDERS_COMPLETE_ORDER_SUCCESS = 'ORDERS_COMPLETE_ORDER_SUCCESS';
export type ORDERS_COMPLETE_ORDER_SUCCESS = typeof ORDERS_COMPLETE_ORDER_SUCCESS;
export interface ICompleteOrderSuccessAction extends Action<ORDERS_COMPLETE_ORDER_SUCCESS> {
  type: ORDERS_COMPLETE_ORDER_SUCCESS;
  response: ApiResponse;
}

export const ORDERS_COMPLETE_ORDER_FAIL = 'ORDERS_COMPLETE_ORDER_FAIL';
export type ORDERS_COMPLETE_ORDER_FAIL = typeof ORDERS_COMPLETE_ORDER_FAIL;
export interface ICompleteOrderFailAction extends Action<ORDERS_COMPLETE_ORDER_FAIL> {
  type: ORDERS_COMPLETE_ORDER_FAIL;
  response: ApiResponse;
}

export const ORDERS_UPDATE_DISPUTE = 'ORDERS_UPDATE_DISPUTE';
export type ORDERS_UPDATE_DISPUTE = typeof ORDERS_UPDATE_DISPUTE;
export interface IUpdateOrdersDisputeAction extends Action<ORDERS_UPDATE_DISPUTE> {
  type: ORDERS_UPDATE_DISPUTE;
  id: number;
  dispute: string;
}

export const COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD';
export type COPY_TO_CLIPBOARD = typeof COPY_TO_CLIPBOARD;
export interface ICopyToClipboardAction extends Action<COPY_TO_CLIPBOARD> {
  type: COPY_TO_CLIPBOARD;
  html: string;
}

export const ORDERS_FETCH_ADDRESSES = 'ORDERS_FETCH_ADDRESSES';
export type ORDERS_FETCH_ADDRESSES_TYPE = typeof ORDERS_FETCH_ADDRESSES;
export interface IFetchAddressesAction extends Action<ORDERS_FETCH_ADDRESSES_TYPE> {
  type: ORDERS_FETCH_ADDRESSES_TYPE;
  userId: number;
}

export const ORDERS_USER_ADDRESSES = 'ORDERS_USER_ADDRESSES';
export type ORDERS_USER_ADDRESSES_TYPE = typeof ORDERS_USER_ADDRESSES;
export interface IUserAddressesAction extends Action<ORDERS_USER_ADDRESSES_TYPE> {
  type: ORDERS_USER_ADDRESSES_TYPE;
  userId: number;
  addresses: IAddress[];
}

export const ORDERS_SAVE_ADDRESS = 'ORDERS_SAVE_ADDRESS';
export type ORDERS_SAVE_ADDRESS_TYPE = typeof ORDERS_SAVE_ADDRESS;
export interface ISaveAddressAction extends Action<ORDERS_SAVE_ADDRESS_TYPE> {
  type: ORDERS_SAVE_ADDRESS_TYPE;
  userId: number;
  address: IAddress;
}

export type ICompleteOrderResponseAction = ICompleteOrderSuccessAction | ICompleteOrderFailAction;

export type IOrdersActions =
  | IGetUserOrdersAction
  | IGetUserOrdersResultAction
  | ISelectOrderAction
  | IDeselectOrderAction
  | ICompleteOrderAction
  | ICompleteOrderRequestAction
  | ICompleteOrderResponseAction
  | IUpdateOrdersDisputeAction
  | ICopyToClipboardAction
  | IFetchAddressesAction
  | IUserAddressesAction
  | ISaveAddressAction;
