import { all, call, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import { getUnixTimestamp } from '../services/dateUtils';
import { StatsActions, StatsSelectors } from '../userStats';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import CyclesService from './cyclesService';
import * as Selectors from './selectors';
import { ICycleStatus } from './types';
import { resetCycles } from './utils';

export default function* cyclesWatcher() {
  yield all([
    takeEvery(ActionTypes.CYCLES_INIT, handleServiceInit),
    takeEvery(ActionTypes.CYCLES_LOAD, handleCyclesLoadingSaga),
    takeLeading(ActionTypes.RESET_TRAINING, resetTrainingSaga),
    takeEvery(ActionTypes.LOAD_SESSION, loadCycleSessionSaga),
  ]);
}

export function* handleServiceInit() {
  yield call(CyclesService.init, ApiService);
}

export function* handleCyclesLoadingSaga({ userId }: ActionTypes.ILoadCyclesAction) {
  yield call(fetchCyclesStatistics, userId);
  // load latest session
  const cycleId: number = yield select(Selectors.getCurrentCycleId);
  const lastSessionId: number = yield select(Selectors.getLastSessionId);
  const cycleLength: number = yield select(Selectors.getCurrentCycleLength);
  // try to load the next session if its not the last one
  let sessionId = cycleLength === lastSessionId ? lastSessionId : lastSessionId + 1;
  while (sessionId <= cycleLength) {
    yield put(Actions.loadSession(cycleId, sessionId));
    sessionId += 1;
  }
}

export function* fetchCyclesStatistics(userId: number) {
  try {
    const statistic: TApiFetchResponse<typeof CyclesService.getUserStatistic> = yield call(
      CyclesService.getUserStatistic,
      userId
    );
    const {
      finishedCycles,
      finishedSessions,
      currentCycleId,
      currentCycleLastSessionId,
      currentCycleLength,
    } = statistic.data.trainingstatistics;
    yield put(
      Actions.updateCycleStats(
        finishedCycles,
        finishedSessions,
        currentCycleId ? currentCycleId : 0,
        currentCycleLastSessionId ? currentCycleLastSessionId : 0,
        currentCycleLength ? currentCycleLength : 0
      )
    );
  } catch (error) {
    console.error(error);
  }
}

export function* loadCycleSessionSaga({ cycleId, sessionId }: ActionTypes.ILoadSessionAction) {
  try {
    const userId: number = yield select(Selectors.getCurrentUserId);
    const response: TApiFetchResponse<typeof CyclesService.getCycleSession> = yield call(
      CyclesService.getCycleSession,
      userId,
      cycleId,
      sessionId
    );
    const cycles = response.data.trainingcycles;
    if (!cycles.length) {
      console.log(cycles);
      return;
    }
    yield put(Actions.updateSession(cycles[0]));
  } catch (error) {
    console.error(error);
  }
}

export function* resetTrainingSaga() {
  const userId: number = yield select(Selectors.getCurrentUserId);
  // save reset info to stats
  yield fork(saveResetProgressStats, userId);
  // reset cycles information
  yield fork(updateTrainingData, userId);
}

export function* saveResetProgressStats(userId: number) {
  // get counter for progres resets
  const resetCounterStatId = '7-0-1';
  const resetCounterStat: number = yield select(StatsSelectors.getIntUserStatById, userId, resetCounterStatId);
  // save increased counter
  yield put(StatsActions.saveUserStatById(userId, resetCounterStatId, resetCounterStat + 1));
  // save reset timestamp
  const resetStatId = '7-0-0';
  const timestamp: number = yield call(getUnixTimestamp);
  yield put(StatsActions.saveUserStatById(userId, resetStatId, timestamp));
}

export function* updateTrainingData(userId: number) {
  try {
    const cycles: ICycleStatus[] = yield select(Selectors.getCycles);
    const updatedCycles = yield call(resetCycles, cycles);
    yield call(CyclesService.updateCycles, userId, updatedCycles);
    // reload cycle info
    yield put(Actions.loadCycles(userId));
  } catch (error) {
    console.error(error);
  }
}
