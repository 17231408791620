import { CombinedState } from 'redux';
import { IFeedbackState, statePropName } from './reducer';
import {
  IAppReport,
  IAppReportFilters,
  IExerciseReport,
  IExerciseReportFilters,
  IUserFeedbackFilters,
  IUserFeedbackReport,
} from './types';
import { parseStringFilter } from './utils';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [statePropName]: IFeedbackState;
}

const getFeatureState = (state: IAppState) => state[statePropName];

export const isReady = (state: IAppState) => getFeatureState(state).ready;

export const isLoading = (state: IAppState) => getFeatureState(state).loading;

const getFiltersState = (state: IAppState) => getFeatureState(state).filters;

export const getFiltersDateRange = (state: IAppState) => {
  const filters = getFiltersState(state);
  const timestampFrom = filters?.timestampFrom ?? null;
  const timestampTo = filters?.timestampTo ?? null;
  return [timestampFrom, timestampTo];
};

export const getFiltersLanguages = (state: IAppState) => parseStringFilter(getFiltersState(state)?.language ?? []);

export const getFiltersOsNames = (state: IAppState) => parseStringFilter(getFiltersState(state)?.osName ?? []);

export const getFiltersOsVersions = (state: IAppState) => parseStringFilter(getFiltersState(state)?.osVersion ?? []);

export const getFiltersPremium = (state: IAppState) => getFiltersState(state)?.isPremium ?? [];

// we sort filters in desc order as requested by stakeholders (see BW-7916)
export const getFiltersAppVersion = (state: IAppState) =>
  (getFiltersState(state)?.appVersion ?? []).sort((a, b) => b.localeCompare(a));

export const getFiltersCountry = (state: IAppState) => parseStringFilter(getFiltersState(state)?.country ?? []);

export const getFiltersLastGameplay = (state: IAppState) => {
  const filters = getFiltersState(state);
  if (!filters) {
    return [];
  }
  return parseStringFilter((filters as IUserFeedbackFilters | IAppReportFilters).lastGameplay ?? []);
};

export const getFiltersExercises = (state: IAppState) => {
  const filters = getFiltersState(state);
  if (!filters) {
    return [];
  }
  return parseStringFilter((filters as IExerciseReportFilters).exercises ?? []);
};

export const getUserFeedbackReports = (state: IAppState) =>
  getFeatureState(state).reports as IUserFeedbackReport[] | undefined;

export const getAppReports = (state: IAppState) => getFeatureState(state).reports as IAppReport[] | undefined;

export const getExerciseReports = (state: IAppState) => getFeatureState(state).reports as IExerciseReport[] | undefined;
