import { IUserStatId, IUserStat, IUserStatPost } from './types';

export function parseUserStatIds(id: string) {
  const ids = id.split('-');
  if (ids.length !== 3) {
    throw new Error('Wrong user stat definition!');
  }
  const stat: IUserStatId = {
    statType: +ids[0],
    statId: +ids[1],
    statComponentId: +ids[2],
  };
  return stat;
}

export function parseStat(id: string, value: number | string) {
  const statIds = parseUserStatIds(id);
  const backgroundStat: IUserStat = {
    ...statIds,
    valueInt: typeof value === 'number' ? value : 0,
    valueTxt: typeof value === 'string' ? value : '',
  };
  return backgroundStat;
}

const DEFAULT_STAT_POST_PROPS = {
  operator: 'UPD',
  time: -1,
};

export function convertIUserStats2IUserStatPosts(userStatPosts: IUserStat[]) {
  return userStatPosts.map((post) => {
    return { ...post, ...DEFAULT_STAT_POST_PROPS } as IUserStatPost;
  });
}
