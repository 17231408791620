import { Intent } from '@blueprintjs/core';
import * as ActionTypes from './actionTypes';
import { MessageContent } from './types';

// main show message action with desired intent
export const showNotification: (intent: Intent, message: MessageContent) => ActionTypes.IShowNotificationAction = (
  intent,
  message
) => ({
  type: ActionTypes.MESSAGES_SHOW_MESSAGE,
  message,
  intent,
});

// helper action builders to provide actions for necessary types of messages
export const showWarning = (message: MessageContent) => showNotification(Intent.WARNING, message);

export const showError = (message: MessageContent) => showNotification(Intent.DANGER, message);

export const showSuccess = (message: MessageContent) => showNotification(Intent.SUCCESS, message);
