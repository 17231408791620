import { useSelector } from 'react-redux';
import ReportsSection from '../components/sections/ReportsSection';
import { getUserFeedbackReports } from '../selectors';
import UserFeedbackReportsTable from './UserFeedbackReportsTable';

export default function UserFeedbackReports() {
  const reports = useSelector(getUserFeedbackReports);
  return (
    <ReportsSection length={reports?.length}>
      <UserFeedbackReportsTable reports={reports} />
    </ReportsSection>
  );
}
