import * as ActionTypes from './actionTypes';
import { TPossibleCouponStatus } from './types';

export const initCouponAction: () => ActionTypes.IInitCouponAction = () => ({
  type: ActionTypes.COUPON_INIT,
});

export const searchCouponAction: (coupon: string) => ActionTypes.ISearchCouponAction = (coupon) => ({
  type: ActionTypes.COUPON_SEARCH,
  coupon,
});

export const couponNotFoundAction: () => ActionTypes.ICouponNotFoundAction = () => ({
  type: ActionTypes.COUPON_NOT_FOUND,
});

export const resetCouponSearchAction: () => ActionTypes.ISearchResetAction = () => ({
  type: ActionTypes.COUPON_SEARCH_RESET,
});

export const couponSearchResultAction: (status: TPossibleCouponStatus) => ActionTypes.ISearchResultAction = (
  status
) => ({
  type: ActionTypes.COUPON_SEARCH_RESULT,
  status,
});
