import { all, call, put, takeEvery } from 'redux-saga/effects';
import { HttpError } from 'web_core_library';
import { ApiResponse } from '../../services/apiResponse';
import StringsService from '../../services/stringsService';
import { APP_GET_PRODUCTS, getProductsFail, getProductsSuccess } from '../actions/products';

export function* getProductsSaga() {
  try {
    const response = yield call(StringsService.getProducts);
    yield put(getProductsSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(getProductsFail(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

export default function* productsWatcher() {
  yield all([takeEvery(APP_GET_PRODUCTS, getProductsSaga)]);
}
