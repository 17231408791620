import { all, call, delay, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { addAuthInterceptor, addRetry, ApiService, sessionStorage, TApiFetchResponse } from 'web_core_library';
import { NotificationActions } from '../notifications';
import CookieManager from '../services/cookieManager';
import UrlManager, { redirectLogin, redirectLogout } from '../services/urlManager';
import {
  ISearchOrdersFailAction,
  USERS_GET_USER_BY_ID_FAIL,
  USERS_GET_USER_SUBSCRIPTIONS_FAIL,
  USERS_SEARCH_ORDERS_FAIL,
  USERS_SEARCH_USERNAME_FAIL,
} from '../state/actions/users';
import { ApiResponseAction } from '../state/types/actions';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import AuthService from './authService';
import { SESSION_EXPIRATION_THRESHOLD_MIN } from './constants';
import * as Selectors from './selectors';
import { getAccessTokenExpiration, getMinutesLeft, parseSession } from './sessionHelper';

export function* restoreAuthSaga() {
  AuthService.init(ApiService);
  try {
    // try to get session from url
    let session = yield call(UrlManager.getSessionToken);
    if (!session) {
      // try to get session from browser storage
      session = sessionStorage.load('session');
      if (!session) {
        // try to get it from cookies (deprecated way)
        session = yield call(CookieManager.getCookie, CookieManager.COOKIE_AUTH_SESSION);
      }
    }
    if (!session) {
      // check if access token is present in url
      let accessToken = yield call(UrlManager.getAccessToken);
      if (!accessToken) {
        // try to get it from cookies
        accessToken = yield call(CookieManager.getCookie, CookieManager.COOKIE_AUTH_TOKEN);
      }
      if (accessToken) {
        // get session token from access token
        session = yield call(getSessionTokenFromAccessToken, accessToken);
      }
      if (!session) {
        yield put(Actions.restoreAuthFailAction());
        return;
      }
    }
    yield call(processSessionToken, session);
  } catch (error) {
    console.error(error);
    yield put(Actions.restoreAuthFailAction());
  }
}

export function* processSessionToken(sessionToken: string) {
  const userData: ReturnType<typeof parseSession> = yield call(parseSession, sessionToken);
  const userId = userData.uid;
  const expires = userData.exp * 1000;
  const minutesLeft = getMinutesLeft(expires);
  if (minutesLeft < 0) {
    // if token already expired - redirect to sso to refresh it
    throw new Error('Token expired');
  }
  yield put(Actions.startTokenRefreshAction(expires));
  sessionStorage.save('session', sessionToken);
  let email: string | undefined;
  const sessionEmail = sessionStorage.load<string>('email');
  if (sessionEmail) {
    email = sessionEmail;
  } else {
    const cookieEmail: string = yield call(CookieManager.getCookie, CookieManager.COOKIE_AUTH_USER);
    if (cookieEmail) {
      email = cookieEmail;
    }
  }
  const tokenExpires: number = yield call(getAccessTokenExpiration);
  yield put(Actions.updateUserAction(userId, userData.isUAD, expires, sessionToken, tokenExpires * 1000, email));
  yield put(Actions.restoreAuthSuccessAction());
}

export function* setUpUserSaga() {
  const userId: number | undefined = yield select(Selectors.getAuthUserId);
  if (!userId) {
    throw new Error('Invalid user data!');
  }
  const session = yield select(Selectors.getSession);
  // Set user credentials to ApiService
  yield call(ApiService.setUserData, userId, session, redirectLogin);
  yield call(addAuthInterceptor);
  yield call(addRetry);
}

export function* handleLoginSaga() {
  // clean up outdated session
  sessionStorage.remove('session');
  yield delay(500);
  yield call(redirectLogin);
}

export function* handleLogoutSaga() {
  yield call(CookieManager.deleteCookie, CookieManager.COOKIE_AUTH_SESSION);
  yield call(CookieManager.deleteCookie, CookieManager.COOKIE_AUTH_USER);
  sessionStorage.remove('session');
  yield call(CookieManager.setCookie, CookieManager.COOKIE_AUTH_LOGOUT, '1');
  yield call(redirectLogout);
}

// TODO: this handling must be moved to the main app saga
// (login shold be implemented as standalone action in this feature)
export function* handleApiAuthError(action: ApiResponseAction | ISearchOrdersFailAction) {
  let error = (action as ISearchOrdersFailAction).response;
  if (!error) {
    error = (action as ApiResponseAction).payload.response;
  }
  if (error.isUnauthorized()) {
    // show warning
    yield put(NotificationActions.showWarning('Authorization failed. Session expired?'));
    // proceed with login
    yield fork(handleLoginSaga);
  }
}

export function* getSessionTokenFromAccessToken(accessToken: string) {
  try {
    yield call(ApiService.setUserData, 0, accessToken, redirectLogin);
    const validationResult: TApiFetchResponse<typeof AuthService.validate> = yield call(
      AuthService.validate,
      accessToken
    );
    const { sessionToken } = validationResult.data.result;
    return sessionToken;
  } catch {
    // if token validation fails - return null
    return null;
  }
}

export function* startTokenRefreshSaga({ expires }: ActionTypes.IStartTokenRefreshAction) {
  let minutesLeft = getMinutesLeft(expires);
  // Wait until expiration is less than threshold
  while (minutesLeft > SESSION_EXPIRATION_THRESHOLD_MIN) {
    yield delay(60000);
    minutesLeft = getMinutesLeft(expires);
  }
  // Start updating the token
  yield put(Actions.refreshTokenAction());
}

export function* refreshSessionTokenSaga() {
  try {
    const accessToken = CookieManager.getCookie(CookieManager.COOKIE_AUTH_TOKEN);
    if (!accessToken) {
      // We have no access token in cookies, redirect to SSO
      throw new Error('Token expired!');
    }
    const sessionToken: string | null = yield call(getSessionTokenFromAccessToken, accessToken);
    if (!sessionToken) {
      // Couldn't restore session from access token, redirect to SSO
      throw new Error("Couldn't restore session from access token");
    }
    yield call(processSessionToken, sessionToken);
  } catch (error) {
    yield put(Actions.restoreAuthFailAction());
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery(ActionTypes.AUTH_RESTORE, restoreAuthSaga),
    takeEvery(ActionTypes.AUTH_RESTORE_FAIL, handleLoginSaga),
    takeLeading(ActionTypes.AUTH_LOGOUT, handleLogoutSaga),
    takeEvery(ActionTypes.AUTH_RESTORE_SUCCESS, setUpUserSaga),
    takeLeading(ActionTypes.AUTH_LOGIN, handleLoginSaga),
    takeEvery(ActionTypes.AUTH_TOKEN_REFRESH, refreshSessionTokenSaga),
    takeEvery(
      [
        USERS_GET_USER_BY_ID_FAIL,
        USERS_GET_USER_SUBSCRIPTIONS_FAIL,
        USERS_SEARCH_ORDERS_FAIL,
        USERS_SEARCH_USERNAME_FAIL,
      ],
      handleApiAuthError
    ),
  ]);
}
