import { Button } from '@blueprintjs/core';
import {
  CellRenderer,
  Column,
  FocusedCellCoordinates,
  RowHeaderCell2,
  SelectionModes,
  Table2,
} from '@blueprintjs/table';
import { RowIndices } from '@blueprintjs/table/lib/esm/common/grid';
import { HeaderCellRenderer } from '@blueprintjs/table/lib/esm/headers/header';
import { useEffect, useState } from 'react';
import ColumnsSelector from './ColumnsSelector';
import './SmartTable.scss';
import { TColumnDefinition, TComparatorCallback, TTableData } from './types';
import {
  getBooleanCellRenderer,
  getBooleanColumnHeaderCellRenderer,
  getColumnsFromStore,
  getDateCellRenderer,
  getDateColumnHeaderCellRenderer,
  getLongTextCellRenderer,
  getNumberColumnHeaderCellRenderer,
  getTextCellRenderer,
  getTextColumnHeaderCellRenderer,
  renderPredicate,
  reorderArray,
  saveColumnsToStore,
} from './utils';

interface ISmartTableProps {
  name?: string;
  className?: string;
  columns: TColumnDefinition[];
  data: TTableData;
  pageSize?: number;
}

export default function SmartTable({ className, columns, data, name, pageSize = 50 }: ISmartTableProps) {
  const [sortedIndexMap, setSortedIndexMap] = useState<number[]>(data.map((_, index) => index));
  const [viewSize, setViewSize] = useState<number>(pageSize);
  const [activeRow, selectRow] = useState<number>(-1);
  // this array contains set of visible columns and their order
  const [activeColumns, setActiveColumns] = useState<TColumnDefinition[]>([...columns]);
  useEffect(() => {
    if (!name) {
      return;
    }
    const savedState = getColumnsFromStore(name);
    if (!savedState) {
      // if no saved state - use all columns
      setActiveColumns(columns);
      return;
    }
    setActiveColumns(savedState);
  }, [name]);
  function isRowActive(rowIndex: number) {
    const id = getCellData<number>(rowIndex, 'id');
    return id === activeRow;
  }
  function sortColumn<T>(columnName: string | number, comparator: TComparatorCallback<T>) {
    const newIndexMap = data.map((_, index) => index);
    newIndexMap.sort((a, b) => comparator(data[a][columnName], data[b][columnName]));
    setSortedIndexMap(newIndexMap);
    selectRow(-1);
  }
  function getCellData<T>(rowIndex: number, columnName: string | number): T | undefined {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (typeof sortedRowIndex !== 'undefined') {
      return data[sortedRowIndex][columnName];
    }
  }
  function renderColumn(colDef: TColumnDefinition) {
    const { columnName, columnTitle, hide, copy } = colDef;
    if (hide) {
      // if column is hidden we don't render it
      return null;
    }
    let cellRenderer: CellRenderer;
    let cellHeaderRenderer: HeaderCellRenderer;
    switch (colDef.columnType) {
      case 'boolean': {
        cellRenderer = getBooleanCellRenderer(columnName, getCellData, isRowActive);
        cellHeaderRenderer = getBooleanColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
      case 'date': {
        cellRenderer = getDateCellRenderer(columnName, getCellData, isRowActive, { copyEnabled: copy });
        cellHeaderRenderer = getDateColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
      case 'number': {
        const { allowEmpty, truncate } = colDef;
        cellRenderer = getTextCellRenderer(columnName, getCellData, isRowActive, {
          allowEmpty,
          truncate,
          copyEnabled: copy,
        });
        cellHeaderRenderer = getNumberColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
      case 'string': {
        const { allowEmpty, truncate } = colDef;
        cellRenderer = getTextCellRenderer(columnName, getCellData, isRowActive, {
          allowEmpty,
          truncate,
          copyEnabled: copy,
        });
        cellHeaderRenderer = getTextColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
      case 'text': {
        const { allowEmpty, truncate } = colDef;
        cellRenderer = getTextCellRenderer(columnName, getCellData, isRowActive, {
          allowEmpty,
          truncate,
          copyEnabled: copy,
        });
        cellHeaderRenderer = getTextColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
      case 'long-text': {
        const { hideContent } = colDef;
        cellRenderer = getLongTextCellRenderer(columnName, getCellData, isRowActive, { hideContent });
        cellHeaderRenderer = getTextColumnHeaderCellRenderer(columnName, columnTitle, sortColumn);
        break;
      }
    }
    return (
      <Column
        id={columnName}
        cellRenderer={cellRenderer}
        columnHeaderCellRenderer={cellHeaderRenderer}
        key={columnName}
      />
    );
  }
  function renderColumns() {
    const columnsWithNull = activeColumns.map(renderColumn);
    // filter out nulls as Table component does not supports nulls as children
    return columnsWithNull.filter<React.JSX.Element>(renderPredicate);
  }

  const columnWidths = activeColumns.filter((colDef) => !colDef.hide).map((colDef) => colDef.columnWidth ?? null);
  function scrollHandler({ rowIndexEnd }: RowIndices) {
    if (rowIndexEnd + 1 >= viewSize) {
      setViewSize(viewSize + 10);
    }
  }
  const focusHandler = (cell: FocusedCellCoordinates) => {
    const id = getCellData<number>(cell.row, 'id');
    selectRow(id ?? -1);
  };
  const rowHeaderRenderer = (rowIndex: number) => {
    const id = getCellData<number>(rowIndex, 'id');
    return <RowHeaderCell2 name={`${id}`} isActive={isRowActive(rowIndex)} />;
  };
  function saveActiveColumns(newColumns: TColumnDefinition[]) {
    setActiveColumns(newColumns);
    if (!name) {
      return;
    }
    saveColumnsToStore(name, newColumns);
  }
  function handleColumnsReordered(oldIndex: number, newIndex: number) {
    console.log(`handleColumnsReordered(${oldIndex}, ${newIndex})`);
    if (oldIndex === newIndex) {
      return;
    }
    const newColumns = reorderArray(activeColumns, oldIndex, newIndex);
    saveActiveColumns(newColumns);
  }
  function handleColumnWidthChange(index: number, size: number) {
    console.log(`handleColumnWidthChange(${index}, ${size})`);
    const newColumns = activeColumns.slice();
    newColumns[index].columnWidth = size;
    saveActiveColumns(newColumns);
  }
  function toggleColumVisibility(index: number) {
    console.log('toggle column visibilty', index);
    const newColumns = activeColumns.slice();
    newColumns[index].hide = !newColumns[index].hide;
    saveActiveColumns(newColumns);
  }
  function resetColumns(e: React.MouseEvent) {
    e.stopPropagation();
    saveActiveColumns([...columns]);
  }
  return (
    <div style={{ height: '430px', width: '100%', position: 'relative' }}>
      <Button className="reset-button" icon="reset" small={true} onClick={resetColumns} />
      <Table2
        bodyContextMenuRenderer={() => (
          <ColumnsSelector columns={activeColumns} onToggleColumVisibility={toggleColumVisibility} />
        )}
        enableColumnReordering={true}
        onColumnsReordered={handleColumnsReordered}
        onColumnWidthChanged={handleColumnWidthChange}
        rowHeaderCellRenderer={rowHeaderRenderer}
        enableFocusedCell={true}
        onFocusedCell={focusHandler}
        selectionModes={SelectionModes.NONE}
        enableGhostCells={false}
        onVisibleCellsChange={scrollHandler}
        numRows={Math.min(data.length, viewSize)}
        columnWidths={columnWidths}
        className={className}
        cellRendererDependencies={[activeColumns, sortedIndexMap, viewSize]}>
        {renderColumns()}
      </Table2>
    </div>
  );
}
