import { Action } from 'redux';
import { NNProjects } from '../state/types/base';
import { IntervalPeriod } from './reducer';

export const STATUS_CHANGE_DIALOG_TOGGLE = 'STATUS_CHANGE_DIALOG_TOGGLE';
export type STATUS_CHANGE_DIALOG_TOGGLE = typeof STATUS_CHANGE_DIALOG_TOGGLE;
export interface IChangeStatusToggleAction extends Action<STATUS_CHANGE_DIALOG_TOGGLE> {
  type: STATUS_CHANGE_DIALOG_TOGGLE;
  project?: NNProjects;
}

export const STATUS_CHANGE_LIFETIME_TOGGLE = 'STATUS_CHANGE_LIFETIME_TOGGLE';
export type STATUS_CHANGE_LIFETIME_TOGGLE = typeof STATUS_CHANGE_LIFETIME_TOGGLE;
export interface IChangeStatusLifetimeToggleAction extends Action<STATUS_CHANGE_LIFETIME_TOGGLE> {
  type: STATUS_CHANGE_LIFETIME_TOGGLE;
}

export const STATUS_CHANGE_AMOUNT_CHANGE = 'STATUS_CHANGE_AMOUNT_CHANGE';
export type STATUS_CHANGE_AMOUNT_CHANGE = typeof STATUS_CHANGE_AMOUNT_CHANGE;
export interface IChangeStatusAmountAction extends Action<STATUS_CHANGE_AMOUNT_CHANGE> {
  type: STATUS_CHANGE_AMOUNT_CHANGE;
  value: number;
}

export const STATUS_CHANGE_PERIOD_CHANGE = 'STATUS_CHANGE_PERIOD_CHANGE';
export type STATUS_CHANGE_PERIOD_CHANGE = typeof STATUS_CHANGE_PERIOD_CHANGE;
export interface IChangeStatusPeriodAction extends Action<STATUS_CHANGE_PERIOD_CHANGE> {
  type: STATUS_CHANGE_PERIOD_CHANGE;
  value: IntervalPeriod;
}

export const STATUS_CHANGE_SUBMIT = 'STATUS_CHANGE_SUBMIT';
export type STATUS_CHANGE_SUBMIT = typeof STATUS_CHANGE_SUBMIT;
export interface IChangeStatusSubmitAction extends Action<STATUS_CHANGE_SUBMIT> {
  type: STATUS_CHANGE_SUBMIT;
  id: number;
}

export const STATUS_CHANGE_FAIL = 'STATUS_CHANGE_FAIL';
export type STATUS_CHANGE_FAIL = typeof STATUS_CHANGE_FAIL;
export interface IChangeStatusFailedAction extends Action<STATUS_CHANGE_FAIL> {
  type: STATUS_CHANGE_FAIL;
  id: number;
}

export const STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS';
export type STATUS_CHANGE_SUCCESS = typeof STATUS_CHANGE_SUCCESS;
export interface IChangeStatusSuccessAction extends Action<STATUS_CHANGE_SUCCESS> {
  type: STATUS_CHANGE_SUCCESS;
  id: number;
}

export const STATUS_TERMINATE_DIALOG_TOGGLE = 'STATUS_TERMINATE_DIALOG_TOGGLE';
export type STATUS_TERMINATE_DIALOG_TOGGLE = typeof STATUS_TERMINATE_DIALOG_TOGGLE;
export interface ITerminateStatusToggleAction extends Action<STATUS_TERMINATE_DIALOG_TOGGLE> {
  type: STATUS_TERMINATE_DIALOG_TOGGLE;
  project?: NNProjects;
}

export const STATUS_TERMINATE_SUBMIT = 'STATUS_TERMINATE_SUBMIT';
export type STATUS_TERMINATE_SUBMIT = typeof STATUS_TERMINATE_SUBMIT;
export interface ITerminateStatusSubmitAction extends Action<STATUS_TERMINATE_SUBMIT> {
  type: STATUS_TERMINATE_SUBMIT;
  id: number;
}

export const STATUS_TERMINATE_IMMEDIATELY_UPDATE = 'STATUS_TERMINATE_IMMEDIATELY_UPDATE';
export type STATUS_TERMINATE_IMMEDIATELY_UPDATE = typeof STATUS_TERMINATE_IMMEDIATELY_UPDATE;
export interface ITerminateStatusImmediatelyUpdateAction extends Action<STATUS_TERMINATE_IMMEDIATELY_UPDATE> {
  type: STATUS_TERMINATE_IMMEDIATELY_UPDATE;
}

export const STATUS_CANCEL_SUBSCRIPTION = 'STATUS_CANCEL_SUBSCRIPTION';
export type STATUS_CANCEL_SUBSCRIPTION = typeof STATUS_CANCEL_SUBSCRIPTION;
export interface ICancelSubscriptionAction extends Action<STATUS_CANCEL_SUBSCRIPTION> {
  type: STATUS_CANCEL_SUBSCRIPTION;
  id: number;
  subscriptionId: number;
  terminate: boolean; // true if canceling immediately, false if it should be valid till the end of the period.
}

export type StatusActions =
  | IChangeStatusToggleAction
  | IChangeStatusLifetimeToggleAction
  | IChangeStatusAmountAction
  | IChangeStatusPeriodAction
  | IChangeStatusSubmitAction
  | IChangeStatusFailedAction
  | IChangeStatusSuccessAction
  | ITerminateStatusToggleAction
  | ITerminateStatusSubmitAction
  | ITerminateStatusImmediatelyUpdateAction
  | ICancelSubscriptionAction;
