import { CombinedState } from 'redux';
import { statePropName, TAuthState } from './reducer';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [statePropName]: TAuthState;
}

const getAuthUser = (state: IAppState) => state[statePropName];

export const getSession = (state: IAppState) => getAuthUser(state)!.session;

export const getAuthUserId = (state: IAppState) => getAuthUser(state)?.userId;

export const isUserLoggedIn = (state: IAppState) => !!getAuthUserId(state);

export const isUserAdmin = (state: IAppState) => getAuthUser(state)?.isAdmin ?? false;

export const getUserEmail = (state: IAppState) => getAuthUser(state)?.email;

export const getExpiration = (state: IAppState) => {
  // get access token expiration timestamp
  const tokenExpires = getAuthUser(state)?.tokenExpires;
  return tokenExpires ? tokenExpires : getAuthUser(state)?.expires;
};
