import { Callout, Card, Classes, H4, Icon, NonIdealState, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../state/reducers';
import { saveUserStatsAction } from './actions';
import { getUserStats } from './selectors';
import StatsRow from './StatsRow';
import { IUserStatGet, IUserStatPost } from './types';
import './UserStats.scss';

export interface IOwnProps {
  userId: number;
}

export interface IUserStatsProps extends IOwnProps {
  stats?: IUserStatGet[];
  onSave: (userId: number, stats: IUserStatPost[]) => void;
}

export const UserStatsFC: React.FC<IUserStatsProps> = ({ stats, userId, onSave }) => {
  const [selectedRow, selectRow] = useState<string>('');
  const { HTML_TABLE, HTML_TABLE_STRIPED, INTERACTIVE, SKELETON } = Classes;
  const tableClassName = `stats-table ${HTML_TABLE} ${HTML_TABLE_STRIPED} ${INTERACTIVE} ${!stats ? SKELETON : ''}`;
  const isEmpty = !!stats && stats.length === 0;
  let rows: React.ReactNode[] = [];
  if (!stats) {
    rows.push(
      <tr key="no-data">
        <td>1</td>
        <td>1</td>
        <td>1</td>
        <td>0</td>
        <td>0</td>
        <td>&nbsp;</td>
        <td>asdasdasd</td>
      </tr>
    );
  } else {
    rows = stats.map((stat) => {
      const { statType, statId, statComponentId } = stat;
      const key = `${statType}-${statId}-${statComponentId}`;
      const isSelected = key === selectedRow;
      const onSelectRow = () => selectRow(key);
      const onSaveRow = (statToSave: IUserStatPost) => {
        onSave(userId, [statToSave]);
        setImmediate(() => selectRow(''));
      };
      return <StatsRow key={key} onSave={onSaveRow} onSelect={onSelectRow} selected={isSelected} stat={stat} />;
    });
  }

  const table = isEmpty ? (
    <NonIdealState
      icon={IconNames.DATABASE}
      title="Stats are empty"
      description="This is a very uncommon situation. Usually every user has at least a dozen of stats."
    />
  ) : (
    <table className={tableClassName}>
      <thead>
        <tr>
          <th className="s-type-col">Stat Type</th>
          <th className="s-id-col">Stat Id</th>
          <th className="s-compid-col">Component Id</th>
          <th className="s-edit-col">&nbsp;</th>
          <th className="s-i-val-col">Integer value</th>
          <th className="s-t-val-col">Text value</th>
          <th className="s-timestamp-col">Last edited</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
  return (
    <Card className="user-stats-card">
      <H4 className={Classes.HEADING}>
        User stats:{' '}
        <Popover content={<Callout intent="none">User stats spreadsheet</Callout>} interactionKind="hover-target">
          <a
            rel="noopener noreferrer"
            href="https://docs.google.com/spreadsheets/d/1MUTedMbBNBFODdQiOIdnyJjg9nlFpu83ajc_W99zouc/edit#gid=1641035822"
            target="_blank">
            <Icon icon="help" />
          </a>
        </Popover>
      </H4>
      <div className="user-stats-list">{table}</div>
    </Card>
  );
};

export const mapStateToProps = (state: AppState, props: IOwnProps) => {
  const stats = getUserStats(state, props.userId);
  return {
    stats,
  };
};

export const mapDispatchToProps = {
  onSave: saveUserStatsAction,
};

const UserStats = connect(mapStateToProps, mapDispatchToProps)(UserStatsFC);
export default UserStats;
