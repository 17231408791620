import AppReportsFilters from './AppReportsFilters';
import AppReportsReports from './AppReportsReports';

export default function AppReportsTab() {
  return (
    <div className="tab-contents">
      <AppReportsFilters />
      <AppReportsReports />
    </div>
  );
}
