import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { IUsedCouponStatus } from './types';

interface IUsedCouponStatusWidgetProps {
  coupon: IUsedCouponStatus;
}

export default function UsedCouponStatusWidget({ coupon }: IUsedCouponStatusWidgetProps) {
  return (
    <Callout intent={Intent.SUCCESS} icon={IconNames.DOLLAR} title="Used coupon found:">
      <p>Coupon code: {coupon.code}</p>
      <p>Created at: {coupon.created_at}</p>
      <p>Duration: {coupon.duration}</p>
      <p>Used by user: {coupon.used_by_user_id}</p>
    </Callout>
  );
}
