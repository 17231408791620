import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import { AuthActions, AuthActionTypes, AuthSelectors, AuthWatcher } from '../../auth';
import { CouponActions, CouponWatcher } from '../../coupon';
import { CyclesActions, CyclesSaga } from '../../cycles';
import { emailHistoryWatcher } from '../../emailHistory';
import { FeedbackActions, FeedbackSaga } from '../../feedbackConsole';
import { historyWatcher } from '../../history';
import { initMessagesFeatureAction } from '../../messages/actions';
import messagesWatcher from '../../messages/saga';
import { NotificationWatcher } from '../../notifications';
import ordersWatcher from '../../orders/saga';
import profileWatcher from '../../profile/saga';
import searchWatcher from '../../search/saga';
import statusWatcher from '../../status/saga';
import { ThemeActions, ThemeSaga } from '../../theme';
import userActionsWatcher from '../../userActions/saga';
import { StatsSaga } from '../../userStats';
import { appStartedAction, APP_START } from '../actions';
import { initAdminAction } from '../actions/admin';
import { getProducts } from '../actions/products';
import { initUsersFeatureAction } from '../actions/users';
import adminWatcher from './admin';
import detailsWatcher from './details';
import productsWatcher from './products';
import usersWatcher from './users';
export function* startAuthorizedFeatures() {
  yield put(ThemeActions.restoreThemeAction());
  const userId: number = yield select(AuthSelectors.getAuthUserId);
  yield put(getProducts());
  yield put(FeedbackActions.initFeedbackAction());
  yield put(CouponActions.initCouponAction());
  yield put(CyclesActions.initCycles());
  yield put(initMessagesFeatureAction(userId));
  yield put(initAdminAction());
  yield put(initUsersFeatureAction());
}

export function* startAppSaga() {
  const apiUrl = process.env.REACT_APP_API_URL || '';
  if (!apiUrl) {
    throw new Error('API url not set!');
  }
  yield call(ApiService.init, `${apiUrl}/v1/1`);
  yield put(AuthActions.restoreAuthAction());
  const result = yield take([AuthActionTypes.AUTH_RESTORE_SUCCESS, AuthActionTypes.AUTH_RESTORE_FAIL]);
  if (result.type === AuthActionTypes.AUTH_RESTORE_SUCCESS) {
    yield call(startAuthorizedFeatures);
  }
  // mark app as started
  yield put(appStartedAction());
}

export function* appWatcher() {
  yield all([takeEvery(APP_START, startAppSaga)]);
}

// should register all 'watcher'-sagas used by application
export default function* rootSaga() {
  yield all([
    fork(searchWatcher),
    fork(usersWatcher),
    fork(AuthWatcher),
    fork(appWatcher),
    fork(detailsWatcher),
    fork(NotificationWatcher),
    fork(ThemeSaga),
    fork(statusWatcher),
    fork(productsWatcher),
    fork(userActionsWatcher),
    fork(adminWatcher),
    fork(ordersWatcher),
    fork(messagesWatcher),
    fork(profileWatcher),
    fork(StatsSaga.default),
    fork(historyWatcher),
    fork(emailHistoryWatcher),
    fork(CyclesSaga.default),
    fork(CouponWatcher),
    fork(FeedbackSaga),
  ]);
}
