import * as ActionTypes from './actionTypes';
import { IEmailData } from './types';

export interface IEmailHistoryState {
  // loading flag
  loading: boolean;
  // list of emails
  emails: IEmailData[] | null;
}

export const initialState: IEmailHistoryState = {
  loading: true,
  emails: null,
};

export const statePropName = 'emails';

export default function emailsReducer(state = initialState, action: ActionTypes.IEmailHistoryActions) {
  switch (action.type) {
    case ActionTypes.EMAILS_LOAD_USER:
      return {
        ...state,
        emails: [],
      };
    case ActionTypes.EMAILS_LIST_UPDATE: {
      const emails = action.emails;
      return {
        ...state,
        loading: false,
        emails,
      };
    }
  }
  return state;
}
