import { Alignment, Button, Icon, NavbarDivider, NavbarGroup, NavbarHeading, Switch } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { AuthActions, AuthSelectors } from '../auth';
import { AppState } from '../state/reducers';
import { getTheme } from '../state/selectors';
import { Callback, DataCallback } from '../state/types/base';
import { ThemeActions, Themes } from '../theme';
import './UserMenu.scss';

export interface Props {
  isUserLoggedIn: boolean;
  userName: string | number;
  isAdmin: boolean;
  theme: string;
  onLogout: Callback;
  onUpdateTheme: DataCallback<Themes>;
}

export function UserMenu(props: Props) {
  if (!props.isUserLoggedIn) {
    return null;
  }
  const userIcon = props.isAdmin ? <Icon icon="user" /> : <Icon icon="blocked-person" />;
  const isDarkThemeSelected = props.theme === Themes.Dark;

  const onUpdateTheme = () => {
    const theme = isDarkThemeSelected ? Themes.Light : Themes.Dark;
    props.onUpdateTheme(theme);
  };

  return (
    <NavbarGroup align={Alignment.RIGHT}>
      <Switch
        className="theme-switch"
        onChange={onUpdateTheme}
        checked={isDarkThemeSelected}
        large={true}
        label="Dark Theme"
      />
      <NavbarDivider />
      <NavbarHeading>
        {userIcon} {props.userName}
      </NavbarHeading>
      <NavbarDivider />
      <Button onClick={props.onLogout} minimal={true} icon="log-out">
        Log out
      </Button>
    </NavbarGroup>
  );
}

export const mapStateToProps = (state: AppState) => {
  const isUserLoggedIn = AuthSelectors.isUserLoggedIn(state);
  const email = AuthSelectors.getUserEmail(state);
  const userId = AuthSelectors.getAuthUserId(state);
  const userName = email ?? userId ?? '';
  const isAdmin = AuthSelectors.isUserAdmin(state);
  const theme = getTheme(state);
  return {
    isUserLoggedIn,
    userName,
    isAdmin,
    theme,
  };
};

export const mapDispatchToProps = {
  onLogout: AuthActions.logoutAction,
  onUpdateTheme: ThemeActions.updateThemeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
