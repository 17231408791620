import { useSelector } from 'react-redux';
import ReportsSection from '../components/sections/ReportsSection';
import { getExerciseReports } from '../selectors';
import ExerciseReportsTable from './ExerciseReportsTable';

export default function ExerciseReportsReports() {
  const reports = useSelector(getExerciseReports);
  return (
    <ReportsSection length={reports?.length}>
      <ExerciseReportsTable reports={reports} />
    </ReportsSection>
  );
}
