import { Action } from 'redux';
import { TPossibleCouponStatus } from './types';

export const COUPON_INIT = 'COUPON_INIT';
export type COUPON_INIT_TYPE = typeof COUPON_INIT;
export interface IInitCouponAction extends Action<COUPON_INIT_TYPE> {
  type: COUPON_INIT_TYPE;
}

export const COUPON_SEARCH = 'COUPON_SEARCH';
export type COUPON_SEARCH_TYPE = typeof COUPON_SEARCH;
export interface ISearchCouponAction extends Action<COUPON_SEARCH_TYPE> {
  type: COUPON_SEARCH_TYPE;
  coupon: string;
}

export const COUPON_NOT_FOUND = 'COUPON_NOT_FOUND';
export type COUPON_NOT_FOUND_TYPE = typeof COUPON_NOT_FOUND;
export interface ICouponNotFoundAction extends Action<COUPON_NOT_FOUND_TYPE> {
  type: COUPON_NOT_FOUND_TYPE;
}

export const COUPON_SEARCH_RESET = 'COUPON_SEARCH_RESET';
export type COUPON_SEARCH_RESET_TYPE = typeof COUPON_SEARCH_RESET;
export interface ISearchResetAction extends Action<COUPON_SEARCH_RESET_TYPE> {
  type: COUPON_SEARCH_RESET_TYPE;
}

export const COUPON_SEARCH_RESULT = 'COUPON_SEARCH_RESULT';
export type COUPON_SEARCH_RESULT_TYPE = typeof COUPON_SEARCH_RESULT;
export interface ISearchResultAction extends Action<COUPON_SEARCH_RESULT_TYPE> {
  type: COUPON_SEARCH_RESULT_TYPE;
  status: TPossibleCouponStatus;
}

export type ICouponActions =
  | IInitCouponAction
  | ISearchCouponAction
  | ICouponNotFoundAction
  | ISearchResetAction
  | ISearchResultAction;
