import { NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { connect } from 'react-redux';
import './EmailHistory.scss';
import EmailHistoryTable from './EmailHistoryTable';
import * as Selectors from './selectors';

export interface Props {
  empty: boolean;
  loading: boolean;
}

export function EmailHistoryWrapper({ empty, loading }: Props) {
  if (loading) {
    return <Spinner className="email-history-spinner" />;
  }
  if (empty) {
    return <NonIdealState icon={IconNames.ENVELOPE} title="No emails were found" />;
  }
  return <EmailHistoryTable />;
}

export const mapStateToProps = (state: Selectors.IAppState) => {
  const empty = Selectors.isEmailsHistoryEmpty(state);
  const loading = Selectors.isEmailsHistoryLoading(state);
  return {
    empty,
    loading,
  };
};

export default connect(mapStateToProps)(EmailHistoryWrapper);
