import { CacheService } from 'web_core_library';
import { TFeedbackTabs, TFilters, TReportData } from './types';

async function init() {
  await CacheService.init();
}

async function getFiltersFromCache(tab: TFeedbackTabs) {
  try {
    return await CacheService.load<TFilters>(`${tab}-filters`);
  } catch {
    return;
  }
}

async function setFiltersCache(tab: TFeedbackTabs, filters: TFilters) {
  try {
    return await CacheService.save(`${tab}-filters`, filters);
  } catch {
    return;
  }
}

async function getReportsFromCache(tab: TFeedbackTabs) {
  try {
    return await CacheService.load<TReportData[]>(`${tab}-reports`);
  } catch {
    return;
  }
}

async function setReportsCache(tab: TFeedbackTabs, reports: TReportData[]) {
  try {
    return await CacheService.save(`${tab}-reports`, reports);
  } catch {
    return;
  }
}

const FeedbackConsoleCacheService = {
  getFiltersFromCache,
  getReportsFromCache,
  init,
  setFiltersCache,
  setReportsCache,
};

export default FeedbackConsoleCacheService;
