import { NNProjects, ProjectStatus } from '../types/base';
import {
  IChargebeeSubscription,
  IExtendedUser,
  ILegacySubscription,
  ISubscription,
  TSubscriptionStatus,
  UserStatus,
  UserStatusesMap,
} from '../types/state';
import User from './user';

function getEmptyStatus(project: NNProjects): UserStatus {
  return {
    status: ProjectStatus.Undefined,
    title: project,
    createdAt: undefined,
    subscriptionExpiresAt: undefined,
    roleExpiresAt: undefined,
    isSubscription: false,
    isChargebee: false,
    subscriptionStatus: null,
  };
}

function isChargebeeSubscription(s: IChargebeeSubscription | ILegacySubscription): s is IChargebeeSubscription {
  return !!s && (s as IChargebeeSubscription).isChargeBee !== undefined;
}

function getProductStatus(project: NNProjects, user: IExtendedUser, subscription?: ISubscription) {
  const isPremium = User.hasPremium(user, project);
  let status = ProjectStatus.Undefined;
  let subscriptionExpiresAt: ISubscription['expiresAt'] | undefined;
  let roleExpiresAt: ReturnType<typeof User.getRoleExpiration>;
  let createdAt: ISubscription['createdAt'] | undefined;
  let isChargebee = false;
  let subscriptionStatus: TSubscriptionStatus | null = null;
  if (isPremium) {
    roleExpiresAt = User.getRoleExpiration(user, project);
    if (!subscription) {
      // if expiration date for role is set - status is cancelled
      status = roleExpiresAt ? ProjectStatus.Cancelled : ProjectStatus.Active;
    }
  }
  if (subscription) {
    // we need to check subscription details for non bt projects only when role is set
    if (isPremium || project === NNProjects.BTAppWeb) {
      isChargebee = isChargebeeSubscription(subscription);
      subscriptionExpiresAt = subscription.expiresAt;
      createdAt = subscription.createdAt;
      // if subscription is active (i.e. autorenewing) than status is active
      if (subscription.autoRenewing) {
        status = ProjectStatus.Active;
      } else {
        status = ProjectStatus.Cancelled;
      }
      subscriptionStatus = isChargebeeSubscription(subscription) ? subscription.status : null;
    }
  }
  const userStatus: UserStatus = {
    status,
    title: project,
    createdAt,
    subscriptionExpiresAt,
    roleExpiresAt,
    isSubscription: !!subscription,
    isChargebee,
    subscriptionStatus,
  };
  if (isChargebee) {
    userStatus.subscriptionId = subscription?.id;
  }
  return userStatus;
}

function getEmptyStatusMap(): UserStatusesMap {
  return {
    [NNProjects.BTApp]: getEmptyStatus(NNProjects.BTApp),
    [NNProjects.BTAppWeb]: getEmptyStatus(NNProjects.BTAppWeb),
    [NNProjects.EaseApp]: getEmptyStatus(NNProjects.EaseApp),
  };
}

const Status = {
  getEmptyStatus,
  getProductStatus,
  getEmptyStatusMap,
};

export default Status;
