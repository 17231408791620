import { Action, ActionCreator } from 'redux';
import { ApiResponse } from '../../services/apiResponse';
import { ApiResponsePayload, IAction, UserDependentActionPayload } from '../types/actions';
import { RoleChange, UserStat } from '../types/base';
import { IUserDetailData } from '../types/state';
import { actionBuilder } from './actionBuilder';

export const USERS_INIT = 'USERS_INIT';
export type USERS_INIT_TYPE = typeof USERS_INIT;
export interface IInitUsersAction extends Action<USERS_INIT_TYPE> {
  type: USERS_INIT_TYPE;
}
export const initUsersFeatureAction: () => IInitUsersAction = () => ({
  type: USERS_INIT,
});

// search by name api request action
export const USERS_SEARCH_USERNAME_REQUEST = 'USERS_SEARCH_USERNAME_REQUEST';
export const searchUsersByUsernameRequestAction = actionBuilder<{ search: string }>(
  USERS_SEARCH_USERNAME_REQUEST,
  'search'
);

// search by name api response actions
export const USERS_SEARCH_USERNAME_SUCCESS = 'USERS_SEARCH_USERNAME_SUCCESS';
export const searchUsersUsernameSuccess = actionBuilder<ApiResponsePayload>(USERS_SEARCH_USERNAME_SUCCESS, 'response');

export const USERS_SEARCH_USERNAME_FAIL = 'USERS_SEARCH_USERNAME_FAIL';
export const searchUsersUsernameFailure = actionBuilder<ApiResponsePayload>(USERS_SEARCH_USERNAME_FAIL, 'response');

// get by id api request action
export const USERS_GET_USER_BY_ID_REQUEST = 'USERS_GET_USER_BY_ID_REQUEST';
export const getUserByIdRequestAction = actionBuilder<UserDependentActionPayload>(USERS_GET_USER_BY_ID_REQUEST, 'id');

// get by id api response actions
export interface GetUserByIdResponsePayload extends ApiResponsePayload {
  userId: number;
}

export const USERS_GET_USER_BY_ID_SUCCESS = 'USERS_GET_USER_BY_ID_SUCCESS';
export const getUserSuccess = actionBuilder<GetUserByIdResponsePayload>(
  USERS_GET_USER_BY_ID_SUCCESS,
  'response',
  'userId'
);

export const USERS_GET_USER_BY_ID_FAIL = 'USERS_GET_USER_BY_ID_FAIL';
export const getUserFailure = actionBuilder<GetUserByIdResponsePayload>(
  USERS_GET_USER_BY_ID_FAIL,
  'response',
  'userId'
);

export const USERS_SEARCH_ORDERS_REQUEST = 'USERS_SEARCH_ORDERS_REQUEST';
export type USERS_SEARCH_ORDERS_REQUEST = typeof USERS_SEARCH_ORDERS_REQUEST;
export interface ISearchOrdersAction extends Action<USERS_SEARCH_ORDERS_REQUEST> {
  type: USERS_SEARCH_ORDERS_REQUEST;
  name?: string;
  orderId?: string;
}
export const searchOrdersRequestAction: ActionCreator<ISearchOrdersAction> = (name?: string, orderId?: string) => ({
  type: USERS_SEARCH_ORDERS_REQUEST,
  name,
  orderId,
});

export const USERS_SEARCH_ORDERS_SUCCESS = 'USERS_SEARCH_ORDERS_SUCCESS';
export type USERS_SEARCH_ORDERS_SUCCESS = typeof USERS_SEARCH_ORDERS_SUCCESS;
export interface ISearchOrdersSuccessAction extends Action<USERS_SEARCH_ORDERS_SUCCESS> {
  type: USERS_SEARCH_ORDERS_SUCCESS;
  response: ApiResponse;
}
export const searchOrdersRequestSuccessAction: ActionCreator<ISearchOrdersSuccessAction> = (response: ApiResponse) => ({
  type: USERS_SEARCH_ORDERS_SUCCESS,
  response,
});

export const USERS_SEARCH_ORDERS_FAIL = 'USERS_SEARCH_ORDERS_FAIL';
export type USERS_SEARCH_ORDERS_FAIL = typeof USERS_SEARCH_ORDERS_FAIL;
export interface ISearchOrdersFailAction extends Action<USERS_SEARCH_ORDERS_FAIL> {
  type: USERS_SEARCH_ORDERS_FAIL;
  response: ApiResponse;
}
export const searchOrdersRequestFailedAction: ActionCreator<ISearchOrdersFailAction> = (response: ApiResponse) => ({
  type: USERS_SEARCH_ORDERS_FAIL,
  response,
});

export type ISearchOrdersResponseAction = ISearchOrdersSuccessAction | ISearchOrdersFailAction;

// updating detailed user info
export const USERS_GET_USER_RESULT = 'USERS_GET_USER_RESULT';
export interface GetUserResultActionPayload {
  user: IUserDetailData;
}
export const getUserResult = actionBuilder<GetUserResultActionPayload>(USERS_GET_USER_RESULT, 'user');

// get user subscription api request action
export const USERS_GET_USER_SUBSCRIPTIONS_REQUEST = 'USERS_GET_USER_SUBSCRIPTIONS_REQUEST';
export const getUserSubscriptionsRequestAction = actionBuilder<UserDependentActionPayload>(
  USERS_GET_USER_SUBSCRIPTIONS_REQUEST,
  'id'
);

// get user subscription api response actions
export const USERS_GET_USER_SUBSCRIPTIONS_SUCCESS = 'USERS_GET_USER_SUBSCRIPTIONS_SUCCESS';
export const getUserSubscriptionsRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_GET_USER_SUBSCRIPTIONS_SUCCESS,
  'response'
);

export const USERS_GET_USER_SUBSCRIPTIONS_FAIL = 'USERS_GET_USER_SUBSCRIPTIONS_FAIL';
export const getUserSubscriptionsRequestFailure = actionBuilder<ApiResponsePayload>(
  USERS_GET_USER_SUBSCRIPTIONS_FAIL,
  'response'
);

// get user orders api request action
export const USERS_GET_USER_ORDERS_REQUEST = 'USERS_GET_USER_ORDERS_REQUEST';
export const getUserOrdersRequestAction = actionBuilder<UserDependentActionPayload>(
  USERS_GET_USER_ORDERS_REQUEST,
  'id'
);

// get user orders api response actions
export const USERS_GET_USER_ORDERS_SUCCESS = 'USERS_GET_USER_ORDERS_SUCCESS';
export const getUserOrdersRequestSuccess = actionBuilder<ApiResponsePayload>(USERS_GET_USER_ORDERS_SUCCESS, 'response');

export const USERS_GET_USER_ORDERS_FAIL = 'USERS_GET_USER_ORDERS_FAIL';
export const getUserOrdersRequestFailure = actionBuilder<ApiResponsePayload>(USERS_GET_USER_ORDERS_FAIL, 'response');

// set user role api request action
export const USERS_SET_USER_ROLE_REQUEST = 'USERS_SET_USER_ROLE_REQUEST';
export interface ChangeUserRoleActionPayload {
  userId: number;
  roleId: number;
}
export const setUserRoleRequestAction = actionBuilder<ChangeUserRoleActionPayload>(
  USERS_SET_USER_ROLE_REQUEST,
  'userId',
  'roleId'
);

// set user role api response actions
export const USERS_SET_USER_ROLE_SUCCESS = 'USERS_SET_USER_ROLE_SUCCESS';
export const setUserRoleRequestSuccess = actionBuilder<ApiResponsePayload>(USERS_SET_USER_ROLE_SUCCESS, 'response');

export const USERS_SET_USER_ROLE_FAIL = 'USERS_SET_USER_ROLE_FAIL';
export const setUserRoleRequestFailure = actionBuilder<ApiResponsePayload>(USERS_SET_USER_ROLE_FAIL, 'response');

// remove user role api request action
export const USERS_REMOVE_USER_ROLE_REQUEST = 'USERS_REMOVE_USER_ROLE_REQUEST';
export const removeUserRoleRequestAction = actionBuilder<ChangeUserRoleActionPayload>(
  USERS_REMOVE_USER_ROLE_REQUEST,
  'userId',
  'roleId'
);

// set user role api response actions
export const USERS_REMOVE_USER_ROLE_SUCCESS = 'USERS_REMOVE_USER_ROLE_SUCCESS';
export const removeUserRoleRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_REMOVE_USER_ROLE_SUCCESS,
  'response'
);

export const USERS_REMOVE_USER_ROLE_FAIL = 'USERS_REMOVE_USER_ROLE_FAIL';
export const removeUserRoleRequestFailure = actionBuilder<ApiResponsePayload>(USERS_REMOVE_USER_ROLE_FAIL, 'response');

// set user role api request action
export const USERS_PATCH_USER_ROLES_REQUEST = 'USERS_PATCH_USER_ROLES_REQUEST';
export interface PatchUserRoleActionPayload {
  userId: number;
  roles: RoleChange[];
}
export const patchUserRolesRequestAction = actionBuilder<ChangeUserRoleActionPayload>(
  USERS_PATCH_USER_ROLES_REQUEST,
  'userId',
  'roles'
);

// set user role api response actions
export const USERS_PATCH_USER_ROLES_SUCCESS = 'USERS_PATCH_USER_ROLES_SUCCESS';
export const patchUserRolesRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_PATCH_USER_ROLES_SUCCESS,
  'response'
);

export const USERS_PATCH_USER_ROLES_FAIL = 'USERS_PATCH_USER_ROLES_FAIL';
export const patchUserRolesRequestFailure = actionBuilder<ApiResponsePayload>(USERS_PATCH_USER_ROLES_FAIL, 'response');

// set user stats api request action
export const USERS_SET_USER_STATS_REQUEST = 'USERS_SET_USER_STATS_REQUEST';
export interface ChangeUserStatsActionPayload {
  userId: number;
  stats: UserStat[];
}
export const setUserStatsRequestAction = actionBuilder<ChangeUserStatsActionPayload>(
  USERS_SET_USER_STATS_REQUEST,
  'userId',
  'stats'
);

// set user role api response actions
export const USERS_SET_USER_STATS_SUCCESS = 'USERS_SET_USER_STATS_SUCCESS';
export const setUserStatsRequestSuccess = actionBuilder<ApiResponsePayload>(USERS_SET_USER_STATS_SUCCESS, 'response');

export const USERS_SET_USER_STATS_FAIL = 'USERS_SET_USER_STATS_FAIL';
export const setUserStatsRequestFailure = actionBuilder<ApiResponsePayload>(USERS_SET_USER_STATS_FAIL, 'response');

// get user newsletter subscription api request action
export const USERS_GET_USER_NEWSLETTER_REQUEST = 'USERS_GET_USER_NEWSLETTER_REQUEST';
export const getUserNewsletterRequestAction = actionBuilder<UserDependentActionPayload>(
  USERS_GET_USER_NEWSLETTER_REQUEST,
  'id'
);

// get user newsletter subscription api response actions
export const USERS_GET_USER_NEWSLETTER_SUCCESS = 'USERS_GET_USER_NEWSLETTER_SUCCESS';
export const getUserNewsletterRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_GET_USER_NEWSLETTER_SUCCESS,
  'response'
);

export const USERS_GET_USER_NEWSLETTER_FAIL = 'USERS_GET_USER_NEWSLETTER_FAIL';
export const getUserNewsletterRequestFailure = actionBuilder<ApiResponsePayload>(
  USERS_GET_USER_NEWSLETTER_FAIL,
  'response'
);

// remove users newsletter subscription api request action
export const USERS_REMOVE_USER_NEWSLETTER_REQUEST = 'USERS_REMOVE_USER_NEWSLETTER_REQUEST';
export const removeUserNewsletterRequestAction = actionBuilder<UserDependentActionPayload>(
  USERS_REMOVE_USER_NEWSLETTER_REQUEST,
  'id'
);

// remove users newsletter subscription api response actions
export const USERS_REMOVE_USER_NEWSLETTER_SUCCESS = 'USERS_REMOVE_USER_NEWSLETTER_SUCCESS';
export const removeUserNewsletterRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_REMOVE_USER_NEWSLETTER_SUCCESS,
  'response'
);

export const USERS_REMOVE_USER_NEWSLETTER_FAIL = 'USERS_REMOVE_USER_NEWSLETTER_FAIL';
export const removeUserNewsletterRequestFailure = actionBuilder<ApiResponsePayload>(
  USERS_REMOVE_USER_NEWSLETTER_FAIL,
  'response'
);

// add users newsletter subscription api request action
export const USERS_ADD_USER_NEWSLETTER_REQUEST = 'USERS_ADD_USER_NEWSLETTER_REQUEST';
export const addUserNewsletterRequestAction = actionBuilder<UserDependentActionPayload>(
  USERS_ADD_USER_NEWSLETTER_REQUEST,
  'id'
);

// add users newsletter subscription api response actions
export const USERS_ADD_USER_NEWSLETTER_SUCCESS = 'USERS_ADD_USER_NEWSLETTER_SUCCESS';
export const addUserNewsletterRequestSuccess = actionBuilder<ApiResponsePayload>(
  USERS_ADD_USER_NEWSLETTER_SUCCESS,
  'response'
);

export const USERS_ADD_USER_NEWSLETTER_FAIL = 'USERS_ADD_USER_NEWSLETTER_FAIL';
export const addUserNewsletterRequestFailure = actionBuilder<ApiResponsePayload>(
  USERS_ADD_USER_NEWSLETTER_FAIL,
  'response'
);

export const USERS_CANCEL_SUBSCRIPTION_REQUEST = 'USERS_CANCEL_SUBSCRIPTION_REQUEST';
export interface CancelSubscriptionRequestPayload {
  userId: number;
  subscriptionId: number;
  terminate: boolean;
}
export interface ICancelSubscriptionRequestAction extends IAction<CancelSubscriptionRequestPayload> {}
export const cancelSubscriptionRequestAction = actionBuilder<CancelSubscriptionRequestPayload>(
  USERS_CANCEL_SUBSCRIPTION_REQUEST,
  'userId',
  'subscriptionId',
  'terminate'
);

export const USERS_CANCEL_SUBSCRIPTION_SUCCESS = 'USERS_CANCEL_SUBSCRIPTION_SUCCESS';
export const cancelSubscriptionRequestSuccessAction = actionBuilder<ApiResponsePayload>(
  USERS_CANCEL_SUBSCRIPTION_SUCCESS,
  'response'
);

export const USERS_CANCEL_SUBSCRIPTION_FAIL = 'USERS_CANCEL_SUBSCRIPTION_FAIL';
export const cancelSubscriptionRequestFailureAction = actionBuilder<ApiResponsePayload>(
  USERS_CANCEL_SUBSCRIPTION_FAIL,
  'response'
);

// delete user api request action
export const USERS_DELETE_USER_REQUEST = 'USERS_DELETE_USER_REQUEST';
export const deleteUserRequestAction = actionBuilder<UserDependentActionPayload>(USERS_DELETE_USER_REQUEST, 'id');

// delete user api response actions
export const USERS_DELETE_USER_SUCCESS = 'USERS_DELETE_USER_SUCCESS';
export const deleteUserRequestSuccess = actionBuilder<ApiResponsePayload>(USERS_DELETE_USER_SUCCESS, 'response');

export const USERS_DELETE_USER_FAIL = 'USERS_DELETE_USER_FAIL';
export const deleteUserRequestFailure = actionBuilder<ApiResponsePayload>(USERS_DELETE_USER_FAIL, 'response');
