import { Action } from 'redux';

import { IEmailData } from './types';

export const EMAILS_LOAD_USER = 'EMAILS_LOAD_USER';
export type EMAILS_LOAD_USER = typeof EMAILS_LOAD_USER;
export interface ILoadUserEmailsAction extends Action<EMAILS_LOAD_USER> {
  type: EMAILS_LOAD_USER;
  userId: number;
}

export const EMAILS_LIST_UPDATE = 'EMAILS_LIST_UPDATE';
export type EMAILS_LIST_UPDATE = typeof EMAILS_LIST_UPDATE;
export interface IUpdateEmailsHistoryAction extends Action<EMAILS_LIST_UPDATE> {
  type: EMAILS_LIST_UPDATE;
  emails: IEmailData[];
}

export type IEmailHistoryActions = ILoadUserEmailsAction | IUpdateEmailsHistoryAction;
