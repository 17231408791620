import { NNProjects } from '../state/types/base';
import * as ActionsTypes from './actionTypes';

export type IntervalPeriod = 'days' | 'weeks' | 'months' | 'years';

export interface ChangeStatusForm {
  amount: number;
  lifetime: boolean;
  period: IntervalPeriod;
}

export interface ChangeStatusState {
  // currently selected project
  project: NNProjects | null;
  // form for changing/adding premium
  form: ChangeStatusForm;
  // add premium form dialog
  open: boolean;
  // cancel/terminate immediately
  immediately: boolean;
  // cancel/terminate subscription dialog
  terminate: boolean;
}

export const emptyChangeStatusForm: ChangeStatusForm = {
  amount: 1,
  lifetime: false,
  period: 'months',
};

export const initialState: ChangeStatusState = {
  project: null,
  form: emptyChangeStatusForm,
  open: false,
  immediately: false,
  terminate: false,
};

export default function status(state = initialState, action: ActionsTypes.StatusActions) {
  switch (action.type) {
    case ActionsTypes.STATUS_CHANGE_DIALOG_TOGGLE: {
      const project = action.project || null;
      const open = !state.open;
      return {
        ...state,
        form: {
          ...emptyChangeStatusForm,
        },
        open,
        project,
      };
    }
    case ActionsTypes.STATUS_CHANGE_LIFETIME_TOGGLE:
      return {
        ...state,
        form: {
          ...state.form,
          lifetime: !state.form.lifetime,
        },
      };
    case ActionsTypes.STATUS_CHANGE_AMOUNT_CHANGE: {
      const amount = action.value;
      return {
        ...state,
        form: {
          ...state.form,
          amount,
        },
      };
    }
    case ActionsTypes.STATUS_CHANGE_PERIOD_CHANGE: {
      const period = action.value;
      return {
        ...state,
        form: {
          ...state.form,
          period,
        },
      };
    }
    case ActionsTypes.STATUS_TERMINATE_DIALOG_TOGGLE: {
      const project = action.project || null;
      const terminate = !state.terminate;
      return {
        ...state,
        immediately: false,
        project,
        terminate,
      };
    }
    case ActionsTypes.STATUS_TERMINATE_SUBMIT: {
      return {
        ...state,
        terminate: false,
      };
    }
    case ActionsTypes.STATUS_TERMINATE_IMMEDIATELY_UPDATE: {
      const immediately = !state.immediately;
      return {
        ...state,
        immediately,
      };
    }
    case ActionsTypes.STATUS_CHANGE_SUCCESS:
    case ActionsTypes.STATUS_CHANGE_FAIL: {
      return {
        ...state,
        open: false,
        project: null,
      };
    }
    default:
      return state;
  }
}
