import * as ActionTypes from './actionTypes';

export interface MessagesState {
  // user id of conversation participant if opened
  userId: number | null;
  // message to send to the user
  message: string;
}

export const initialState: MessagesState = {
  userId: null,
  message: '',
};

export default function messages(state = initialState, action: ActionTypes.IMessagesActions) {
  switch (action.type) {
    case ActionTypes.MESSAGES_OPEN_CONVERSATION: {
      const newUserId = action.userId || null;
      const oldUserId = state.userId;
      const userId = newUserId !== oldUserId ? newUserId : null;
      return {
        ...state,
        userId,
        message: '',
      };
    }
    case ActionTypes.MESSAGES_ADMIN_MESSAGE_CHANGE: {
      const value = action.value;
      return {
        ...state,
        message: value,
      };
    }
  }
  return state;
}
