import { Callout, Card, Classes, Elevation, H3, Icon, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CyclesWrapper } from '../cycles';
import { EmailHistory } from '../emailHistory';
import { AppState } from '../state/reducers';
import { getUser } from '../state/selectors';
import { IExtendedUser } from '../state/types/state';
import './UserHistory.scss';

export interface OwnProps {
  userId: number;
}

export interface Props extends OwnProps {
  user: IExtendedUser;
}

export enum UserHistoryTab {
  Email = 'email',
  Cycles = 'cycles',
}

export function UserHistory({ user, userId }: Props) {
  if (!user) {
    return null;
  }
  const linkClasses = `${Classes.BUTTON} ${Classes.SMALL} ${Classes.MINIMAL}`;
  const registeredDate = moment(+user.created * 1000).format('DD.MM.YYYY, HH:mm:ss');
  const name = user.name || user.username;
  return (
    <Card elevation={Elevation.ONE} className="user-history-page">
      <H3 className={Classes.HEADING}>
        <Link className={linkClasses} to="/">
          <Icon iconSize={25} icon={IconNames.CHEVRON_LEFT} />
        </Link>
        <span>
          &nbsp;{name} ({userId})
        </span>
      </H3>
      <div className="main-info">
        <Callout className="registration-date">
          <Icon icon={IconNames.CALENDAR} />
          &nbsp; User registered: {registeredDate}
        </Callout>
        <Tabs id="user-history-tabs">
          <Tab id={UserHistoryTab.Email} title="Emails" panel={<EmailHistory userId={userId} />} />
          <Tab id={UserHistoryTab.Cycles} title="Training" panel={<CyclesWrapper userId={userId} />} />
        </Tabs>
      </div>
    </Card>
  );
}

export const mapStateToProps = (state: AppState, props: OwnProps) => {
  const user = getUser(state, props.userId) as IExtendedUser;
  return {
    user,
  };
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserHistory);
