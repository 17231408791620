import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { NotificationActions } from '../notifications';
import { searchUserByIdAction } from '../search/actions';
import { ApiResponse } from '../services/apiResponse';
import { patchUserRolesRequestAction, USERS_PATCH_USER_ROLES_SUCCESS } from '../state/actions/users';
import { getUser } from '../state/selectors';
import { RoleChange } from '../state/types/base';
import { IExtendedUser } from '../state/types/state';
import { StatsActions, StatsSelectors } from '../userStats';
import { IUserStatPost } from '../userStats/types';
import * as ActionTypes from './actionTypes';
import ProfileService from './profileService';

export function* loadUserProfileSaga(action: ActionTypes.ILoadUserProfileAction) {
  const userId = action.userId;
  const stats = yield select(StatsSelectors.getUserStats, userId);
  if (!stats) {
    yield put(searchUserByIdAction(userId));
  }
}

export function* saveUserProfileSaga({
  userId,
  mail,
  password,
  language,
  timezone,
  changeConfirmation,
}: ActionTypes.ISaveUserProfileAction) {
  if (!ProfileService.isReady()) {
    yield call(ProfileService.init, ApiService);
  }
  const user: IExtendedUser = yield select(getUser, userId);
  const emailChanged = mail !== user.mail;
  const passwordChanged = !!password;
  const languageChanged = language !== user.language;
  const profileChanged = emailChanged || passwordChanged || languageChanged;
  const credentialsChanged = emailChanged || passwordChanged;
  // update profile data if necessary
  if (profileChanged) {
    const userData: Partial<IExtendedUser> = {};
    if (emailChanged) {
      userData.mail = mail;
    }
    if (languageChanged) {
      userData.language = language;
    }
    if (passwordChanged) {
      userData.password = password;
    }
    if (credentialsChanged) {
      userData.changeConfirmation = changeConfirmation;
    }
    let response;
    try {
      response = new ApiResponse(yield call(ProfileService.saveUser, userId, userData));
    } catch (error) {
      response = new ApiResponse((error as HttpError).baseError ?? error);
    }
    if (response.isError()) {
      const mailExist = response.status === 422 && response.data.message === 'Mail already exists';
      const message = credentialsChanged
        ? mailExist
          ? 'Mail already exists'
          : 'Request rejected due to lack of proof.'
        : 'Saving user failed!';
      yield put(NotificationActions.showError(message));
      return;
    }
    // refresh user profile data
    yield put(searchUserByIdAction(userId));
  }
  // update user stats if necessary
  const userTimezone = yield select(StatsSelectors.getUserTimezone, userId);
  const timezoneChanged = timezone !== userTimezone;
  if (timezoneChanged) {
    const timezoneStat: IUserStatPost = {
      statType: 4,
      statId: 6,
      statComponentId: 1,
      valueInt: 0,
      valueTxt: timezone || '',
      operator: 'UPD',
      time: -1,
    };
    yield put(StatsActions.saveUserStatsAction(userId, [timezoneStat]));
  }
}

export function* removeRoleSaga(action: ActionTypes.IRemoveRoleAction) {
  const { userId, roleId } = action;
  const roleChange: RoleChange = {
    id: roleId,
    period: '-1 years',
  };
  yield put(patchUserRolesRequestAction(userId, [roleChange]));
  yield take(USERS_PATCH_USER_ROLES_SUCCESS);
  yield put(searchUserByIdAction(userId));
}

export function* addRoleSaga(action: ActionTypes.IAddRoleAction) {
  const { userId, roleId } = action;
  const roleChange: RoleChange = {
    id: roleId,
  };
  yield put(patchUserRolesRequestAction(userId, [roleChange]));
  yield take(USERS_PATCH_USER_ROLES_SUCCESS);
  yield put(searchUserByIdAction(userId));
}

export default function* profileWatcher() {
  yield all([
    takeEvery(ActionTypes.PROFILE_LOAD_USER, loadUserProfileSaga),
    takeEvery(ActionTypes.PROFILE_SAVE_USER, saveUserProfileSaga),
    takeEvery(ActionTypes.PROFILE_REMOVE_ROLE, removeRoleSaga),
    takeEvery(ActionTypes.PROFILE_ADD_ROLE, addRoleSaga),
  ]);
}
