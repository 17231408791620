/**
 * Saga implementing users related api calls using UsersService
 */
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { ApiResponse } from '../../services/apiResponse';
import UsersService from '../../services/usersService';
import * as UsersActions from '../actions/users';
import { IAction, UserDependentAction } from '../types/actions';

export function* handleServiceInit() {
  yield call(UsersService.init, ApiService);
}

export function* handleSearchUsersByUsername(action: IAction<{ search: string }>) {
  try {
    const mail = action.payload.search;
    const response = yield call(UsersService.searchUsers, mail);
    yield put(UsersActions.searchUsersUsernameSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.searchUsersUsernameFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

export function* handleGetUserById(action: UserDependentAction) {
  const userId = action.payload.id;
  try {
    const response = yield call(UsersService.getUser, userId);
    yield put(UsersActions.getUserSuccess(new ApiResponse(response), userId));
  } catch (error) {
    yield put(UsersActions.getUserFailure(new ApiResponse((error as HttpError).baseError ?? error), userId));
  }
}

export function* handleGetUserSubscriptions(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.getUserSubscriptions, userId);
    yield put(UsersActions.getUserSubscriptionsRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(
      UsersActions.getUserSubscriptionsRequestFailure(new ApiResponse((error as HttpError).baseError ?? error))
    );
  }
}

// see when it would be the best time to call it without afecting performance
export function* handleGetUserOrders(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.getUserOrders, userId);
    yield put(UsersActions.getUserOrdersRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.getUserOrdersRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// add a role to the user
export function* handleSetUserRole(action: IAction<UsersActions.ChangeUserRoleActionPayload>) {
  try {
    const userId = action.payload.userId;
    const roleId = action.payload.roleId;
    const response = yield call(UsersService.setUserRole, userId, roleId);
    yield put(UsersActions.setUserRoleRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.setUserRoleRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

export function* handlePatchUserRoles(action: IAction<UsersActions.PatchUserRoleActionPayload>) {
  try {
    const userId = action.payload.userId;
    const roles = action.payload.roles;
    const response = yield call(UsersService.patchUserRoles, userId, roles);
    yield put(UsersActions.patchUserRolesRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.patchUserRolesRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// remove a role from the user
export function* handleRemoveUserRole(action: IAction<UsersActions.ChangeUserRoleActionPayload>) {
  try {
    const userId = action.payload.userId;
    const roleId = action.payload.roleId;
    const response = yield call(UsersService.removeUserRole, userId, roleId);
    yield put(UsersActions.removeUserRoleRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.removeUserRoleRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// set user stats
export function* handleSetUserStats(action: IAction<UsersActions.ChangeUserStatsActionPayload>) {
  try {
    const userId = action.payload.userId;
    const stats = action.payload.stats;
    const response = yield call(UsersService.setUserStats, userId, stats);
    yield put(UsersActions.setUserStatsRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.setUserStatsRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// get users newsletter status
export function* handleGetUserNewsletter(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.getUserNewsletter, userId);
    yield put(UsersActions.getUserNewsletterRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.getUserNewsletterRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// delete users newsletter
export function* handleRemoveUserNewsletter(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.removeUserNewsletter, userId);
    yield put(UsersActions.removeUserNewsletterRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(
      UsersActions.removeUserNewsletterRequestFailure(new ApiResponse((error as HttpError).baseError ?? error))
    );
  }
}

// add user to our newsletter
export function* handleAddUserNewsletter(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.addUserNewsletter, userId);
    yield put(UsersActions.addUserNewsletterRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.addUserNewsletterRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// terminate user's subscription
export function* handleCancelUserSubscription(action: UsersActions.ICancelSubscriptionRequestAction) {
  try {
    const { userId, subscriptionId, terminate } = action.payload;
    const response = yield call(UsersService.cancelUserSubscription, userId, subscriptionId, terminate);
    yield put(UsersActions.cancelSubscriptionRequestSuccessAction(new ApiResponse(response)));
  } catch (error) {
    yield put(
      UsersActions.cancelSubscriptionRequestFailureAction(new ApiResponse((error as HttpError).baseError ?? error))
    );
  }
}

// search orders for given user first/last name or order id
export function* handleSearchUserOrders(action: UsersActions.ISearchOrdersAction) {
  try {
    const { name, orderId } = action;
    const response = yield call(UsersService.searchOrders, name, orderId);
    yield put(UsersActions.searchOrdersRequestSuccessAction(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.searchOrdersRequestFailedAction(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

// delete user by id
export function* handleDeleteUserRequest(action: UserDependentAction) {
  try {
    const userId = action.payload.id;
    const response = yield call(UsersService.deleteUser, userId);
    yield put(UsersActions.deleteUserRequestSuccess(new ApiResponse(response)));
  } catch (error) {
    yield put(UsersActions.deleteUserRequestFailure(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

export default function* usersWatcher() {
  yield all([
    takeEvery(UsersActions.USERS_INIT, handleServiceInit),
    takeEvery(UsersActions.USERS_SEARCH_USERNAME_REQUEST, handleSearchUsersByUsername),
    takeEvery(UsersActions.USERS_GET_USER_BY_ID_REQUEST, handleGetUserById),
    takeEvery(UsersActions.USERS_GET_USER_SUBSCRIPTIONS_REQUEST, handleGetUserSubscriptions),
    takeEvery(UsersActions.USERS_GET_USER_ORDERS_REQUEST, handleGetUserOrders),
    takeEvery(UsersActions.USERS_SET_USER_ROLE_REQUEST, handleSetUserRole),
    takeEvery(UsersActions.USERS_REMOVE_USER_ROLE_REQUEST, handleRemoveUserRole),
    takeEvery(UsersActions.USERS_PATCH_USER_ROLES_REQUEST, handlePatchUserRoles),
    takeEvery(UsersActions.USERS_SET_USER_STATS_REQUEST, handleSetUserStats),
    takeEvery(UsersActions.USERS_GET_USER_NEWSLETTER_REQUEST, handleGetUserNewsletter),
    takeEvery(UsersActions.USERS_REMOVE_USER_NEWSLETTER_REQUEST, handleRemoveUserNewsletter),
    takeEvery(UsersActions.USERS_ADD_USER_NEWSLETTER_REQUEST, handleAddUserNewsletter),
    takeEvery(UsersActions.USERS_CANCEL_SUBSCRIPTION_REQUEST, handleCancelUserSubscription),
    takeEvery(UsersActions.USERS_SEARCH_ORDERS_REQUEST, handleSearchUserOrders),
    takeEvery(UsersActions.USERS_DELETE_USER_REQUEST, handleDeleteUserRequest),
  ]);
}
