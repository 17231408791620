import { ApiResponse } from '../services/apiResponse';
import { IOrderData, IAddress } from '../state/types/base';
import * as ActionTypes from './actionTypes';

// get all orders for given user
export const getUserOrdersAction: (id: number) => ActionTypes.IGetUserOrdersAction = (id) => ({
  type: ActionTypes.ORDERS_GET_USER_ORDERS,
  id,
});

// orders were loaded successfully for given user
export const userOrdersUpdateAction: (id: number, orders: IOrderData[]) => ActionTypes.IGetUserOrdersSuccessAction = (
  id,
  orders
) => ({
  type: ActionTypes.ORDERS_GET_USER_ORDERS_SUCCESS,
  id,
  orders,
});

// get all orders for given user request has failed
export const getUserOrdersFailedAction: (id: number) => ActionTypes.IGetUserOrdersFailedAction = (id) => ({
  type: ActionTypes.ORDERS_GET_USER_ORDERS_FAILED,
  id,
});

// select a particular user order
export const selectOrderAction: (id: number, referenceId: string) => ActionTypes.ISelectOrderAction = (
  id,
  referenceId
) => ({
  type: ActionTypes.ORDERS_SELECT_ORDER,
  id,
  referenceId,
});

// deselect a user order / close dialog
export const deselectOrderAction: (id: number) => ActionTypes.IDeselectOrderAction = (id) => ({
  type: ActionTypes.ORDERS_DESELECT_ORDER,
  id,
});

// change the order status from PENDING to SUCCESS state
export const completeOrderAction: (referenceId: string) => ActionTypes.ICompleteOrderAction = (referenceId) => ({
  type: ActionTypes.ORDERS_COMPLETE_ORDER,
  referenceId,
});

// change the order status from PENDING to SUCCESS state (API call)
export const completeOrderRequestAction: (
  userId: number,
  referenceId: string
) => ActionTypes.ICompleteOrderRequestAction = (userId, referenceId) => ({
  type: ActionTypes.ORDERS_COMPLETE_ORDER_REQUEST,
  referenceId,
  userId,
});

export const completeOrderSuccessAction: (response: ApiResponse) => ActionTypes.ICompleteOrderSuccessAction = (
  response
) => ({
  type: ActionTypes.ORDERS_COMPLETE_ORDER_SUCCESS,
  response,
});

export const completeOrderFailedAction: (response: ApiResponse) => ActionTypes.ICompleteOrderFailAction = (
  response
) => ({
  type: ActionTypes.ORDERS_COMPLETE_ORDER_FAIL,
  response,
});

export const updateOrdersDisputeAction: (id: number, dispute: string) => ActionTypes.IUpdateOrdersDisputeAction = (
  id,
  dispute
) => ({
  type: ActionTypes.ORDERS_UPDATE_DISPUTE,
  id,
  dispute,
});

export const copyToClipBoardAction: (html: string) => ActionTypes.ICopyToClipboardAction = (html) => ({
  type: ActionTypes.COPY_TO_CLIPBOARD,
  html,
});

// get all addresses for given user
export const fetchUserAddressesAction: (userId: number) => ActionTypes.IFetchAddressesAction = (userId) => ({
  type: ActionTypes.ORDERS_FETCH_ADDRESSES,
  userId,
});

export const updateUserAddressesAction: (userId: number, addresses: IAddress[]) => ActionTypes.IUserAddressesAction = (
  userId,
  addresses
) => ({
  type: ActionTypes.ORDERS_USER_ADDRESSES,
  userId,
  addresses,
});

export const saveAddressAction: (userId: number, address: IAddress) => ActionTypes.ISaveAddressAction = (
  userId,
  address
) => ({
  type: ActionTypes.ORDERS_SAVE_ADDRESS,
  userId,
  address,
});
