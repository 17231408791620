export enum ECouponStatus {
  Free = 0,
  Reserved = 1,
  UsedByUser = 2,
}

export interface IFreeCouponStatus {
  code: string;
  duration: string;
  status: ECouponStatus.Free;
  reserved_until: null;
  used_by_user_id: null;
  updated_at: string;
  created_at: string;
}

export interface IReservedCouponStatus {
  code: string;
  duration: string;
  status: ECouponStatus.Reserved;
  reserved_until: string;
  used_by_user_id: null;
  updated_at: string;
  created_at: string;
}

export interface IUsedCouponStatus {
  code: string;
  duration: string;
  status: ECouponStatus.UsedByUser;
  reserved_until: null;
  used_by_user_id: number;
  updated_at: string;
  created_at: string;
}

export type TPossibleCouponStatus = IFreeCouponStatus | IReservedCouponStatus | IUsedCouponStatus;
