import { Menu, MenuItem } from '@blueprintjs/core';
import { TColumnDefinition } from './types';

interface IColumnsSelectorProps {
  columns: TColumnDefinition[];
  onToggleColumVisibility: (index: number) => void;
}

export default function ColumnsSelector({ columns, onToggleColumVisibility }: IColumnsSelectorProps) {
  return (
    <Menu>
      {columns.map(({ columnName, columnTitle, hide }, index) => {
        return (
          <MenuItem
            key={columnName}
            active={!hide}
            text={columnTitle ?? columnName}
            onClick={() => onToggleColumVisibility(index)}
          />
        );
      })}
    </Menu>
  );
}
