import * as ActionTypes from './actionTypes';
import { TPossibleCouponStatus } from './types';

export interface ICouponState {
  // loading flag
  loading: boolean;
  // last coupon search
  coupon?: string;
  // status of a coupon
  status?: TPossibleCouponStatus;
}

export const initialState: ICouponState = {
  loading: false,
};

export const statePropName = 'coupon';

export default function couponReducer(
  state: ICouponState = initialState,
  action: ActionTypes.ICouponActions
): ICouponState {
  switch (action.type) {
    case ActionTypes.COUPON_SEARCH: {
      const { coupon } = action;
      return {
        ...state,
        loading: true,
        coupon,
      };
    }
    case ActionTypes.COUPON_NOT_FOUND: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.COUPON_SEARCH_RESET: {
      return {
        ...state,
        loading: false,
        coupon: undefined,
        status: undefined,
      };
    }
    case ActionTypes.COUPON_SEARCH_RESULT: {
      const { status } = action;
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
}
