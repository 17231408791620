import { IApiService } from 'web_core_library';
import { RoleChange, UserStat } from '../state/types/base';

export function createUsersService() {
  let ApiService: IApiService;

  // search users by email or username
  const searchUsers = (mail: string) => {
    return ApiService.action('users_search', {
      method: 'get',
      params: {
        mail,
      },
    });
  };

  // get detailed user info by given id
  const getUser = (userId: number) => {
    return ApiService.action('users', {
      method: 'get',
      params: {
        fields: 'roles',
      },
      urlParams: {
        userId,
      },
    });
  };

  // get subscriptions for the given user
  const getUserSubscriptions = (userId: number) => {
    return ApiService.action('users_subscriptions_v2', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  };

  // get orders for the given user
  const getUserOrders = (userId: number) => {
    return ApiService.action('users_orders_v2', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  };

  // not used currently: replaced by patchUserRoles
  const setUserRole = (userId: number, roleId: number) => {
    return ApiService.action('users_roles', {
      method: 'post',
      urlParams: {
        roleId,
        userId,
      },
    });
  };

  // not used currently: replaced by patchUserRoles
  const removeUserRole = (userId: number, roleId: number) => {
    return ApiService.action('users_roles', {
      method: 'delete',
      urlParams: {
        roleId,
        userId,
      },
    });
  };

  const patchUserRoles = (userId: number, roles: RoleChange[]) => {
    return ApiService.action('users_roles', {
      method: 'patch',
      data: roles,
      urlParams: {
        roleId: '',
        userId,
      },
    });
  };

  const setUserStats = (userId: number, stats: UserStat[]) => {
    return ApiService.action('users_stats', {
      data: stats,
      method: 'patch',
      urlParams: {
        userId,
      },
    });
  };

  // get newsletter subscriptions for the given user
  const getUserNewsletter = (userId: number) => {
    return ApiService.action('users_newsletter', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  };

  // add newsletter subscription for the given user
  const addUserNewsletter = (userId: number) => {
    return ApiService.action('users_newsletter', {
      method: 'post',
      urlParams: {
        userId,
      },
    });
  };

  // remove newsletter subscription for the given user
  const removeUserNewsletter = (userId: number) => {
    return ApiService.action('users_newsletter', {
      method: 'delete',
      urlParams: {
        userId,
      },
    });
  };

  // cancel subscription for the given user
  const cancelUserSubscription = (userId: number, subscriptionId: number, terminate: boolean) => {
    return ApiService.action('admin_web_subscriptions', {
      method: 'post',
      params: {
        end_of_period: terminate ? 0 : 1,
      },
      urlParams: {
        subscriptionId,
        userId,
      },
    });
  };

  const searchOrders = (name?: string, orderId?: string) => {
    return ApiService.action('admin_orders_search_v2', {
      method: 'get',
      params: {
        name,
        orderId,
      },
    });
  };

  const deleteUser = (userId: number) => {
    return ApiService.action('users_delete', {
      method: 'delete',
      urlParams: {
        userId,
      },
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    searchUsers,
    getUser,
    getUserSubscriptions,
    getUserOrders,
    setUserRole,
    removeUserRole,
    patchUserRoles,
    setUserStats,
    getUserNewsletter,
    addUserNewsletter,
    removeUserNewsletter,
    cancelUserSubscription,
    searchOrders,
    deleteUser,
  };
  return service;
}

const UsersService = createUsersService();
export default UsersService;
