import reduce from 'lodash/reduce';
import { IAction, IBaseAction } from '../types/actions';
import { IUserBaseData, UserMap } from '../types/state';

import * as SearchActionTypes from '../../search/actionTypes';
import { USERS_USER_SUBSCRIPTION_UPDATE, UserSubscriptionUpdateActionPayload } from '../actions/details';
import {
  CancelSubscriptionRequestPayload,
  GetUserResultActionPayload,
  USERS_CANCEL_SUBSCRIPTION_REQUEST,
  USERS_GET_USER_RESULT,
} from '../actions/users';

export const initialState: UserMap = {};

export default function users(state = initialState, action: IBaseAction) {
  switch (action.type) {
    case SearchActionTypes.USERS_SEARCH_RESULTS: {
      const initial = {
        ...state,
      };
      const usersList = (action as SearchActionTypes.ISearchUsersResultAction).users;
      return reduce(
        usersList,
        (map: UserMap, user: IUserBaseData) => {
          map[user.id] = user;
          return map;
        },
        initial
      );
    }
    case USERS_GET_USER_RESULT: {
      const user = (action as IAction<GetUserResultActionPayload>).payload.user;
      const existingUser = state[user.userId];
      return {
        ...state,
        [user.userId]: {
          ...existingUser,
          ...user,
        },
      };
    }
    case USERS_USER_SUBSCRIPTION_UPDATE: {
      const { id, subscriptions } = (action as IAction<UserSubscriptionUpdateActionPayload>).payload;
      const user = state[id];
      return {
        ...state,
        [id]: {
          ...user,
          subscriptions,
        },
      };
    }
    case USERS_CANCEL_SUBSCRIPTION_REQUEST: {
      const { userId } = (action as IAction<CancelSubscriptionRequestPayload>).payload;
      const user = state[userId];
      return {
        ...state,
        [userId]: {
          ...user,
          subscriptions: [],
        },
      };
    }
    default:
      break;
  }
  return state;
}
