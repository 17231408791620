import { Classes } from '@blueprintjs/core';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { localStorage } from 'web_core_library';
import { AuthSelectors } from '../auth';
import { updateThemeAction } from './actions';
import * as ActionTypes from './actionTypes';
import { Themes } from './types';

export function* restoreTheme() {
  const userId = yield select(AuthSelectors.getAuthUserId);
  if (!userId) {
    return;
  }
  const theme = localStorage.load(`${userId}.theme`) || Themes.Dark;
  const body = document.getElementById('app-body')!;
  const isBodyDark = body.className === Classes.DARK;
  const needUpdate = (theme === Themes.Dark && !isBodyDark) || (theme === Themes.Light && isBodyDark);

  if (needUpdate) {
    yield put(updateThemeAction(theme));
  }
}

export function* updateTheme(action: ActionTypes.IUpdateThemeAction) {
  const userId = yield select(AuthSelectors.getAuthUserId);
  if (!userId) {
    return;
  }
  const theme = action.theme;
  const body = document.getElementById('app-body')!;
  body.className = theme === Themes.Dark ? Classes.DARK : '';
  localStorage.save(`${userId}.theme`, theme);
}

export default function* themeWatcher() {
  yield all([takeEvery(ActionTypes.THEME_RESTORE, restoreTheme), takeEvery(ActionTypes.THEME_UPDATE, updateTheme)]);
}
