import { Intent } from '@blueprintjs/core';
import { Action } from 'redux';
import { MessageContent } from './types';

export const MESSAGES_SHOW_MESSAGE = 'MESSAGES_SHOW_MESSAGE';
export type MESSAGES_SHOW_MESSAGE = typeof MESSAGES_SHOW_MESSAGE;
export interface IShowNotificationAction extends Action<MESSAGES_SHOW_MESSAGE> {
  type: MESSAGES_SHOW_MESSAGE;
  message: MessageContent;
  intent: Intent;
}

export type TNotificationsActions = IShowNotificationAction;
