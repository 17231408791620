import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';

function getRenderer(single = false) {
  const StringItemRenderer: ItemRenderer<string> = (item, props) => (
    <MenuItem
      roleStructure="listoption"
      shouldDismissPopover={single}
      active={props.modifiers.active}
      onClick={props.handleClick}
      onFocus={props.handleFocus}
      ref={props.ref}
      key={item}
      text={item}
    />
  );
  return StringItemRenderer;
}

export default getRenderer;
