import { AxiosError, AxiosResponse } from 'axios';

import { HttpStatusCode } from './constants';

function instanceOfAxiosError(object: any): object is AxiosError {
  return 'response' in object;
}

/**
 * base response class
 */
export class ApiResponse {
  public data: any;

  public status: number;
  public statusText: string;

  constructor(response: AxiosResponse | AxiosError | Error | { data: any; status: number; statusText: string }) {
    if (response instanceof Error && !instanceOfAxiosError(response)) {
      this.status = HttpStatusCode.InternalError;
      this.statusText = response.message;
      this.data = response.message;
    } else {
      const realResponse = instanceOfAxiosError(response) ? (response.response as AxiosResponse) : response;
      // realResponse is undefined for network errors otherwise it represents the response
      this.status = realResponse ? realResponse.status : HttpStatusCode.Unknown;
      this.statusText = realResponse ? realResponse.statusText : 'Unknown error';
      this.data = realResponse ? realResponse.data : {};
    }
  }

  public isSuccess() {
    return this.status > 0 && this.status < 300;
  }

  public isError() {
    return !this.isSuccess();
  }

  public isUnauthorized() {
    return this.isError() && this.status === HttpStatusCode.Unauthorized;
  }

  public isEmpty() {
    return this.isError() && this.status === HttpStatusCode.NotFound;
  }
}
