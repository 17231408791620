import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersLanguages } from '../../selectors';

interface ILanguagesFilterProps {
  selectedLanguages: string[];
  onChange: (languages: string[]) => void;
}

export default function LanguagesFilter({ selectedLanguages, onChange }: ILanguagesFilterProps) {
  const availableLanguages = useSelector(getFiltersLanguages);
  return (
    <StringListMultiSelector
      items={availableLanguages}
      label={'Languages'}
      onChange={onChange}
      selectedItems={selectedLanguages}
    />
  );
}
