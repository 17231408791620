import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { IReservedCouponStatus } from './types';

interface IReservedCouponStatusWidgetProps {
  coupon: IReservedCouponStatus;
}

export default function ReservedCouponStatusWidget({ coupon }: IReservedCouponStatusWidgetProps) {
  return (
    <Callout intent={Intent.WARNING} icon={IconNames.DOLLAR} title="Reserved coupon found:">
      <p>Coupon code: {coupon.code}</p>
      <p>Created at: {coupon.created_at}</p>
      <p>Duration: {coupon.duration}</p>
      <p>Reserved until: {coupon.reserved_until}</p>
    </Callout>
  );
}
