import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import * as Types from './types';

const hasActiveStatus = (status: Types.ETrainingStatus) =>
  status === Types.ETrainingStatus.None || status === Types.ETrainingStatus.Ongoing;

function completeWorkout(workout: Types.IWorkoutStatus) {
  const mustSkip = hasActiveStatus(workout.status);
  return {
    ...workout,
    finishedTimestamp: mustSkip ? -1 : workout.finishedTimestamp,
    status: mustSkip ? Types.ETrainingStatus.Skipped : workout.status,
  } as Types.IWorkoutStatus;
}

function completeSession(session: Types.ISessionStatus) {
  const workouts = session.workouts;
  const updatedWorkouts: Types.IWorkoutStatus[] = map(workouts, completeWorkout);
  const mustComplete = hasActiveStatus(session.status);
  return {
    ...session,
    workouts: updatedWorkouts,
    status: mustComplete ? Types.ETrainingStatus.Finished : session.status,
    finishedTimestamp: mustComplete ? -1 : session.finishedTimestamp,
  } as Types.ISessionStatus;
}

function resetCycle(cycle: Types.ICycleStatus) {
  const updatedSessions = map(cycle.sessions, completeSession);
  const updatedCycle: Types.ICycleStatus = {
    ...cycle,
    finishedTimestamp: -1,
    sessions: updatedSessions,
  };
  return updatedCycle;
}

export function resetCycles(cycles: Types.ICycleStatus[]) {
  return map(cycles, resetCycle);
}

const mergeSession = (session1: Types.ISessionStatus, session2: Types.ISessionStatus) => {
  const workouts1 = sortBy(session1.workouts, 'index');
  const workouts2 = sortBy(session2.workouts, 'index');
  const result: Types.ISessionStatus = {
    ...session1,
    ...session2,
    workouts: [...workouts1],
  };
  workouts2.forEach((workout, index) => {
    const newWorkout = result.workouts[index];
    result.workouts[index] = {
      ...newWorkout,
      ...workout,
    };
  });
  return result;
};

interface ISessionMap {
  [sessionId: number]: Types.ISessionStatus;
}

const mergeSessions = (sessions1: Types.ISessionStatus[], sessions2: Types.ISessionStatus[]) => {
  const sessionMap: ISessionMap = reduce(
    sessions1,
    (acc, session) => {
      acc[session.sessionId] = session;
      return acc;
    },
    {} as ISessionMap
  );
  sessions2.forEach((session) => {
    const existingSession = sessionMap[session.sessionId];
    sessionMap[session.sessionId] = existingSession ? mergeSession(existingSession, session) : session;
  });
  return Object.keys(sessionMap).map((sessionId) => sessionMap[+sessionId]);
};

const mergeCycle = (cycle1: Types.ICycleStatus, cycle2: Types.ICycleStatus) => {
  return {
    ...cycle1,
    ...cycle2,
    sessions: mergeSessions(cycle1.sessions, cycle2.sessions),
  } as Types.ICycleStatus;
};

interface ICycleMap {
  [cycleId: number]: Types.ICycleStatus;
}

export const mergeCycles = (cycles1: Types.ICycleStatus[], cycles2: Types.ICycleStatus[]) => {
  const cycleMap: ICycleMap = reduce(
    cycles1,
    (acc, cycle) => {
      acc[cycle.cycleId] = cycle;
      return acc;
    },
    {} as ICycleMap
  );
  cycles2.forEach((cycle) => {
    const existingCycle = cycleMap[cycle.cycleId];
    cycleMap[cycle.cycleId] = existingCycle ? mergeCycle(existingCycle, cycle) : cycle;
  });
  return Object.keys(cycleMap).map((cycleId) => cycleMap[+cycleId]);
};
