import * as ActionTypes from './actionTypes';
import { IEmailData } from './types';

// load users email history data if was not loaded
export const loadUserEmailHistoryAction: (userId: number) => ActionTypes.ILoadUserEmailsAction = (userId) => ({
  type: ActionTypes.EMAILS_LOAD_USER,
  userId,
});

// update emails history list with received data
export const updateEmailHistoryAction: (emails: IEmailData[]) => ActionTypes.IUpdateEmailsHistoryAction = (emails) => ({
  type: ActionTypes.EMAILS_LIST_UPDATE,
  emails,
});
