export function copyToClipboard(content: string) {
  try {
    // try the modern approach first
    navigator.clipboard.writeText(content);
    return true;
  } catch {
    try {
      // if modern approach failed, try the older way
      const clipboardDiv = document.createElement('div');
      clipboardDiv.style.fontSize = '12pt'; // Prevent zooming on iOS
      // Reset box model
      clipboardDiv.style.border = '0';
      clipboardDiv.style.padding = '0';
      clipboardDiv.style.margin = '0';
      // Move element out of screen
      clipboardDiv.style.position = 'fixed';
      clipboardDiv.style.right = '-9999px';
      clipboardDiv.style.top = (window.pageYOffset || document.documentElement.scrollTop) + 'px';
      // more hiding
      clipboardDiv.setAttribute('readonly', '');
      clipboardDiv.style.opacity = '0';
      clipboardDiv.style.pointerEvents = 'none';
      clipboardDiv.style.zIndex = '-1';
      clipboardDiv.style.backgroundColor = 'white';
      clipboardDiv.style.color = 'black';
      clipboardDiv.setAttribute('tabindex', '0'); // so it can be focused
      clipboardDiv.innerHTML = '';
      document.body.appendChild(clipboardDiv);
      clipboardDiv.innerHTML = content;

      clipboardDiv.focus();
      let selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
      const range = document.createRange();
      const { firstChild, lastChild } = clipboardDiv;
      if (firstChild && lastChild) {
        range.setStartBefore(firstChild);
        range.setEndAfter(lastChild);
      }
      selection = window.getSelection();
      if (selection) {
        selection.addRange(range);
      }
      const successful = document.execCommand('copy');
      document.body.removeChild(clipboardDiv);
      return successful;
    } catch {
      console.warn('Copy to clipboard failed. Please try to copy manually: ', content);
      return false;
    }
  }
}
