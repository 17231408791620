import { Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import * as Selectors from './selectors';
import { IEmailData } from './types';

export interface Props {
  emails: IEmailData[];
}

export function EmailHistoryTable({ emails }: Props) {
  const { HTML_TABLE, HTML_TABLE_STRIPED, INTERACTIVE } = Classes;
  const classesList = `email-history-table ${HTML_TABLE} ${HTML_TABLE_STRIPED} ${INTERACTIVE}`;
  const emailsList = emails.map((email, index) => {
    const formatedDate = moment(email.createdAt).format('DD.MM.YYYY, HH:mm:ss');
    return (
      <tr key={index}>
        <td>{formatedDate}</td>
        <td>{email.to}</td>
        <td>{email.template}</td>
        <td>{email.locale}</td>
      </tr>
    );
  });
  return (
    <table className={classesList}>
      <thead>
        <tr>
          <th>
            <Icon icon={IconNames.TIME} /> Date
          </th>
          <th>
            <Icon icon={IconNames.ENVELOPE} /> E-Mail
          </th>
          <th>
            <Icon icon={IconNames.ANNOTATION} /> Template
          </th>
          <th>
            <Icon icon={IconNames.TRANSLATE} />
          </th>
        </tr>
      </thead>
      <tbody>{emailsList}</tbody>
    </table>
  );
}

export const mapStateToProps = (state: Selectors.IAppState) => {
  const emails = Selectors.getEmailsHistoryList(state);
  return {
    emails,
  };
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmailHistoryTable);
