import { NonIdealState } from '@blueprintjs/core';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import FreeCouponStatusWidget from './FreeCouponStatusWidget';
import ReservedCouponStatusWidget from './ReservedCouponStatusWidget';
import { isLoading, isCouponSubmitted, getCouponStatus } from './selectors';
import { ECouponStatus } from './types';
import UsedCouponStatusWidget from './UsedCouponStatusWidget';

interface ICouponSearchResultProps {
  children?: ReactNode | undefined;
}

export default function CouponSearchResult({ children }: ICouponSearchResultProps) {
  const loading = useSelector(isLoading);
  const submitted = useSelector(isCouponSubmitted);
  const couponStatus = useSelector(getCouponStatus);
  if (couponStatus) {
    if (couponStatus.status === ECouponStatus.Free) {
      return <FreeCouponStatusWidget coupon={couponStatus} />;
    }
    if (couponStatus.status === ECouponStatus.Reserved) {
      return <ReservedCouponStatusWidget coupon={couponStatus} />;
    }
    if (couponStatus.status === ECouponStatus.UsedByUser) {
      return (
        <>
          <UsedCouponStatusWidget coupon={couponStatus} />
          {children}
        </>
      );
    }
  }
  if (loading || !submitted || !!couponStatus) {
    return <>{children}</>;
  }
  return <NonIdealState icon="search" title="No search results" description="Coupon does not exists" />;
}
