import { getHttpService } from 'web_core_library';

export function createStringsParsingService() {
  const localesUrl = process.env.REACT_APP_LOCALES_URL;
  if (!localesUrl) {
    throw new Error('Locales url not set!');
  }
  const localesPath = `${localesUrl}/common/de`;
  const http = getHttpService(localesPath);
  const request = (namespace: string) => {
    const url = `/${namespace}.json`;
    return http.get(url);
  };
  function getProducts() {
    return request('products');
  }
  const service = {
    getProducts,
  };
  return service;
}

const StringsService = createStringsParsingService();
export default StringsService;
