import { IApiService } from 'web_core_library';

export function createAuthService() {
  let ApiService: IApiService;
  const validate = (token: string) => {
    if (!token) {
      throw new Error('Can not validate empty token!');
    }
    return ApiService.action<{ result: { sessionToken: string } }>('validate', {
      method: 'post',
      token,
    });
  };

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    validate,
  };
  return service;
}

const AuthService = createAuthService();
export default AuthService;
