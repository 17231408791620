import { IApiService } from 'web_core_library';
import {
  IAppReport,
  IAppReportAppliedFilter,
  IAppReportFilters,
  IExerciseReport,
  IExerciseReportAppliedFilter,
  IExerciseReportFilters,
  IUserFeedbackFilters,
  IUserFeedbackReport,
  IUserFeedbackReportAppliedFilter,
  TReportPayload,
} from './types';
import { parseFilterParams } from './utils';

export function createFeedbackService() {
  let ApiService: IApiService;

  // calls without payload to get possible filters
  function getFeedbackReportFilters() {
    return ApiService.action<IUserFeedbackFilters>('feedback_reports', {
      method: 'GET',
    });
  }
  function getAppReportFilters() {
    return ApiService.action<IAppReportFilters>('app_reports', {
      method: 'GET',
    });
  }
  function getExerciseReportFilters() {
    return ApiService.action<IExerciseReportFilters>('exercise_reports', {
      method: 'GET',
    });
  }

  // calls with payload to load actual reports
  function loadFeedbackReports(filters: IUserFeedbackReportAppliedFilter) {
    const params = parseFilterParams(filters);
    return ApiService.action<TReportPayload<IUserFeedbackReport>>('feedback_reports', {
      method: 'GET',
      params,
    });
  }

  function loadAppReports(filters: IAppReportAppliedFilter) {
    const params = parseFilterParams(filters);
    return ApiService.action<TReportPayload<IAppReport>>('app_reports', {
      method: 'GET',
      params,
    });
  }

  function loadExerciseReports(filters: IExerciseReportAppliedFilter) {
    const params = parseFilterParams(filters);
    return ApiService.action<TReportPayload<IExerciseReport>>('exercise_reports', {
      method: 'GET',
      params,
    });
  }

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getFeedbackReportFilters,
    getAppReportFilters,
    getExerciseReportFilters,
    loadFeedbackReports,
    loadAppReports,
    loadExerciseReports,
  };
  return service;
}

const FeedbackService = createFeedbackService();
export default FeedbackService;
