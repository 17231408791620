import { Classes, Spinner, SpinnerSize } from '@blueprintjs/core';
import { createBrowserHistory } from 'history';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { SessionExpirationWarning } from './auth';
import Header from './components/Header';
import './Root.scss';
import { AppState } from './state/reducers';
import { getTheme } from './state/selectors';
import { Themes } from './theme';

export interface Props {
  theme: string;
}

const history = createBrowserHistory();
const AppPage = lazy(() => import('./App'));
const UserHistoryPage = lazy(() => import('./history'));
const ProfilePage = lazy(() => import('./profile'));
const FeedbackConsolePage = lazy(() => import('./feedbackConsole'));

export function Root(props: Props) {
  let rootClass = 'users-admin';
  if (props.theme === Themes.Dark) {
    rootClass += ' ' + Classes.DARK;
  }

  return (
    <div className={rootClass}>
      <div className="app-root">
        <Router history={history}>
          <Header />
          <Suspense fallback={<Spinner size={SpinnerSize.LARGE} />}>
            <Switch>
              <Route path="/history/:userId(\d+)">
                <UserHistoryPage />
              </Route>
              <Route path="/profile/:userId(\d+)">
                <ProfilePage />
              </Route>
              <Route path="/feedback/:reportType(feedback|appreports|exercisereports)?">
                <FeedbackConsolePage />
              </Route>
              <Route path="/user/:userId(\d+)">
                <AppPage />
              </Route>
              <Route exact={true} path="/">
                <AppPage />
              </Route>
            </Switch>
          </Suspense>
        </Router>
        <SessionExpirationWarning />
      </div>
    </div>
  );
}

export const mapStateToProps = (state: AppState) => {
  const theme = getTheme(state);
  return {
    theme,
  };
};

export default connect(mapStateToProps)(Root);
