import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersExercises } from '../../selectors';

interface IExercisesFilterProps {
  selectedExercises: string[];
  onChange: (exercises: string[]) => void;
}

export default function ExercisesFilter({ onChange, selectedExercises }: IExercisesFilterProps) {
  const availableExercises = useSelector(getFiltersExercises);
  return (
    <StringListMultiSelector
      items={availableExercises}
      label="Exercises"
      onChange={onChange}
      selectedItems={selectedExercises}
    />
  );
}
