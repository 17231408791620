import { ControlGroup, FormGroup } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import StringSelector from '../../../components/selectors/StringSelector';
import { getFiltersAppVersion } from '../../selectors';

interface IAppVersionFilterProps {
  appRange: [string | undefined, string | undefined];
  onChange: (range: [string | undefined, string | undefined]) => void;
}

export default function AppVersionFilter({ appRange, onChange }: IAppVersionFilterProps) {
  const appVersions = useSelector(getFiltersAppVersion);
  const [appVersionFrom, appVersionTo] = appRange;
  const setFrom = (value: string | undefined) => onChange([value, appVersionTo]);
  const setTo = (value: string | undefined) => onChange([appVersionFrom, value]);
  return (
    <FormGroup label="App version" className="">
      <ControlGroup>
        <StringSelector items={appVersions} label="from" onChange={setFrom} selectedItem={appVersionFrom} />
        <StringSelector items={appVersions} label="to" onChange={setTo} selectedItem={appVersionTo} />
      </ControlGroup>
    </FormGroup>
  );
}
