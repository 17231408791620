import { ApiResponse } from '../services/apiResponse';
import * as ActionTypes from './actionTypes';

// init feature with current userId
export const initMessagesFeatureAction: (userId: number) => ActionTypes.IInitMessagesAction = (userId) => ({
  type: ActionTypes.MESSAGES_INIT,
  userId,
});

// open a conversation with a user
export const openUserConversationAction: (userId?: number) => ActionTypes.IOpenUserConversationAction = (userId) => ({
  type: ActionTypes.MESSAGES_OPEN_CONVERSATION,
  userId,
});

// change text of the message which should be sent to user
export const changeAdminMessageAction: (value: string) => ActionTypes.IChangeAdminMessageAction = (value) => ({
  type: ActionTypes.MESSAGES_ADMIN_MESSAGE_CHANGE,
  value,
});

// send a message to a user
export const sendAdminMessageAction: () => ActionTypes.ISendAdminMessageAction = () => ({
  type: ActionTypes.MESSAGES_ADMIN_MESSAGE_SEND,
});

export const sendAdminMessageSuccessAction: (response: ApiResponse) => ActionTypes.ISendAdminMessageSuccessAction = (
  response
) => ({
  type: ActionTypes.MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS,
  response,
});

export const sendAdminMessageFailedAction: (response: ApiResponse) => ActionTypes.ISendAdminMessageFailAction = (
  response
) => ({
  type: ActionTypes.MESSAGES_ADMIN_MESSAGE_SEND_FAIL,
  response,
});
