const hostname = window.location.hostname;
// const domain = isProduction ? '.neuronation.com' : '.dev.cms';
const domain =
  '.' +
  hostname
    .split('.')
    .slice(1)
    .join('.');

function stripSpacesFromLeft(str: string) {
  let result = str;
  while (result.charAt(0) === ' ') {
    result = result.substring(1);
  }
  return result;
}

function getCookie(cName: string) {
  const name = cName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (const cookie of cookies) {
    let cookieValue = stripSpacesFromLeft(cookie);
    if (cookieValue.indexOf(name) === 0) {
      return cookieValue.substring(name.length, cookieValue.length);
    }
  }
  return '';
}

function setCookie(cName: string, cValue: string, expireDays: number = 365) {
  const today = new Date(Date.now());
  today.setTime(today.getTime() + expireDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + today.toUTCString();
  const cDomain = `; domain=${domain}`;
  const newCookie = cName + '=' + encodeURIComponent(cValue) + '; ' + expires + '; path=/' + cDomain;
  document.cookie = newCookie;
  return newCookie;
}

function deleteCookie(cName: string) {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  const cDomain = `; domain=${domain}`;
  const newCookie = cName + '=; ' + expires + '; path=/' + cDomain;
  document.cookie = newCookie;
}

const COOKIE_AUTH_SESSION = 'nn_auth_session';
const COOKIE_AUTH_USER = 'nn_auth_user';
const COOKIE_AUTH_LOGOUT = 'nn_auth_logout';
const COOKIE_AUTH_TOKEN = 'nn_auth_token';

const CookieManager = {
  COOKIE_AUTH_LOGOUT,
  COOKIE_AUTH_SESSION,
  COOKIE_AUTH_USER,
  COOKIE_AUTH_TOKEN,
  deleteCookie,
  getCookie,
  setCookie,
};

export default CookieManager;
