import * as ActionTypes from './actionTypes';
import { TAppliedFilter, TFeedbackTabs, TFilters, TReportData } from './types';

export const initFeedbackAction: () => ActionTypes.IInitFeedbackAction = () => ({
  type: ActionTypes.FEEDBACK_INIT,
});

export const feedbackReadyAction: () => ActionTypes.IFeedbackReadyAction = () => ({
  type: ActionTypes.FEEDBACK_READY,
});

export const openFeedbackTabAction: (tab: TFeedbackTabs, reload?: boolean) => ActionTypes.IOpenFeedbackTabAction = (
  tab,
  reload
) => ({
  type: ActionTypes.FEEDBACK_OPEN_TAB,
  tab,
  reload,
});

export const updateUserFeedbackFiltersAction: (filters: TFilters) => ActionTypes.IUpdateUserFeedbackFiltersAction = (
  filters
) => ({
  type: ActionTypes.FEEDBACK_UPDATE_FILTERS,
  filters,
});

export const loadReportsAction: (tab: TFeedbackTabs, filters: TAppliedFilter) => ActionTypes.ILoadReportsAction = (
  tab,
  filters
) => ({
  type: ActionTypes.FEEDBACK_LOAD_REPORTS,
  tab,
  filters,
});

export const updateReportsAction: (reports: TReportData[]) => ActionTypes.IUpdateReportsAction = (reports) => ({
  type: ActionTypes.FEEDBACK_UPDATE_REPORTS,
  reports,
});

export const loadingFinishedAction: () => ActionTypes.ILoadingFinishedAction = () => ({
  type: ActionTypes.FEEDBACK_LOADING_FINISHED,
});
