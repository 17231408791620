import { FormGroup } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import getRenderer from './ItemRenderer';

interface IStringListSelectorProps {
  items: string[];
  label: string;
  selectedItems: string[];
  onChange: (items: string[]) => void;
}

export default function StringListMultiSelector({ items, label, onChange, selectedItems }: IStringListSelectorProps) {
  const tagRenderer = (item: string) => item;
  const removeSelection = (language: string) => onChange([...selectedItems].filter((lang) => lang !== language));
  const selectItem = (selectedItem: string) => {
    if (selectedItems.includes(selectedItem)) {
      removeSelection(selectedItem);
    } else {
      onChange([...selectedItems, selectedItem]);
    }
  };
  return (
    <FormGroup label={label}>
      <MultiSelect
        itemRenderer={getRenderer()}
        items={items}
        onItemSelect={selectItem}
        onRemove={removeSelection}
        selectedItems={selectedItems}
        tagRenderer={tagRenderer}
      />
    </FormGroup>
  );
}
