import { IApiService } from 'web_core_library';

export function createAdminService() {
  let ApiService: IApiService;

  function getSwitchToken(userId: number) {
    return ApiService.action('admin_switch', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  }

  function getNewsletterStatus(email: string) {
    return ApiService.action('admin_newsletter', {
      method: 'get',
      urlParams: {
        email,
      },
    });
  }

  function unsubscribeEmail(email: string) {
    return ApiService.action('admin_newsletter', {
      method: 'delete',
      urlParams: {
        email,
      },
    });
  }

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
      const apiUrl = process.env.REACT_APP_API_URL;
      const privateConfig = new Map([
        ['admin_switch', `${apiUrl}/v1/admin/switch/{userId}`],
        ['admin_newsletter', `${apiUrl}/v1/admin/newsletter/{email}`],
      ]);
      ApiService.setPrivateConfig(privateConfig);
    },
    getSwitchToken,
    getNewsletterStatus,
    unsubscribeEmail,
  };
  return service;
}

const AdminService = createAdminService();
export default AdminService;
