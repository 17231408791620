import { IUserBaseData } from '../state/types/state';
import * as ActionTypes from './actionTypes';

// update username field on search form
export const updateUsernameFieldAction: (value: string) => ActionTypes.IUpdateFormUsernameAction = (value) => ({
  type: ActionTypes.SEARCH_FORM_USERNAME_UPDATE,
  value,
});

// update userid field on search form
export const updateUseridFieldAction: (value: number) => ActionTypes.IUpdateFormUseridAction = (value) => ({
  type: ActionTypes.SEARCH_FORM_USERID_UPDATE,
  value,
});

// update first/last name field on search form
export const updateNameFieldAction: (value: string) => ActionTypes.IUpdateFormNameAction = (value) => ({
  type: ActionTypes.SEARCH_FORM_NAME_UPDATE,
  value,
});

// update order number field on search form
export const updateOrderFieldAction: (value: string) => ActionTypes.IUpdateFormOrderAction = (value) => ({
  type: ActionTypes.SEARCH_FORM_ORDER_UPDATE,
  value,
});

// start searching users by Username
export const searchUsersByUsernameAction: (search: string) => ActionTypes.ISearchUsersByUsernameAction = (search) => ({
  type: ActionTypes.USERS_SEARCH_USERNAME,
  search,
});

// start searching users by UserId
export const searchUserByIdAction: (id: number) => ActionTypes.ISearchUsersByIdAction = (id) => ({
  type: ActionTypes.USERS_SEARCH_ID,
  id,
});

// start searching users by first/last name
export const searchUsersByNameAction: (search: string) => ActionTypes.ISearchUsersByNameAction = (search) => ({
  type: ActionTypes.USERS_SEARCH_NAME,
  search,
});

// start searching users by first/last name
export const searchUsersByOrderAction: (search: string) => ActionTypes.ISearchUsersByOrderAction = (search) => ({
  type: ActionTypes.USERS_SEARCH_ORDER,
  search,
});

// search results
export const searchUsersResults: (users: IUserBaseData[]) => ActionTypes.ISearchUsersResultAction = (
  users: IUserBaseData[]
) => ({
  type: ActionTypes.USERS_SEARCH_RESULTS,
  users,
});

// search by id failed
export const searchUserByIdNotFoundAction: (id: number) => ActionTypes.ISearchUserNotFoundAction = (id) => ({
  type: ActionTypes.USERS_SEARCH_ID_NOTFOUND,
  id,
});

// some error happened during the search process
export const searchFailure: (error: any) => ActionTypes.ISearchUsersFailedAction = (error) => ({
  type: ActionTypes.USERS_SEARCH_FAILED,
  error,
});

// select user in search result list UI action
export const selectUserListItem: (id: number) => ActionTypes.ISearchUsersResultSelectAction = (id) => ({
  type: ActionTypes.USERS_SELECT_USER_LIST_ITEM,
  id,
});

// internal select user action
export const selectUserAction: (id: number) => ActionTypes.ISearchSelectUserAction = (id) => ({
  type: ActionTypes.USERS_SELECT_USER,
  id,
});

// internal deselect user action
export const deselectUserAction: () => ActionTypes.ISearchDeselectUserAction = () => ({
  type: ActionTypes.USERS_DESELECT_USER,
});

// user must be removed
export const searchRemoveUserAction: (id: number) => ActionTypes.ISearchRemoveUserAction = (id) => ({
  type: ActionTypes.USERS_REMOVE_USER,
  id,
});
