import { IApiService } from 'web_core_library';
import { IAddress, OrderStatus } from '../state/types/base';

export function createOrdersService() {
  let ApiService: IApiService;
  let _ready = false;

  // api to complete the order (change status from PENDING to SUCCESS)
  function completeOrder(userId: number, orderId: string) {
    return ApiService.action('user_order', {
      method: 'patch',
      urlParams: {
        userId,
        orderId,
      },
      data: {
        status: OrderStatus.Success,
      },
    });
  }

  function getDisputeInfo(orderId: string) {
    return ApiService.action('dispute', {
      method: 'get',
      urlParams: {
        orderId,
      },
    });
  }

  function getUserAddresses(userId: number) {
    return ApiService.action('get_user_addresses', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  }

  function saveUserAddress(userId: number, address: IAddress) {
    return ApiService.action('get_user_addresses', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: address,
    });
  }

  function isReady() {
    return _ready;
  }

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
      _ready = true;
    },
    isReady,
    completeOrder,
    getDisputeInfo,
    getUserAddresses,
    saveUserAddress,
  };
  return service;
}

const OrdersService = createOrdersService();
export default OrdersService;
