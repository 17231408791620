import { Popover } from '@blueprintjs/core';
import { PropsWithChildren, useState } from 'react';
import { copyToClipboard } from '../../services/clipboardService';

interface IClipBoardCopyWrapperProps extends PropsWithChildren {
  content?: string;
  copyEnabled?: boolean;
}

export default function ClipBoardCopyWrapper({ content, copyEnabled, children }: IClipBoardCopyWrapperProps) {
  const [copyResult, setCopyResult] = useState('');
  const copyContentHandler = () => {
    if (!content) {
      return;
    }
    const result = copyToClipboard(content);
    setCopyResult(result ? 'Content copied to clipboard' : `Copy to clipboard failed. Here is the content: ${content}`);
  };
  if (!copyEnabled || !content) {
    return <>{children}</>;
  }
  return (
    <Popover
      hasBackdrop={true}
      portalClassName="long-text-popover"
      usePortal={true}
      position="bottom-left"
      interactionKind="click"
      onOpening={copyContentHandler}
      content={<p>{copyResult}</p>}>
      {children}
    </Popover>
  );
}
