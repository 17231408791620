import { ActionCreator } from 'redux';

import * as ActionTypes from './actionTypes';

// get user newsletter subscription info
export const getUserNewsletterInfoAction: ActionCreator<ActionTypes.IGetUserNewsletterAction> = (userId: number) => ({
  type: ActionTypes.ACTIONS_GET_USER_NEWSLETTER,
  userId,
});

// update user newsletter status after receiving info from server
export const updateUserNewsletterInfoAction: ActionCreator<ActionTypes.IUpdateUserNewsletterAction> = (
  userId: number,
  newsletter: boolean
) => ({
  type: ActionTypes.ACTIONS_USER_NEWSLETTER_UPDATE,
  userId,
  newsletter,
});

// toggle user newsletter subscription info
export const toggleUserNewsletterInfoAction: ActionCreator<ActionTypes.IToggleUserNewsletterAction> = (
  userId: number
) => ({
  type: ActionTypes.ACTIONS_TOGGLE_USER_NEWSLETTER,
  userId,
});

// get user token
export const getUserTokenAction: ActionCreator<ActionTypes.IGetUserTokenAction> = (userId: number) => ({
  type: ActionTypes.ACTIONS_GET_USER_TOKEN,
  userId,
});

// update users edisonUrl after receiving session token
export const updateUrlAction: ActionCreator<ActionTypes.IUpdateUrlAction> = (
  userId: number,
  opxpUrl: string,
  checkoutUrl: string
) => ({
  type: ActionTypes.ACTIONS_URL_UPDATE,
  userId,
  opxpUrl,
  checkoutUrl,
});

// update users session token after receiving it from server
export const updateUserSessionTokenAction: ActionCreator<ActionTypes.IUpdateUserSessionAction> = (
  userId: number,
  session: string
) => ({
  type: ActionTypes.ACTIONS_USER_SESSION_UPDATE,
  userId,
  session,
});

// update users active status after receiving subscription info
export const updateUserActiveAction: ActionCreator<ActionTypes.IUpdateUserActiveAction> = (
  userId: number,
  isActive: boolean
) => ({
  type: ActionTypes.ACTIONS_USER_ACTIVE_UPDATE,
  userId,
  isActive,
});

// delete user action
export const deleteUserAction: ActionCreator<ActionTypes.IDeleteUserAction> = (userId: number) => ({
  type: ActionTypes.ACTIONS_USER_DELETE,
  userId,
});

// update list of product ids
export const updateProductsAction: (products: number[]) => ActionTypes.IUpdateProductsAction = (products) => ({
  type: ActionTypes.ACTIONS_UPDATE_PRODUCTS,
  products,
});
