import { Button, Icon, Popover } from '@blueprintjs/core';
import { TruncatedFormat2, TruncatedPopoverMode } from '@blueprintjs/table';
import { ReactElement, useState } from 'react';
import { copyToClipboard } from '../../services/clipboardService';

interface ILongTextCellProps {
  content: string;
  hideContent?: boolean;
}

export default function LongTextCell({ content, hideContent }: ILongTextCellProps) {
  const [copyResult, setCopyResult] = useState('');
  const copyContentHandler = () => {
    const result = copyToClipboard(content);
    setCopyResult(result ? 'Content copied to clipboard' : `Copy to clipboard failed. Here is the content: ${content}`);
  };
  const cellContent = hideContent ? (
    <Icon icon="more" className="long-text-content" />
  ) : (
    <TruncatedFormat2 className="long-text-content" showPopover={TruncatedPopoverMode.NEVER} detectTruncation={true}>
      {content}
    </TruncatedFormat2>
  );
  let popoverContent: ReactElement = <p>{copyResult}</p>;
  if (!copyResult) {
    if (!hideContent) {
      popoverContent = (
        <>
          <p>
            {content}&nbsp;
            <Button icon="clipboard" onClick={copyContentHandler} />
          </p>
        </>
      );
    }
  }
  const openPopupHandler = () => {
    if (hideContent) {
      copyContentHandler();
      return;
    }
  };
  const closePopupHandler = () => {
    setCopyResult('');
  };
  return (
    <Popover
      hasBackdrop={true}
      portalClassName="long-text-popover"
      usePortal={true}
      position="bottom-left"
      interactionKind="click"
      onOpening={openPopupHandler}
      onClosing={closePopupHandler}
      content={popoverContent}>
      {cellContent}
    </Popover>
  );
}
