import { Icon, Intent, NonIdealState, Tree, TreeNodeInfo } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { printUnixTimestamp } from '../services/dateUtils';
import * as Selectors from './selectors';
import { ETrainingStatus, ICycleStatus } from './types';

export interface ICycleTreeProps {
  cycles: ICycleStatus[];
}

function getStatusIcon(status: ETrainingStatus) {
  switch (status) {
    case ETrainingStatus.None:
      return <Icon icon={IconNames.DOT} intent={Intent.NONE} className="training-status-icon" />;
    case ETrainingStatus.Ongoing:
      return (
        <Icon
          icon={IconNames.FULL_CIRCLE}
          intent={Intent.PRIMARY}
          title="Ongoing"
          htmlTitle="Ongoing"
          className="training-status-icon"
        />
      );
    case ETrainingStatus.Skipped:
      return (
        <Icon
          icon={IconNames.FULL_CIRCLE}
          intent={Intent.WARNING}
          title="Skipped"
          htmlTitle="Skipped"
          className="training-status-icon"
        />
      );
    case ETrainingStatus.Finished:
      return (
        <Icon
          icon={IconNames.FULL_CIRCLE}
          intent={Intent.SUCCESS}
          title="Finished"
          htmlTitle="Finished"
          className="training-status-icon"
        />
      );
  }
}

export const CycleTreeFC: FC<ICycleTreeProps> = ({ cycles }) => {
  if (!cycles.length) {
    return <NonIdealState icon={IconNames.WARNING_SIGN} title="There were no training cycles found for this user" />;
  }
  const tree: TreeNodeInfo[] = cycles.map((cycle) => {
    const sessions: TreeNodeInfo[] = cycle.sessions.map((session) => {
      const workouts: TreeNodeInfo[] = session.workouts.map((workout) => {
        const workoutStatusIcon = getStatusIcon(workout.status);
        return {
          id: `workout-${cycle.cycleId}-${session.sessionId}-${workout.index}-${workout.workoutId}`,
          icon: workoutStatusIcon,
          label: (
            <span>
              {workout.index}: Workout {workout.workoutId}
            </span>
          ),
          secondaryLabel: printUnixTimestamp(workout.finishedTimestamp),
        };
      });
      return {
        id: `session-${cycle.cycleId}-${session.sessionId}`,
        icon: getStatusIcon(session.status),
        label: <span>Session {session.sessionId}</span>,
        secondaryLabel: printUnixTimestamp(session.finishedTimestamp),
        childNodes: workouts,
        isExpanded: true,
      };
    });
    return {
      id: `cycle-${cycle.cycleId}`,
      label: <span>Cycle {cycle.cycleId}</span>,
      secondaryLabel: printUnixTimestamp(cycle.finishedTimestamp),
      nodeData: cycle,
      childNodes: sessions,
      isExpanded: true,
    };
  });
  return (
    <div className="cycle-tree">
      <Tree contents={tree} />
    </div>
  );
};

export const mapStateToProps = (state: Selectors.IAppState) => {
  const cycles = Selectors.getCycles(state);
  return {
    cycles,
  };
};

const CycleTree = connect(mapStateToProps)(CycleTreeFC);
export default CycleTree;
