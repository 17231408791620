import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { NotificationActions } from '../../notifications';
import { getUserOrdersAction } from '../../orders/actions';
import * as OrdersActionTypes from '../../orders/actionTypes';
import { selectUserAction } from '../../search/actions';
import * as UserActionsActions from '../../userActions/actions';
import * as DetailsActions from '../actions/details';
import {
  getUserByIdRequestAction,
  getUserResult,
  getUserSubscriptionsRequestAction,
  USERS_GET_USER_BY_ID_FAIL,
  USERS_GET_USER_BY_ID_SUCCESS,
  USERS_GET_USER_SUBSCRIPTIONS_FAIL,
  USERS_GET_USER_SUBSCRIPTIONS_SUCCESS,
} from '../actions/users';
import Status from '../models/status';
import Subscription from '../models/subscription';
import { getUser, getUserDetails } from '../selectors';
import { ApiResponseAction, IAction, IBaseAction, UserDependentAction } from '../types/actions';
import { NNProjects, ProjectStatus } from '../types/base';
import { IExtendedUser, ISubscriptionData, UserDetails, UserStatusesMap } from '../types/state';

export function* handleSubscriptionLoad(action: UserDependentAction) {
  const userId = action.payload.id;
  yield put(getUserSubscriptionsRequestAction(userId));
  const result: ApiResponseAction = yield take([
    USERS_GET_USER_SUBSCRIPTIONS_FAIL,
    USERS_GET_USER_SUBSCRIPTIONS_SUCCESS,
  ]);
  const { response } = result.payload;
  if (response.isError()) {
    yield put(DetailsActions.detailsFailure(userId, response));
  } else {
    const subscriptionsData = response.data.subscriptions as ISubscriptionData[];
    yield put(DetailsActions.userSubscriptionUpdateAction(userId, subscriptionsData));
  }
}

export function* handleDetailsLoad(action: UserDependentAction) {
  const userId = action.payload.id;
  const details: UserDetails | undefined = yield select(getUserDetails, userId);
  if (!details || !details.loaded) {
    yield put(DetailsActions.getUserSubscriptionAction(userId));
    const detailsResult: IBaseAction = yield take([
      DetailsActions.USERS_DETAILS_FAILED,
      DetailsActions.USERS_USER_SUBSCRIPTION_UPDATE,
    ]);

    if (detailsResult.type === DetailsActions.USERS_DETAILS_FAILED) {
      yield put(NotificationActions.showError('Subscriptions loading failed!'));
      return;
    }
    const user: IExtendedUser = yield select(getUser, userId);
    // if user has running subscription
    let isActiveUser = false;
    const subscriptions = (detailsResult as IAction<DetailsActions.UserSubscriptionUpdateActionPayload>).payload
      .subscriptions;
    // subscription for Ease App
    const easeSubscription = Subscription.getEaseSubscription(subscriptions);
    // Ease App status
    const easeStatus = Status.getProductStatus(NNProjects.EaseApp, user, easeSubscription);
    isActiveUser = isActiveUser || (easeStatus.status === ProjectStatus.Active && easeStatus.isSubscription);
    // subscription for BT
    const btSubscription = Subscription.getBTSubscription(subscriptions);
    // BT Web&App status
    const btWebStatus = Status.getProductStatus(NNProjects.BTAppWeb, user, btSubscription);
    isActiveUser = isActiveUser || (btWebStatus.status === ProjectStatus.Active && btWebStatus.isSubscription);
    // BT App status
    const btAppStatus = Status.getProductStatus(NNProjects.BTApp, user, btSubscription);
    isActiveUser = isActiveUser || (btAppStatus.status === ProjectStatus.Active && btAppStatus.isSubscription);

    const statuses: UserStatusesMap = {
      [NNProjects.EaseApp]: easeStatus,
      [NNProjects.BTAppWeb]: btWebStatus,
      [NNProjects.BTApp]: btAppStatus,
    };

    yield put(DetailsActions.statusesUpdate(userId, statuses));

    // get data for user actions widget
    yield put(UserActionsActions.getUserNewsletterInfoAction(userId));
    yield put(UserActionsActions.getUserTokenAction(userId));
    yield put(UserActionsActions.updateUserActiveAction(userId, isActiveUser));

    yield put(getUserOrdersAction(userId));
    const ordersResult: OrdersActionTypes.IGetUserOrdersResultAction = yield take([
      OrdersActionTypes.ORDERS_GET_USER_ORDERS_FAILED,
      OrdersActionTypes.ORDERS_GET_USER_ORDERS_SUCCESS,
    ]);
    if (ordersResult.type !== OrdersActionTypes.ORDERS_GET_USER_ORDERS_SUCCESS) {
      yield put(DetailsActions.detailsFailure(userId));
      yield put(NotificationActions.showError('Orders loading failed!'));
      return;
    }
  }

  yield put(DetailsActions.detailsLoaded(userId));
}

export function* handleUserReload(action: UserDependentAction) {
  const userId = action.payload.id;
  yield put(getUserByIdRequestAction(userId));
  const result: ApiResponseAction = yield take([USERS_GET_USER_BY_ID_FAIL, USERS_GET_USER_BY_ID_SUCCESS]);
  if (result.type === USERS_GET_USER_BY_ID_SUCCESS) {
    yield put(getUserResult(result.payload.response.data.user));
    yield put(selectUserAction(userId));
    yield put(DetailsActions.detailsLoadAction(userId));
  }
}

export default function* detailsWatcher() {
  yield all([
    takeEvery(DetailsActions.USERS_DETAILS_LOAD, handleDetailsLoad),
    takeEvery(DetailsActions.USERS_GET_USER_SUBSCRIPTION, handleSubscriptionLoad),
    takeEvery(DetailsActions.USERS_USER_RELOAD, handleUserReload),
  ]);
}
