import { CombinedState } from 'redux';
import { IEmailHistoryState, statePropName } from './reducer';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [statePropName]: IEmailHistoryState;
}

export const getEmailsState = (state: IAppState) => state.emails;

export const isEmailsHistoryLoading = (state: IAppState) => getEmailsState(state).loading;

export const getEmailsHistory = (state: IAppState) => getEmailsState(state).emails;

export const isEmailHistoryFeatureStarted = (state: IAppState) => getEmailsHistory(state) !== null;

export const getEmailsHistoryList = (state: IAppState) => getEmailsHistory(state) || [];

export const isEmailsHistoryEmpty = (state: IAppState) => {
  const emails = getEmailsHistory(state);
  if (!emails) {
    return true;
  }
  return emails.length === 0;
};
