import { useSelector } from 'react-redux';
import ReportsSection from '../components/sections/ReportsSection';
import { getAppReports } from '../selectors';
import AppReportsTable from './AppReportsTable';

export default function AppReportsReports() {
  const reports = useSelector(getAppReports);
  return (
    <ReportsSection length={reports?.length}>
      <AppReportsTable reports={reports} />
    </ReportsSection>
  );
}
