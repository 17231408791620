import { Moment } from 'moment';

export type Callback = () => void;

export type DataCallback<T = any> = (data: T) => void;

export type UnparsedTime = number | string;
export type ParsedTime = Moment;
export type Time = UnparsedTime | ParsedTime;

export enum SubscriptionProjects {
  Neuronation = 'NN',
  Ease = 'EASE',
  NNAndEaseBundle = 'NN_AND_EASE',
}

export enum NNProjects {
  BTAppWeb = 'BT - Web & App',
  BTApp = 'BT - App',
  EaseApp = 'Ease - App',
}

export type NNProjectsKeys = 'BTAppWeb' | 'BTApp' | 'EaseApp';

export const projectOrder: NNProjectsKeys[] = ['BTAppWeb', 'BTApp', 'EaseApp'];

export enum ProjectStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Undefined = 'undefined',
}

export const currencyMap: { [short: string]: string } = {
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'Fr',
  EUR: '€',
  GBP: '£',
  NZD: 'NZ$',
  RUB: '₽',
  USD: '$',
};

export enum OrderStatus {
  Started = 'STARTED',
  Pending = 'PENDING',
  Cancelled = 'CANCELED',
  Failed = 'FAILED',
  Success = 'SUCCESS',
  Refunded = 'REFUNDED',
  TransferStarted = 'TRANSFER_STARTED',
  Transferred = 'TRANSFERRED',
  ActivatedPending = 'ACTIVATED_PENDING',
  Disputed = 'DISPUTED',
}

export enum ProjectTypeEnum {
  NN = 1,
  EASE = 2,
  NN_AND_EASE = 3,
}

export enum PSPTypeEnum {
  GOOGLE_PLAY = 1,
  IOS = 2,
  PAYPAL = 3,
  NOVALNET = 4,
  STRIPE = 5,
  BANK_TRANSFER = 6,
  STRIPE_INTENTS = 7,
  CHARGEBEE = 8,
  NOVALNET_LEGACY = 90,
  PAYPAL_LEGACY = 91,
  AMAZON_LEGACY = 92,
  GOOGLE_PLAY_LEGACY = 93,
  IOS_LEGACY = 94,
  BANK_TRANSFER_LEGACY = 95,
  UNKNOWN = 99,
}

export const PSPMap = {
  [PSPTypeEnum.GOOGLE_PLAY]: 'Google Play',
  [PSPTypeEnum.IOS]: 'iOS',
  [PSPTypeEnum.NOVALNET]: 'Novalnet',
  [PSPTypeEnum.PAYPAL]: 'PayPal',
  [PSPTypeEnum.STRIPE]: 'Stripe',
  [PSPTypeEnum.BANK_TRANSFER]: 'Bank transfer',
  [PSPTypeEnum.STRIPE_INTENTS]: 'Stripe Intents',
  [PSPTypeEnum.CHARGEBEE]: 'Chargebee',
  [PSPTypeEnum.NOVALNET_LEGACY]: 'Novalnet',
  [PSPTypeEnum.PAYPAL_LEGACY]: 'PayPal (Legacy)',
  [PSPTypeEnum.AMAZON_LEGACY]: 'Amazon (Legacy)',
  [PSPTypeEnum.GOOGLE_PLAY_LEGACY]: 'Google Play (Legacy)',
  [PSPTypeEnum.IOS_LEGACY]: 'iOS (Legacy)',
  [PSPTypeEnum.BANK_TRANSFER_LEGACY]: 'Bank transfer (Legacy)',
  [PSPTypeEnum.UNKNOWN]: 'Unknown (inform devs!)',
};

export interface OrderProductInfo {
  id: string | number;
  project: ProjectTypeEnum;
}

export type TInvoiceChannel = 'web' | 'app_store' | 'play_store';

export interface IOrderData {
  channel?: TInvoiceChannel;
  date: UnparsedTime;
  invoiceUrl: string;
  ipAddress: string;
  legacy: boolean;
  products: OrderProductInfo[];
  psp: {
    link?: string;
    methodId: PSPTypeEnum;
    pspTransactionId: string;
  };
  referenceId: string;
  renewal: boolean;
  status: OrderStatus;
  subscriptionId: string | null;
  total: {
    amount: number;
    currency: string;
  };
}

export interface UserStat {
  statType: number;
  statId: number;
  statComponentId: number;
  valueInt?: number;
  valueTxt?: string;
  operator: string;
  time?: number;
}

export interface IProductNameData {
  Legacy: {
    [id: number]: string;
  };
  Current: {
    [id: number]: string;
  };
}

export interface NewsletterStatus {
  [provider: string]: boolean;
}

export interface RoleChange {
  id: number;
  period?: string;
}

export interface IAddress {
  firstName: string;
  lastName: string;
  street1: string;
  street2?: string;
  city: string;
  zip: string;
  country?: string;
  countryIsoCode2?: string;
  createdAt?: string;
}
