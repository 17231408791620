import { Button, InputGroup, NumericInput } from '@blueprintjs/core';
import moment from 'moment';
import React, { useState } from 'react';

import { IUserStatGet, IUserStatPost } from './types';

export interface IStatsRowProps {
  selected: boolean;
  stat: IUserStatGet;
  onSave: (stat: IUserStatPost) => void;
  onSelect: () => void;
}

const StatsRow: React.FC<IStatsRowProps> = ({ onSave, onSelect, selected, stat }) => {
  const { statType, statId, statComponentId, valueInt, valueTxt, lastEdit } = stat;
  const [newIntVal, setIntVal] = useState<number>(valueInt);
  const [newTxtVal, setTxtVal] = useState<string>(valueTxt);
  const formatedDate = moment(lastEdit * 1000).format('DD.MM.YYYY HH:mm');
  const rowClass = selected ? 'selected' : '';
  const intValInput = <NumericInput buttonPosition="none" onValueChange={setIntVal} value={newIntVal} />;
  const txtChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => setTxtVal(e.target.value);
  const txtValInput = <InputGroup onChange={txtChangeHandler} value={newTxtVal} />;
  const isEdited = valueInt !== newIntVal || valueTxt !== newTxtVal;
  const updateHandler = () => {
    if (!isEdited) {
      return;
    }
    const newStat: IUserStatPost = {
      statType,
      statId,
      statComponentId,
      valueInt: newIntVal,
      valueTxt: newTxtVal,
      operator: 'UPD',
      time: -1,
    };
    onSave(newStat);
  };
  const editModeCell = isEdited ? (
    <Button onClick={updateHandler} intent="success" icon="tick" loading={isEdited && !selected} />
  ) : null;
  return (
    <tr className={rowClass} onClick={onSelect}>
      <td>{statType}</td>
      <td>{statId}</td>
      <td>{statComponentId}</td>
      <td>{editModeCell}</td>
      <td>{selected ? intValInput : valueInt}</td>
      <td>{selected ? txtValInput : valueTxt}</td>
      <td>{formatedDate}</td>
    </tr>
  );
};
export default StatsRow;
