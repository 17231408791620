import moment from 'moment';

export function getUnixTimestamp(timestamp: number = Date.now()) {
  return Math.ceil(timestamp / 1000);
}

export function printUnixTimestamp(timestamp?: number) {
  return timestamp ? moment.utc(timestamp * 1000).format('DD.MM.YYYY, HH:mm:ss') : '';
}

export function parseDate(date: string | null | undefined) {
  return moment(date).toDate();
}

export function formatDate(date: Date) {
  return moment(date).format('DD.MM.YYYY');
}

export function formatFullDate(date: Date) {
  return moment(date).format('DD.MM.YYYY, HH:mm:ss');
}
