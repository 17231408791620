import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { NotificationActions } from '../notifications';
import { searchRemoveUserAction } from '../search/actions';
import * as AdminActions from '../state/actions/admin';
import * as UsersActions from '../state/actions/users';
import { getUser } from '../state/selectors';
import { ApiResponseAction, IAction } from '../state/types/actions';
import { IExtendedUser } from '../state/types/state';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { getUserNewsletterStatus } from './selectors';

export function* getNewsletterStatusSaga(action: ActionTypes.IGetUserNewsletterAction) {
  const userId = action.userId;
  yield put(UsersActions.getUserNewsletterRequestAction(userId));
  const result: ApiResponseAction = yield take([
    UsersActions.USERS_GET_USER_NEWSLETTER_FAIL,
    UsersActions.USERS_GET_USER_NEWSLETTER_SUCCESS,
  ]);
  const { response } = result.payload;
  if (response.isSuccess()) {
    const { sg, mc, nn } = response.data;
    const newsletter = sg || mc || nn;
    yield put(Actions.updateUserNewsletterInfoAction(userId, newsletter));
  }
}

export function* toggleNewsletterSaga(action: ActionTypes.IToggleUserNewsletterAction) {
  const userId = action.userId;
  // get current newsletter status
  const newsletter = yield select(getUserNewsletterStatus, userId);
  if (newsletter) {
    yield put(UsersActions.removeUserNewsletterRequestAction(userId));
    yield take([UsersActions.USERS_REMOVE_USER_NEWSLETTER_FAIL, UsersActions.USERS_REMOVE_USER_NEWSLETTER_SUCCESS]);
  } else {
    yield put(UsersActions.addUserNewsletterRequestAction(userId));
    yield take([UsersActions.USERS_ADD_USER_NEWSLETTER_FAIL, UsersActions.USERS_ADD_USER_NEWSLETTER_SUCCESS]);
  }
  yield put(Actions.getUserNewsletterInfoAction(userId));
}

export function* updateUserSigninSaga(action: ActionTypes.IGetUserTokenAction) {
  const userId = action.userId;
  yield put(AdminActions.adminSigninAsUserRequestAction(userId));
  const resultAction = yield take(AdminActions.ADMIN_SIGNIN_AS_USER_RESPONSE);
  const { session } = (resultAction as IAction<AdminActions.UserTokenResponsePayload>).payload;
  const opxpBaseUrl = process.env.REACT_APP_OPXP_URL;
  const checkoutBaseUrl = process.env.REACT_APP_CHECKOUT_URL;
  const ssoBaseUrl = process.env.REACT_APP_AUTH_URL;
  if (session && opxpBaseUrl && checkoutBaseUrl && ssoBaseUrl) {
    // set user session token
    yield put(Actions.updateUserSessionTokenAction(userId, session));
    const user: IExtendedUser = yield select(getUser, userId);
    let userLanguage = user.language || 'de';
    if (userLanguage === 'en-us') {
      userLanguage = 'en';
    }
    // use template-like placeholder to create different pages links
    const opxpLocalizedUrl = `${opxpBaseUrl}%page%/?lang=${userLanguage}&session=${session}`;
    // compile template for checkout page urls
    const completeUrl = encodeURIComponent(`${opxpBaseUrl}/training/dashboard/?lang=${userLanguage}`);
    const checkoutLocalizedUrl = `${checkoutBaseUrl}/?productId=_productId_&session=${session}&lang=${userLanguage}&completeUrl=${completeUrl}&noCoupon=1&forwardSession=1`;
    yield put(Actions.updateUrlAction(userId, opxpLocalizedUrl, checkoutLocalizedUrl));
  }
  // parse products
  const productsString = process.env.REACT_APP_PRODUCTS || '';
  const productIds = productsString.split(',').map(Number);
  yield put(Actions.updateProductsAction(productIds));
}

export function* handleUserDeleteAction(action: ActionTypes.IDeleteUserAction) {
  const userId = action.userId;
  // call api to delete user
  yield put(UsersActions.deleteUserRequestAction(userId));
  const resultAction = yield take([UsersActions.USERS_DELETE_USER_FAIL, UsersActions.USERS_DELETE_USER_SUCCESS]);
  if (resultAction.type === UsersActions.USERS_DELETE_USER_FAIL) {
    yield put(NotificationActions.showError('Failed to delete user! Subscription still active?'));
    return;
  }
  // remove user from search when api call was successful
  yield put(searchRemoveUserAction(userId));
  yield put(NotificationActions.showSuccess('Delete user confirmation email has been sent!'));
}

export default function* userActionsWatcher() {
  yield all([
    takeEvery(ActionTypes.ACTIONS_GET_USER_NEWSLETTER, getNewsletterStatusSaga),
    takeEvery(ActionTypes.ACTIONS_TOGGLE_USER_NEWSLETTER, toggleNewsletterSaga),
    takeEvery(ActionTypes.ACTIONS_GET_USER_TOKEN, updateUserSigninSaga),
    takeEvery(ActionTypes.ACTIONS_USER_DELETE, handleUserDeleteAction),
  ]);
}
