import { Action } from 'redux';

export const APP_START = 'APP_START';
export type APP_START_TYPE = typeof APP_START;
export interface IStartAppAction extends Action<APP_START_TYPE> {
  type: APP_START_TYPE;
}
export const startAppAction: () => IStartAppAction = () => ({
  type: APP_START,
});

export const APP_STARTED = 'APP_STARTED';
export type APP_STARTED_TYPE = typeof APP_STARTED;
export interface IAppStartedAction extends Action<APP_STARTED_TYPE> {
  type: APP_STARTED_TYPE;
}
export const appStartedAction: () => IAppStartedAction = () => ({
  type: APP_STARTED,
});

export type TAppActions = IStartAppAction | IAppStartedAction;
