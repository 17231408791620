import CookieManager from '../services/cookieManager';

function parseJWT<TPayload>(jwt: string): TPayload {
  return JSON.parse(window.atob(jwt.split('.')[1]));
}

interface IUserSessionPayload {
  exp: number;
  uid: number;
  isUAD: boolean;
  roles: Array<number>;
}

// parses JWT session token and extracts user data
export function parseSession(session: string): IUserSessionPayload {
  return parseJWT<IUserSessionPayload>(session);
}

export function getMinutesLeft(expires: number) {
  const now = Date.now();
  return Math.floor((expires - now) / 1000 / 60);
}

interface IUserAccessPayload {
  exp: number;
  iat: number;
  uid: number;
}
// parses JWT session token and extracts user data
export function parseAccess(token: string): IUserAccessPayload {
  return parseJWT<IUserAccessPayload>(token);
}

export function getAccessTokenExpiration() {
  const token = CookieManager.getCookie(CookieManager.COOKIE_AUTH_TOKEN);
  if (!token) {
    return 0;
  }
  const tokenData = parseAccess(token);
  return tokenData.exp;
}
