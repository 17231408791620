import { AppState } from '../state/reducers';

export const getUserActionsState = (state: AppState, userId: number) => state.userActions[userId];

export const getUserNewsletterStatus = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return false;
  }
  return status.newsletter;
};

export const isUserNewsletterLoading = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return true;
  }
  return status.newsletterLoading;
};

export const getUserOpxpUrl = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return '';
  }
  return status.opxpUrl;
};

export const getUserCheckoutUrl = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return '';
  }
  return status.checkoutUrl;
};

export const getUserSessionToken = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return '';
  }
  return status.session;
};

export const isUserActive = (state: AppState, userId: number) => {
  const status = getUserActionsState(state, userId);
  if (!status) {
    return true;
  }
  return status.isActive;
};

export const getProducts = (state: AppState) => state.userActions.products;
