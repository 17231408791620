import * as ActionTypes from './actionTypes';
import { Themes } from './types';

export const initialState: Themes = Themes.Dark;

export const statePropName = 'theme';

/**
 * Reducer to switch theme
 */
export default function themeReducer(state = initialState, action: ActionTypes.TThemeActions) {
  switch (action.type) {
    case ActionTypes.THEME_UPDATE:
      return action.theme;
    default:
      return state;
  }
}
