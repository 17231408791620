import find from 'lodash/find';
import { AppState } from '../state/reducers';
import { IUserStatGet, IUserStatId } from './types';
import { parseUserStatIds } from './utils';

export const getUserStatsState = (state: AppState) => state.userStats;

export const getUserStats = (state: AppState, userId: number) => getUserStatsState(state)[userId];

export const getUserStatById = (state: AppState, userId: number, statId: string) => {
  const stats = getUserStats(state, userId);
  const searchStat: IUserStatId = parseUserStatIds(statId);
  return find(stats, searchStat) as IUserStatGet | undefined;
};

export const getIntUserStatById = (state: AppState, userId: number, statId: string) => {
  const stat = getUserStatById(state, userId, statId);
  return stat ? stat.valueInt : 0;
};

export const getUserTimezone = (state: AppState, userId: number) => {
  // TODO: for future refactoring:
  // this id should be moved to the consumer
  // consumers should define ids they need and use
  // generic getUserStat selector
  const statId = '4-6-1';
  const timezoneStat = getUserStatById(state, userId, statId);
  return timezoneStat ? timezoneStat.valueTxt : '';
};
