import { ApiService, IApiService } from 'web_core_library';
import { IEmailHistoryResponse } from './types';

export function createEmailHistoryService() {
  let apiService: IApiService;

  function getEmailHistory(userId: number) {
    if (!apiService) {
      apiService = ApiService;
    }
    return apiService.action<IEmailHistoryResponse>('emails_history', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  }

  const service = {
    getEmailHistory,
  };
  return service;
}

const EmailHistoryService = createEmailHistoryService();
export default EmailHistoryService;
