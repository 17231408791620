import { AppState } from '../state/reducers';

export const getSearchResultState = (state: AppState) => state.search;

export const getSearchForm = (state: AppState) => getSearchResultState(state).form;

export const isRealnameSearch = (state: AppState) => {
  const form = getSearchForm(state);
  if (!form) {
    return false;
  }
  return !!form.name;
};

export const getOrderSearched = (state: AppState) => {
  const form = getSearchForm(state);
  if (!form) {
    return null;
  }
  return form.order;
};

export const isSearchStarted = (state: AppState) => getSearchResultState(state).started;

export const isSearchInProgress = (state: AppState) => getSearchResultState(state).loading;

export const isSearchFinished = (state: AppState) => isSearchStarted(state) && !getSearchResultState(state).loading;

export const getUsersOrder = (state: AppState) => getSearchResultState(state).list;

export const getSelectedUserId = (state: AppState) => getSearchResultState(state).selectedUser;

export const getTotalUsersNumber = (state: AppState) => getSearchResultState(state).total;
