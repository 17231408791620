import { SmartTable } from '../../components/smartTable';
import { IAppReport } from '../types';

interface IAppReportsTableProps {
  reports?: IAppReport[];
}
export default function AppReportsTable({ reports }: IAppReportsTableProps) {
  if (!reports) {
    return null;
  }
  return (
    <SmartTable
      name="app-reports"
      columns={[
        {
          columnName: 'appVersion',
          columnType: 'string',
          columnTitle: 'App version',
          columnWidth: 100,
        },
        {
          columnName: 'lastGameplay',
          columnType: 'string',
          columnTitle: 'Last gameplay',
          columnWidth: 120,
        },
        {
          columnName: 'language',
          columnType: 'string',
          columnTitle: 'Language',
          columnWidth: 90,
        },
        {
          columnName: 'country',
          columnType: 'string',
          columnTitle: 'Country',
          columnWidth: 70,
        },
        {
          columnName: 'osName',
          columnType: 'string',
          columnTitle: 'OS name',
          columnWidth: 120,
        },
        {
          columnName: 'osVersion',
          columnType: 'text',
          columnTitle: 'OS version',
          columnWidth: 200,
          truncate: true,
        },
        {
          columnName: 'userId',
          columnType: 'number',
          columnTitle: 'User ID',
          columnWidth: 80,
          copy: true,
        },
        {
          columnName: 'message',
          columnType: 'long-text',
          columnTitle: 'Message',
          columnWidth: 200,
          hideContent: false,
        },
        {
          columnName: 'createdAt',
          columnType: 'date',
          columnTitle: 'Created at',
          columnWidth: 140,
        },
        {
          columnName: 'isPremium',
          columnType: 'boolean',
          columnTitle: 'Premium?',
          columnWidth: 90,
        },
        {
          columnName: 'pushNotificationToken',
          columnType: 'long-text',
          columnTitle: 'PUSH',
          columnWidth: 80,
          hideContent: true,
        },
      ]}
      data={reports}
      className="reports-table"
    />
  );
}
