import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { UserAccessWarning } from '../auth';
import * as HistoryActions from './actions';
import * as HistoryActionTypes from './actionTypes';
import historyWatcher from './saga';
import UserHistoryContainer from './UserHistoryContainer';

export interface IPageRouteParams {
  // id of selected user
  userId: string;
}

export default function UserHistoryPage() {
  const { params } = useRouteMatch<IPageRouteParams>();
  const userId = parseInt(params.userId, 10);
  return (
    <div className="container">
      <UserAccessWarning />
      <UserHistoryContainer userId={userId} />
    </div>
  );
}

export { HistoryActions, HistoryActionTypes, historyWatcher };
