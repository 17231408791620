import { Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import getRenderer from './ItemRenderer';

interface IStringSelectorProps {
  items: string[];
  label: string;
  selectedItem?: string;
  onChange: (item: string | undefined) => void;
}

export default function StringSelector({ items, label, onChange, selectedItem }: IStringSelectorProps) {
  const buttonText = (selectedItem ?? label).toString().padEnd(14, '\xa0');
  const itemsWithNone = ['None', ...items];
  const selectItem = (item: string) => onChange(item === 'None' ? undefined : item);
  return (
    <Select<string> items={itemsWithNone} itemRenderer={getRenderer(true)} onItemSelect={selectItem} filterable={false}>
      <Button text={buttonText} rightIcon="double-caret-vertical" />
    </Select>
  );
}
