import { Action } from 'redux';

export const AUTH_RESTORE = 'AUTH_RESTORE';
export type AUTH_RESTORE_TYPE = typeof AUTH_RESTORE;
export interface IRestoreAuthAction extends Action<AUTH_RESTORE_TYPE> {
  type: AUTH_RESTORE_TYPE;
}

export const AUTH_RESTORE_FAIL = 'AUTH_RESTORE_FAIL';
export type AUTH_RESTORE_FAIL_TYPE = typeof AUTH_RESTORE_FAIL;
export interface IRestoreAuthFailedAction extends Action<AUTH_RESTORE_FAIL_TYPE> {
  type: AUTH_RESTORE_FAIL_TYPE;
}

export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export type AUTH_UPDATE_USER = typeof AUTH_UPDATE_USER;
export interface IUpdateUserAction extends Action<AUTH_UPDATE_USER> {
  type: AUTH_UPDATE_USER;
  userId: number;
  isAdmin: boolean;
  expires: number;
  session: string;
  email?: string;
  tokenExpires: number;
}

export const AUTH_RESTORE_SUCCESS = 'AUTH_RESTORE_SUCCESS';
export type AUTH_RESTORE_SUCCESS = typeof AUTH_RESTORE_SUCCESS;
export interface IRestoreAuthSucceedAction extends Action<AUTH_RESTORE_SUCCESS> {
  type: AUTH_RESTORE_SUCCESS;
}

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export type AUTH_LOGOUT = typeof AUTH_LOGOUT;
export interface ILogoutAction extends Action<AUTH_LOGOUT> {
  type: AUTH_LOGOUT;
}

export const AUTH_LOGIN = 'AUTH_LOGIN';
export type AUTH_LOGIN_TYPE = typeof AUTH_LOGIN;
export interface ILoginAction extends Action<AUTH_LOGIN_TYPE> {
  type: AUTH_LOGIN_TYPE;
}

export const AUTH_TOKEN_REFRESH_START = 'AUTH_TOKEN_REFRESH_START';
export type AUTH_TOKEN_REFRESH_START_TYPE = typeof AUTH_TOKEN_REFRESH_START;
export interface IStartTokenRefreshAction extends Action<AUTH_TOKEN_REFRESH_START_TYPE> {
  type: AUTH_TOKEN_REFRESH_START_TYPE;
  expires: number;
}

export const AUTH_TOKEN_REFRESH = 'AUTH_TOKEN_REFRESH';
export type AUTH_TOKEN_REFRESH_TYPE = typeof AUTH_TOKEN_REFRESH;
export interface IRefreshTokenAction extends Action<AUTH_TOKEN_REFRESH_TYPE> {
  type: AUTH_TOKEN_REFRESH_TYPE;
}

export type IAuthActions =
  | IRestoreAuthAction
  | IRestoreAuthFailedAction
  | IUpdateUserAction
  | IRestoreAuthSucceedAction
  | ILogoutAction
  | ILoginAction
  | IStartTokenRefreshAction
  | IRefreshTokenAction;
