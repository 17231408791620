import { IApiService } from 'web_core_library';

export function createMessagesService() {
  let ApiService: IApiService;
  let userId: number;
  function init(ApiServiceInstance: IApiService, uId?: number) {
    ApiService = ApiServiceInstance;
    if (uId) {
      userId = uId;
    }
  }
  function sendMessage(recipient: number, message: string) {
    return ApiService.action('users_messages', {
      method: 'post',
      urlParams: {
        filter: '',
        userId,
      },
      data: {
        recipient,
        messageBody: message,
      },
    });
  }
  const service = {
    init,
    sendMessage,
  };
  return service;
}

const MessagesService = createMessagesService();
export default MessagesService;
