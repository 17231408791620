import { Action } from 'redux';
import { UserDependentActionPayload } from '../types/actions';
import { actionBuilder } from './actionBuilder';

export const ADMIN_INIT = 'ADMIN_INIT';
export type ADMIN_INIT_TYPE = typeof ADMIN_INIT;
export interface IInitAdminAction extends Action<ADMIN_INIT_TYPE> {
  type: ADMIN_INIT_TYPE;
}
export const initAdminAction: () => IInitAdminAction = () => ({
  type: ADMIN_INIT,
});

export const ADMIN_CHECK_NEWSLETTER = 'ADMIN_CHECK_NEWSLETTER';
export interface NewsletterCheckPayload {
  mail: string;
}
export const adminCheckNewsletterAction = actionBuilder<NewsletterCheckPayload>(ADMIN_CHECK_NEWSLETTER, 'mail');

export const ADMIN_CHECK_NEWSLETTER_MULTIPLE = 'ADMIN_CHECK_NEWSLETTER_MULTIPLE';
export const adminNewsletterMultipleAction = actionBuilder(ADMIN_CHECK_NEWSLETTER_MULTIPLE);

export const ADMIN_CHECK_NEWSLETTER_RESULT = 'ADMIN_CHECK_NEWSLETTER_RESULT';
export interface NewsletterCheckResultPayload {
  newsletter: boolean;
}
export const adminCheckNewsletterResultAction = actionBuilder<NewsletterCheckResultPayload>(
  ADMIN_CHECK_NEWSLETTER_RESULT,
  'newsletter'
);

export const ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE = 'ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE';
export const adminNewsletterUnsubscribeAction = actionBuilder(ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE);

export const ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE_SUCCESS = 'ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE_SUCCESS';
export const adminNewsletterUnsubscribeSuccessAction = actionBuilder(ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE_SUCCESS);

export const ADMIN_SIGNIN_AS_USER_REQUEST = 'ADMIN_SIGNIN_AS_USER_REQUEST';
export const adminSigninAsUserRequestAction = actionBuilder<UserDependentActionPayload>(
  ADMIN_SIGNIN_AS_USER_REQUEST,
  'id'
);

export const ADMIN_SIGNIN_AS_USER_RESPONSE = 'ADMIN_SIGNIN_AS_USER_RESPONSE';
export interface UserTokenResponsePayload extends UserDependentActionPayload {
  session: string;
}
export const adminSigninAsUserResponseAction = actionBuilder<UserTokenResponsePayload>(
  ADMIN_SIGNIN_AS_USER_RESPONSE,
  'id',
  'session'
);
