import { all, call, takeEvery } from 'redux-saga/effects';
import * as Types from './actionTypes';
import NotificationService from './NotificationService';

export function* showToast(action: Types.IShowNotificationAction) {
  const { intent, message } = action;
  yield call(NotificationService.showMessage, intent, message);
}

export default function* messageWatcher() {
  yield all([takeEvery(Types.MESSAGES_SHOW_MESSAGE, showToast)]);
}
