import { Alignment, Navbar, NavbarGroup } from '@blueprintjs/core';
import * as React from 'react';
import NavButton from './NavButton';
import UserMenu from './UserMenu';

export default function Header() {
  return (
    <Navbar className="header">
      <NavbarGroup align={Alignment.LEFT}>
        <NavButton to="/" exact={true} icon="user">
          Users/orders management
        </NavButton>
        <NavButton to={'/feedback'} icon="th-filtered">
          Feedback console
        </NavButton>
      </NavbarGroup>
      <UserMenu />
    </Navbar>
  );
}
