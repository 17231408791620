import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersCountry } from '../../selectors';

interface ICountryFilterProps {
  selectedCountries: string[];
  onChange: (countries: string[]) => void;
}

export default function CountryFilter({ onChange, selectedCountries }: ICountryFilterProps) {
  const availableCountries = useSelector(getFiltersCountry);
  return (
    <StringListMultiSelector
      items={availableCountries}
      label="Country"
      onChange={onChange}
      selectedItems={selectedCountries}
    />
  );
}
