import * as React from 'react';
import { connect } from 'react-redux';

import { loadUserEmailHistoryAction } from './actions';
import EmailHistoryWrapper from './EmailHistoryWrapper';

export interface OwnProps {
  userId: number;
}

export interface Props extends OwnProps {
  onStart: (userId: number) => void;
}

// this wrapper is needed because currently enzyme shallow renderer does not support useEffect hook
export function StartWrapper({ onStart, userId }: Props) {
  // whatch for changes in userId and call start handler when userid is changed
  React.useEffect(() => {
    if (userId && onStart) {
      onStart(userId);
    }
  }, [onStart, userId]);
  // render wrapper
  return <EmailHistoryWrapper />;
}

export const mapDispatchToProps = {
  onStart: loadUserEmailHistoryAction,
};

export default connect(null, mapDispatchToProps)(StartWrapper);
