import { ApiResponsePayload } from '../types/actions';
import { actionBuilder } from './actionBuilder';

export const APP_GET_PRODUCTS = 'APP_GET_PRODUCTS';
export const getProducts = actionBuilder(APP_GET_PRODUCTS);

export const APP_GET_PRODUCTS_SUCCESS = 'APP_GET_PRODUCTS_SUCCESS';
export const getProductsSuccess = actionBuilder<ApiResponsePayload>(APP_GET_PRODUCTS_SUCCESS, 'response');

export const APP_GET_PRODUCTS_FAIL = 'APP_GET_PRODUCTS_FAIL';
export const getProductsFail = actionBuilder<ApiResponsePayload>(APP_GET_PRODUCTS_FAIL, 'response');
