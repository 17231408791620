import { UserDependentActionPayload } from '../types/actions';
import { ISubscriptionData, UserStatusesMap } from '../types/state';
import { actionBuilder } from './actionBuilder';

export const USERS_GET_USER_SUBSCRIPTION = 'USERS_GET_USER_SUBSCRIPTION';
export const getUserSubscriptionAction = actionBuilder<UserDependentActionPayload>(USERS_GET_USER_SUBSCRIPTION, 'id');

export const USERS_USER_SUBSCRIPTION_UPDATE = 'USERS_USER_SUBSCRIPTION_UPDATE';
export interface UserSubscriptionUpdateActionPayload extends UserDependentActionPayload {
  subscriptions: ISubscriptionData[];
}
export const userSubscriptionUpdateAction = actionBuilder<UserSubscriptionUpdateActionPayload>(
  USERS_USER_SUBSCRIPTION_UPDATE,
  'id',
  'subscriptions'
);

export const USERS_STATUSES_UPDATE = 'USERS_STATUSES_UPDATE';
export interface UserStatusesUpdateActionPayload extends UserDependentActionPayload {
  details: UserStatusesMap;
}
export const statusesUpdate = actionBuilder<UserStatusesUpdateActionPayload>(USERS_STATUSES_UPDATE, 'id', 'details');

// start loading detailed information about selected user
export const USERS_DETAILS_LOAD = 'USERS_DETAILS_LOAD';
export const detailsLoadAction = actionBuilder<UserDependentActionPayload>(USERS_DETAILS_LOAD, 'id');

// detailed information about selected user is loaded
export const USERS_DETAILS_LOADED = 'USERS_DETAILS_LOADED';
export const detailsLoaded = actionBuilder<UserDependentActionPayload>(USERS_DETAILS_LOADED, 'id');

// some error happened during the detailed info loading process
export const USERS_DETAILS_FAILED = 'USERS_DETAILS_FAILED';
export const detailsFailure = actionBuilder<UserDependentActionPayload>(USERS_DETAILS_FAILED, 'id');

export const USERS_USER_RELOAD = 'USERS_USER_RELOAD';
export const reloadUserAction = actionBuilder<UserDependentActionPayload>(USERS_USER_RELOAD, 'id');
