import * as ActionTypes from './actionTypes';
import { TFilters, TReportData } from './types';

export interface IFeedbackState {
  // feature ready flag
  ready: boolean;
  // loading flag
  loading: boolean;
  // user feedback filters data
  filters?: TFilters;
  // user feedback reports data
  reports?: TReportData[];
}

export const initialState: IFeedbackState = {
  ready: false,
  loading: false,
};

export const statePropName = 'feedback';

export default function feedbackReducer(
  state: IFeedbackState = initialState,
  action: ActionTypes.IFeedbackActions
): IFeedbackState {
  switch (action.type) {
    case ActionTypes.FEEDBACK_READY: {
      return {
        ...state,
        ready: true,
      };
    }
    case ActionTypes.FEEDBACK_OPEN_TAB: {
      return {
        ...state,
        loading: true,
        filters: undefined,
        reports: undefined,
      };
    }
    case ActionTypes.FEEDBACK_UPDATE_FILTERS: {
      const { filters } = action;
      return { ...state, filters };
    }
    case ActionTypes.FEEDBACK_LOAD_REPORTS: {
      return { ...state, loading: true, reports: undefined };
    }
    case ActionTypes.FEEDBACK_UPDATE_REPORTS: {
      const { reports } = action;
      return { ...state, reports };
    }
    case ActionTypes.FEEDBACK_LOADING_FINISHED: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
}
