import { Action } from 'redux';
import { IUserBaseData } from '../state/types/state';

/**
 * Search form action types
 */

export const SEARCH_FORM_USERNAME_UPDATE = 'SEARCH_FORM_USERNAME_UPDATE';
export type SEARCH_FORM_USERNAME_UPDATE_TYPE = typeof SEARCH_FORM_USERNAME_UPDATE;
export interface IUpdateFormUsernameAction extends Action<SEARCH_FORM_USERNAME_UPDATE_TYPE> {
  type: SEARCH_FORM_USERNAME_UPDATE_TYPE;
  value: string;
}

export const SEARCH_FORM_USERID_UPDATE = 'SEARCH_FORM_USERID_UPDATE';
export type SEARCH_FORM_USERID_UPDATE_TYPE = typeof SEARCH_FORM_USERID_UPDATE;
export interface IUpdateFormUseridAction extends Action<SEARCH_FORM_USERID_UPDATE_TYPE> {
  type: SEARCH_FORM_USERID_UPDATE_TYPE;
  value: number;
}

export const SEARCH_FORM_NAME_UPDATE = 'SEARCH_FORM_NAME_UPDATE';
export type SEARCH_FORM_NAME_UPDATE_TYPE = typeof SEARCH_FORM_NAME_UPDATE;
export interface IUpdateFormNameAction extends Action<SEARCH_FORM_NAME_UPDATE_TYPE> {
  type: SEARCH_FORM_NAME_UPDATE_TYPE;
  value: string;
}

export const SEARCH_FORM_ORDER_UPDATE = 'SEARCH_FORM_ORDER_UPDATE';
export type SEARCH_FORM_ORDER_UPDATE_TYPE = typeof SEARCH_FORM_ORDER_UPDATE;
export interface IUpdateFormOrderAction extends Action<SEARCH_FORM_ORDER_UPDATE_TYPE> {
  type: SEARCH_FORM_ORDER_UPDATE_TYPE;
  value: string;
}

export type ISearchFormUpdateAction =
  | IUpdateFormUsernameAction
  | IUpdateFormUseridAction
  | IUpdateFormNameAction
  | IUpdateFormOrderAction;

/**
 * Search action types
 */

export const USERS_SEARCH_USERNAME = 'USERS_SEARCH_USERNAME';
export type USERS_SEARCH_USERNAME_TYPE = typeof USERS_SEARCH_USERNAME;
export interface ISearchUsersByUsernameAction extends Action<USERS_SEARCH_USERNAME_TYPE> {
  type: USERS_SEARCH_USERNAME_TYPE;
  search: string;
}

export const USERS_SEARCH_ID = 'USERS_SEARCH_ID';
export type USERS_SEARCH_ID_TYPE = typeof USERS_SEARCH_ID;
export interface ISearchUsersByIdAction extends Action<USERS_SEARCH_ID_TYPE> {
  type: USERS_SEARCH_ID_TYPE;
  id: number;
}

export const USERS_SEARCH_NAME = 'USERS_SEARCH_NAME';
export type USERS_SEARCH_NAME_TYPE = typeof USERS_SEARCH_NAME;
export interface ISearchUsersByNameAction extends Action<USERS_SEARCH_NAME_TYPE> {
  type: USERS_SEARCH_NAME_TYPE;
  search: string;
}

export const USERS_SEARCH_ORDER = 'USERS_SEARCH_ORDER';
export type USERS_SEARCH_ORDER_TYPE = typeof USERS_SEARCH_ORDER;
export interface ISearchUsersByOrderAction extends Action<USERS_SEARCH_ORDER_TYPE> {
  type: USERS_SEARCH_ORDER_TYPE;
  search: string;
}

export type ISearchUserOrdersActions = ISearchUsersByNameAction | ISearchUsersByOrderAction;

/**
 * Search results action types
 */

export const USERS_SEARCH_RESULTS = 'USERS_SEARCH_RESULTS';
export type USERS_SEARCH_RESULTS_TYPE = typeof USERS_SEARCH_RESULTS;
export interface ISearchUsersResultAction extends Action<USERS_SEARCH_RESULTS_TYPE> {
  type: USERS_SEARCH_RESULTS_TYPE;
  users: IUserBaseData[];
}

export const USERS_SEARCH_ID_NOTFOUND = 'USERS_SEARCH_ID_NOTFOUND';
export type USERS_SEARCH_ID_NOTFOUND_TYPE = typeof USERS_SEARCH_ID_NOTFOUND;
export interface ISearchUserNotFoundAction extends Action<USERS_SEARCH_ID_NOTFOUND_TYPE> {
  type: USERS_SEARCH_ID_NOTFOUND_TYPE;
  id: number;
}

export const USERS_SEARCH_FAILED = 'USERS_SEARCH_FAILED';
export type USERS_SEARCH_FAILED_TYPE = typeof USERS_SEARCH_FAILED;
export interface ISearchUsersFailedAction extends Action<USERS_SEARCH_FAILED_TYPE> {
  type: USERS_SEARCH_FAILED_TYPE;
  error: any;
}

export const USERS_SELECT_USER_LIST_ITEM = 'USERS_SELECT_USER_LIST_ITEM';
export type USERS_SELECT_USER_LIST_ITEM_TYPE = typeof USERS_SELECT_USER_LIST_ITEM;
export interface ISearchUsersResultSelectAction extends Action<USERS_SELECT_USER_LIST_ITEM_TYPE> {
  type: USERS_SELECT_USER_LIST_ITEM_TYPE;
  id: number;
}

export const USERS_SELECT_USER = 'USERS_SELECT_USER';
export type USERS_SELECT_USER_TYPE = typeof USERS_SELECT_USER;
export interface ISearchSelectUserAction extends Action<USERS_SELECT_USER_TYPE> {
  type: USERS_SELECT_USER_TYPE;
  id: number;
}

export const USERS_DESELECT_USER = 'USERS_DESELECT_USER';
export type USERS_DESELECT_USER_TYPE = typeof USERS_DESELECT_USER;
export interface ISearchDeselectUserAction extends Action<USERS_DESELECT_USER_TYPE> {
  type: USERS_DESELECT_USER_TYPE;
}

export const USERS_REMOVE_USER = 'USERS_REMOVE_USER';
export type USERS_REMOVE_USER_TYPE = typeof USERS_REMOVE_USER;
export interface ISearchRemoveUserAction extends Action<USERS_REMOVE_USER_TYPE> {
  type: USERS_REMOVE_USER_TYPE;
  id: number;
}

export type ISearchUsersAction =
  | ISearchUsersByUsernameAction
  | ISearchUsersByIdAction
  | ISearchUsersByNameAction
  | ISearchUsersByOrderAction
  | ISearchUsersResultAction
  | ISearchUserNotFoundAction
  | ISearchUsersFailedAction
  | ISearchUsersResultSelectAction
  | ISearchSelectUserAction
  | ISearchDeselectUserAction
  | ISearchRemoveUserAction;

export type ISearchActions = ISearchFormUpdateAction | ISearchUsersAction;
