import { Action } from 'redux';

export const ACTIONS_GET_USER_NEWSLETTER = 'ACTIONS_GET_USER_NEWSLETTER';
export type ACTIONS_GET_USER_NEWSLETTER = typeof ACTIONS_GET_USER_NEWSLETTER;
export interface IGetUserNewsletterAction extends Action<ACTIONS_GET_USER_NEWSLETTER> {
  type: ACTIONS_GET_USER_NEWSLETTER;
  userId: number;
}

export const ACTIONS_USER_NEWSLETTER_UPDATE = 'ACTIONS_USER_NEWSLETTER_UPDATE';
export type ACTIONS_USER_NEWSLETTER_UPDATE = typeof ACTIONS_USER_NEWSLETTER_UPDATE;
export interface IUpdateUserNewsletterAction extends Action<ACTIONS_USER_NEWSLETTER_UPDATE> {
  type: ACTIONS_USER_NEWSLETTER_UPDATE;
  userId: number;
  newsletter: boolean;
}

export const ACTIONS_TOGGLE_USER_NEWSLETTER = 'ACTIONS_TOGGLE_USER_NEWSLETTER';
export type ACTIONS_TOGGLE_USER_NEWSLETTER = typeof ACTIONS_TOGGLE_USER_NEWSLETTER;
export interface IToggleUserNewsletterAction extends Action<ACTIONS_TOGGLE_USER_NEWSLETTER> {
  type: ACTIONS_TOGGLE_USER_NEWSLETTER;
  userId: number;
}

export const ACTIONS_GET_USER_TOKEN = 'ACTIONS_GET_USER_TOKEN';
export type ACTIONS_GET_USER_TOKEN = typeof ACTIONS_GET_USER_TOKEN;
export interface IGetUserTokenAction extends Action<ACTIONS_GET_USER_TOKEN> {
  type: ACTIONS_GET_USER_TOKEN;
  userId: number;
}

export const ACTIONS_URL_UPDATE = 'ACTIONS_URL_UPDATE';
export type ACTIONS_URL_UPDATE = typeof ACTIONS_URL_UPDATE;
export interface IUpdateUrlAction extends Action<ACTIONS_URL_UPDATE> {
  type: ACTIONS_URL_UPDATE;
  userId: number;
  opxpUrl: string;
  checkoutUrl: string;
}

export const ACTIONS_USER_SESSION_UPDATE = 'ACTIONS_USER_SESSION_UPDATE';
export type ACTIONS_USER_SESSION_UPDATE = typeof ACTIONS_USER_SESSION_UPDATE;
export interface IUpdateUserSessionAction extends Action<ACTIONS_USER_SESSION_UPDATE> {
  type: ACTIONS_USER_SESSION_UPDATE;
  userId: number;
  session: string;
}

export const ACTIONS_USER_ACTIVE_UPDATE = 'ACTIONS_USER_ACTIVE_UPDATE';
export type ACTIONS_USER_ACTIVE_UPDATE = typeof ACTIONS_USER_ACTIVE_UPDATE;
export interface IUpdateUserActiveAction extends Action<ACTIONS_USER_ACTIVE_UPDATE> {
  type: ACTIONS_USER_ACTIVE_UPDATE;
  userId: number;
  isActive: boolean;
}

export const ACTIONS_USER_DELETE = 'ACTIONS_USER_DELETE';
export type ACTIONS_USER_DELETE = typeof ACTIONS_USER_DELETE;
export interface IDeleteUserAction extends Action<ACTIONS_USER_DELETE> {
  type: ACTIONS_USER_DELETE;
  userId: number;
}

export const ACTIONS_UPDATE_PRODUCTS = 'ACTIONS_UPDATE_PRODUCTS';
export type ACTIONS_UPDATE_PRODUCTS = typeof ACTIONS_UPDATE_PRODUCTS;
export interface IUpdateProductsAction extends Action<ACTIONS_UPDATE_PRODUCTS> {
  type: ACTIONS_UPDATE_PRODUCTS;
  products: number[];
}

export type UserActionsActions =
  | IGetUserNewsletterAction
  | IUpdateUserNewsletterAction
  | IToggleUserNewsletterAction
  | IGetUserTokenAction
  | IUpdateUrlAction
  | IUpdateUserSessionAction
  | IUpdateUserActiveAction
  | IDeleteUserAction
  | IUpdateProductsAction;
