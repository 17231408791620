import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { NotificationActions } from '../notifications';
import { getSelectedUserId } from '../search/selectors';
import { ApiResponse } from '../services/apiResponse';
import { copyToClipboard } from '../services/clipboardService';
import * as UsersActions from '../state/actions/users';
import { ApiResponseAction } from '../state/types/actions';
import { IAddress, IOrderData } from '../state/types/base';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import OrdersService from './ordersService';

export function* handleOrdersLoad(action: ActionTypes.IGetUserOrdersAction) {
  const userId = action.id;
  yield put(UsersActions.getUserOrdersRequestAction(userId));
  const result: ApiResponseAction = yield take([
    UsersActions.USERS_GET_USER_ORDERS_SUCCESS,
    UsersActions.USERS_GET_USER_ORDERS_FAIL,
  ]);
  const { response } = result.payload;
  if (response.isError()) {
    yield put(Actions.getUserOrdersFailedAction(userId));
  } else {
    const initialOrders = response.data.orders as IOrderData[];
    // sort result by date and exclude duplicates
    const sorted = reverse(sortBy(uniqBy(initialOrders, 'referenceId'), 'date'));
    yield put(Actions.userOrdersUpdateAction(userId, sorted));
  }
}

export function* handleOrderComplete(action: ActionTypes.ICompleteOrderAction) {
  const userId = yield select(getSelectedUserId);
  const referenceId = action.referenceId;
  yield put(Actions.completeOrderRequestAction(userId, referenceId));
  const result: ActionTypes.ICompleteOrderResponseAction = yield take([
    ActionTypes.ORDERS_COMPLETE_ORDER_FAIL,
    ActionTypes.ORDERS_COMPLETE_ORDER_SUCCESS,
  ]);
  if (result.response.isError()) {
    yield put(
      NotificationActions.showError(
        `Server answered with error with code ${result.response.status} saying "${result.response.statusText}"`
      )
    );
    return;
  }
  yield put(Actions.deselectOrderAction(userId));
  yield put(NotificationActions.showSuccess('Order status successfully changed!'));
  yield put(Actions.getUserOrdersAction(userId));
}

export function* handleOrderCompleteRequest(action: ActionTypes.ICompleteOrderRequestAction) {
  try {
    if (!OrdersService.isReady()) {
      yield call(OrdersService.init, ApiService);
    }
    const referenceId = action.referenceId;
    const userId = action.userId;
    const response = yield call(OrdersService.completeOrder, userId, referenceId);
    yield put(Actions.completeOrderSuccessAction(new ApiResponse(response)));
  } catch (error) {
    yield put(Actions.completeOrderFailedAction(new ApiResponse((error as HttpError).baseError ?? error)));
  }
}

export function* handleDisputeLoad(action: ActionTypes.ISelectOrderAction) {
  try {
    if (!OrdersService.isReady()) {
      yield call(OrdersService.init, ApiService);
    }
    const { referenceId, id: userId } = action;
    const response = yield call(OrdersService.getDisputeInfo, referenceId);
    const dispute = response ? response.data.html : '';
    yield put(Actions.updateOrdersDisputeAction(userId, dispute));
  } catch (error) {
    const errorResponse = new ApiResponse((error as HttpError).baseError ?? error);
    if (errorResponse.status !== 404) {
      yield put(
        NotificationActions.showError(
          `${errorResponse.status}: Could not get dispute of an order, saying "${errorResponse.statusText}"`
        )
      );
    }
  }
}

export function* handleCopyToClipboard(action: ActionTypes.ICopyToClipboardAction) {
  const successful = copyToClipboard(action.html);
  const msg = successful ? 'successful' : 'unsuccessful';
  const intent = successful ? 'success' : 'danger';
  yield put(NotificationActions.showNotification(intent, `Dispute copied to clipboard ${msg}`));
}

export function* handleAddressFetch({ userId }: ActionTypes.IFetchAddressesAction) {
  try {
    if (!OrdersService.isReady()) {
      yield call(OrdersService.init, ApiService);
    }
    const response = yield call(OrdersService.getUserAddresses, userId);
    const addresses = response.data.addresses as IAddress[];
    yield put(Actions.updateUserAddressesAction(userId, addresses));
  } catch (error) {
    const errorResponse = new ApiResponse((error as HttpError).baseError ?? error);
    yield put(
      NotificationActions.showError(
        `${errorResponse.status}: Could not get users addresses, saying "${errorResponse.statusText}"`
      )
    );
  }
}

export function* handleAddressSave({ address, userId }: ActionTypes.ISaveAddressAction) {
  try {
    if (!OrdersService.isReady()) {
      yield call(OrdersService.init, ApiService);
    }
    // save address
    yield call(OrdersService.saveUserAddress, userId, address);
    yield put(NotificationActions.showSuccess('User address successfully saved!'));
    // fetch adress to refresh the data
    yield put(Actions.getUserOrdersAction(userId));
  } catch (error) {
    const errorResponse = new ApiResponse((error as HttpError).baseError ?? error);
    yield put(
      NotificationActions.showError(
        `${errorResponse.status}: Could not save the address, saying "${errorResponse.statusText}"`
      )
    );
  }
}

export default function* ordersWatcher() {
  yield all([
    takeEvery(ActionTypes.ORDERS_GET_USER_ORDERS, handleOrdersLoad),
    takeEvery(ActionTypes.ORDERS_SELECT_ORDER, handleDisputeLoad),
    takeEvery(ActionTypes.ORDERS_COMPLETE_ORDER, handleOrderComplete),
    takeEvery(ActionTypes.ORDERS_COMPLETE_ORDER_REQUEST, handleOrderCompleteRequest),
    takeEvery(ActionTypes.COPY_TO_CLIPBOARD, handleCopyToClipboard),
    takeEvery(ActionTypes.ORDERS_FETCH_ADDRESSES, handleAddressFetch),
    takeEvery(ActionTypes.ORDERS_SAVE_ADDRESS, handleAddressSave),
  ]);
}
