/**
 * Saga to handle different search requests from user
 * implements business logic behind search - consequitive calls to orders or users list, details etc.
 */
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { all, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { NotificationActions } from '../notifications';
import { adminCheckNewsletterAction } from '../state/actions/admin';
import { detailsLoadAction } from '../state/actions/details';
import * as UsersActions from '../state/actions/users';
import { getUser } from '../state/selectors';
import { ApiResponseAction, IAction } from '../state/types/actions';
import { IUserBaseData } from '../state/types/state';
import { StatsActions } from '../userStats';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { USERS_LIST_PAGE_SIZE } from './constants';
import { getSelectedUserId } from './selectors';

export function* getUserById(id: number) {
  const user = yield select(getUser, id);
  const userLoaded = user && user.isExtended && user.isExtended();
  if (!userLoaded) {
    yield put(UsersActions.getUserByIdRequestAction(id));
    let correctRespone = false;
    while (!correctRespone) {
      const result: IAction<UsersActions.GetUserByIdResponsePayload> = yield take([
        UsersActions.USERS_GET_USER_BY_ID_FAIL,
        UsersActions.USERS_GET_USER_BY_ID_SUCCESS,
      ]);
      const { response, userId } = result.payload;
      if (userId !== id) {
        continue;
      }
      correctRespone = true;
      if (response.isError()) {
        if (response.isEmpty()) {
          yield put(Actions.searchUserByIdNotFoundAction(id));
        } else {
          yield put(Actions.searchFailure(response));
        }
        // do not load details if fetching failed
        return;
      } else {
        yield put(UsersActions.getUserResult(response.data.user));
      }
    }
  }
  // for currently selected user
  const selectedUser = yield select(getSelectedUserId);
  if (selectedUser === id) {
    // load also details information (like subscriptions, orders etc.)
    yield put(detailsLoadAction(id));
  }
}

export function* handleSearchUsersByUsername(action: ActionTypes.ISearchUsersByUsernameAction) {
  const mail = action.search;
  yield put(UsersActions.searchUsersByUsernameRequestAction(mail));
  const result: ApiResponseAction = yield take([
    UsersActions.USERS_SEARCH_USERNAME_FAIL,
    UsersActions.USERS_SEARCH_USERNAME_SUCCESS,
  ]);
  const { response } = result.payload;
  if (response.isError() && !response.isEmpty()) {
    yield put(Actions.searchFailure(response));
    return;
  }
  let users: IUserBaseData[] = [];
  if (response.isSuccess()) {
    users = uniqBy(response.data as IUserBaseData[], 'id');
  }
  yield put(Actions.searchUsersResults(users));
  if (users.length === 0) {
    yield put(adminCheckNewsletterAction(mail));
  }
}

export function* handleSearchUserById(action: ActionTypes.ISearchUsersByIdAction) {
  const id = action.id;
  // create an empty entry to show loading skeleton
  const users: IUserBaseData[] = [];
  users.push({
    id,
    name: '',
    picture: '',
  });
  yield put(Actions.searchUsersResults(users));
}

export function* handleSearchUserOrders(action: ActionTypes.ISearchUserOrdersActions) {
  let name;
  let order;
  if (action.type === ActionTypes.USERS_SEARCH_ORDER) {
    order = action.search;
  } else {
    name = action.search;
  }
  yield put(UsersActions.searchOrdersRequestAction(name, order));
  const result: UsersActions.ISearchOrdersResponseAction = yield take([
    UsersActions.USERS_SEARCH_ORDERS_FAIL,
    UsersActions.USERS_SEARCH_ORDERS_SUCCESS,
  ]);
  const { response } = result;
  if (response.isError() && !response.isEmpty()) {
    yield put(Actions.searchFailure(response));
    yield put(NotificationActions.showError(response.statusText));
  } else {
    let users: IUserBaseData[] = [];
    if (response.isSuccess()) {
      // limit the result by 20 items for performance reasons, we could implement pagination later
      users = uniqBy(response.data as IUserBaseData[], 'id');
      // preserve realname from order data
      users = map(users, (user: IUserBaseData) => ({
        ...user,
        realname: user.name,
      }));
    }
    yield put(Actions.searchUsersResults(users));
  }
}

export function* handleUserSelection(action: ActionTypes.ISearchUsersResultSelectAction) {
  const userId = action.id;
  const selectedUser = yield select(getSelectedUserId);
  if (userId === selectedUser) {
    yield put(Actions.deselectUserAction());
  } else {
    yield put(Actions.selectUserAction(userId));
    yield spawn(getUserById, userId);
  }
}

export function* loadUserOnSelectSaga({ id }: ActionTypes.ISearchSelectUserAction) {
  // each time user is selected we prefetch user stats
  yield put(StatsActions.loadUserStatsAction(id));
}

export function* loadUserDetails({ users }: ActionTypes.ISearchUsersResultAction) {
  if (users.length > 0) {
    // select user if its the only one result
    if (users.length === 1) {
      // select user
      yield put(Actions.selectUserAction(users[0].id));
    }
    // load extended info about each of users
    yield spawn(getUsersDetailedInfo, users);
  }
}

export function* getUsersDetailedInfo(users: IUserBaseData[]) {
  for (const user of users.slice(0, USERS_LIST_PAGE_SIZE - 1)) {
    yield spawn(getUserById, user.id);
  }
}

export default function* searchWatcher() {
  yield all([
    takeEvery(ActionTypes.USERS_SEARCH_USERNAME, handleSearchUsersByUsername),
    takeEvery(ActionTypes.USERS_SEARCH_ID, handleSearchUserById),
    takeEvery([ActionTypes.USERS_SEARCH_NAME, ActionTypes.USERS_SEARCH_ORDER], handleSearchUserOrders),
    takeEvery(ActionTypes.USERS_SELECT_USER_LIST_ITEM, handleUserSelection),
    takeLatest(ActionTypes.USERS_SELECT_USER, loadUserOnSelectSaga),
    takeLatest(ActionTypes.USERS_SEARCH_RESULTS, loadUserDetails),
  ]);
}
