import * as ActionTypes from './actionTypes';
import { IDashboardUser, ISessionData } from './types';

export type AuthProps = { tokenExpires: number };
export type TAuthState = (IDashboardUser & ISessionData & AuthProps) | null;

export const initialState = null;

export const statePropName = 'auth';

/**
 * authorization reducer applies user data to state after successfull restoration
 * or clears user state if restoration has failed
 */
export default function authReducer(state: TAuthState = initialState, action: ActionTypes.IAuthActions): TAuthState {
  switch (action.type) {
    case ActionTypes.AUTH_UPDATE_USER: {
      const { expires, isAdmin, session, userId, email, tokenExpires } = action;
      return {
        ...state,
        email,
        expires,
        isAdmin,
        session,
        userId,
        tokenExpires,
      };
    }
    case ActionTypes.AUTH_RESTORE_FAIL:
    case ActionTypes.AUTH_LOGOUT:
      return null;
    default:
      return state;
  }
}
