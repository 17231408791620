import { Intent, Menu, MenuItem } from '@blueprintjs/core';
import { Cell, ColumnHeaderCell2, TruncatedFormat2, TruncatedPopoverMode } from '@blueprintjs/table';
import { ReactNode } from 'react';
import { localStorage } from 'web_core_library';
import { formatFullDate, parseDate } from '../../services/dateUtils';
import ClipBoardCopyWrapper from './ClipBoardCopyWrapper';
import LongTextCell from './LongTextCell';
import {
  ICellRenderOptions,
  ILongTextCellRenderOptions,
  TActiveCheckCallback,
  TCellLookup,
  TColumnDefinition,
  TComparatorCallback,
  TSortCallback,
} from './types';

export function getTextCellRenderer(
  columnName: string | number,
  getCellData: TCellLookup<string>,
  isRowActive: TActiveCheckCallback,
  options?: ICellRenderOptions
) {
  const { allowEmpty, copyEnabled, truncate } = options ?? {};
  return (rowIndex: number, columnIndex: number) => {
    let rawData = getCellData(rowIndex, columnName);
    if (!allowEmpty && !rawData) {
      rawData = 'N/A';
    }
    let content: ReactNode = rawData;
    if (truncate) {
      content = (
        <TruncatedFormat2 showPopover={TruncatedPopoverMode.WHEN_TRUNCATED} detectTruncation={true}>
          {rawData}
        </TruncatedFormat2>
      );
    }
    const intent: Intent = isRowActive(rowIndex) ? Intent.PRIMARY : Intent.NONE;
    return (
      <Cell intent={intent}>
        <ClipBoardCopyWrapper copyEnabled={copyEnabled} content={rawData}>
          {content}
        </ClipBoardCopyWrapper>
      </Cell>
    );
  };
}

export function getLongTextCellRenderer(
  columnName: string | number,
  getCellData: TCellLookup<string>,
  isRowActive: TActiveCheckCallback,
  options?: ILongTextCellRenderOptions
) {
  const { hideContent } = options ?? {};
  return (rowIndex: number, columnIndex: number) => {
    const rawData = getCellData(rowIndex, columnName);
    let content: ReactNode = 'N/A';
    if (rawData) {
      content = <LongTextCell content={rawData} hideContent={hideContent} />;
    }
    const intent: Intent = isRowActive(rowIndex) ? Intent.PRIMARY : Intent.NONE;
    return <Cell intent={intent}>{content}</Cell>;
  };
}

export function getDateCellRenderer(
  columnName: string | number,
  getCellData: TCellLookup<string>,
  isRowActive: TActiveCheckCallback,
  options?: ICellRenderOptions
) {
  const { copyEnabled } = options ?? {};
  return (rowIndex: number, columnIndex: number) => {
    const stringDate = getCellData(rowIndex, columnName);
    const cellContents = stringDate ? formatFullDate(parseDate(stringDate)) : 'N/A';
    const intent: Intent = isRowActive(rowIndex) ? Intent.PRIMARY : Intent.NONE;
    return (
      <Cell intent={intent} key={`${columnName}-${rowIndex}-${columnIndex}`}>
        <ClipBoardCopyWrapper copyEnabled={copyEnabled} content={cellContents}>
          {cellContents}
        </ClipBoardCopyWrapper>
      </Cell>
    );
  };
}

export function getBooleanCellRenderer(
  columnName: string | number,
  getCellData: TCellLookup<boolean>,
  isRowActive: TActiveCheckCallback
) {
  return (rowIndex: number, columnIndex: number) => {
    const value = getCellData(rowIndex, columnName);
    let cellContents = 'N/A';
    if (typeof value !== 'undefined') {
      cellContents = value ? 'true' : 'false';
    }
    const intent: Intent = isRowActive(rowIndex) ? Intent.PRIMARY : Intent.NONE;
    return (
      <Cell intent={intent} key={`${columnName}-${rowIndex}-${columnIndex}`}>
        {cellContents}
      </Cell>
    );
  };
}

export function getTextColumnHeaderCellRenderer(
  columnName: string | number,
  columnTitle: string | undefined,
  sortColumn: TSortCallback<string>
) {
  return () => (
    <ColumnHeaderCell2
      name={columnTitle ?? `${columnName}`}
      menuRenderer={() => renderTextSortMenu(columnName, sortColumn)}
    />
  );
}

export function getNumberColumnHeaderCellRenderer(
  columnName: string | number,
  columnTitle: string | undefined,
  sortColumn: TSortCallback<number>
) {
  return () => (
    <ColumnHeaderCell2
      name={columnTitle ?? `${columnName}`}
      menuRenderer={() => renderNumberSortMenu(columnName, sortColumn)}
    />
  );
}

export function getBooleanColumnHeaderCellRenderer(
  columnName: string | number,
  columnTitle: string | undefined,
  sortColumn: TSortCallback<boolean>
) {
  return () => (
    <ColumnHeaderCell2
      name={columnTitle ?? `${columnName}`}
      menuRenderer={() => renderBooleanSortMenu(columnName, sortColumn)}
    />
  );
}

export function getDateColumnHeaderCellRenderer(
  columnName: string | number,
  columnTitle: string | undefined,
  sortColumn: TSortCallback<string>
) {
  return () => (
    <ColumnHeaderCell2
      name={columnTitle ?? `${columnName}`}
      menuRenderer={() => renderDateSortMenu(columnName, sortColumn)}
    />
  );
}

export function renderTextSortMenu(columnName: string | number, sortColumn: TSortCallback<string>) {
  const sortAsc = () => sortColumn(columnName, (a, b) => compareText(a, b));
  const sortDesc = () => sortColumn(columnName, (a, b) => compareText(b, a));
  return (
    <Menu>
      <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort ASC" />
      <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort DESC" />
    </Menu>
  );
}

export function renderNumberSortMenu(columnName: string | number, sortColumn: TSortCallback<number>) {
  const sortAsc = () => sortColumn(columnName, (a, b) => compareNumber(a, b));
  const sortDesc = () => sortColumn(columnName, (a, b) => compareNumber(b, a));
  return (
    <Menu>
      <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort ASC" />
      <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort DESC" />
    </Menu>
  );
}

export function renderBooleanSortMenu(columnName: string | number, sortColumn: TSortCallback<boolean>) {
  const sortAsc = () => sortColumn(columnName, (a, b) => compareBoolean(a, b));
  const sortDesc = () => sortColumn(columnName, (a, b) => compareBoolean(b, a));
  return (
    <Menu>
      <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort ASC" />
      <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort DESC" />
    </Menu>
  );
}

export function renderDateSortMenu(columnName: string | number, sortColumn: TSortCallback<string>) {
  const sortAsc = () => sortColumn(columnName, (a, b) => compareDate(a, b));
  const sortDesc = () => sortColumn(columnName, (a, b) => compareDate(b, a));
  return (
    <Menu>
      <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort ASC" />
      <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort DESC" />
    </Menu>
  );
}

export const compareText: TComparatorCallback<string> = (a, b) => a.localeCompare(b);
export const compareBoolean: TComparatorCallback<boolean> = (a, b) => {
  if (a && !b) {
    return -1;
  } else if (!a && b) {
    return 1;
  }
  return 0;
};
export const compareDate: TComparatorCallback<string> = (a, b) => parseDate(a).valueOf() - parseDate(b).valueOf();
export const compareNumber: TComparatorCallback<number> = (a, b) => a - b;

export function getColumnsFromStore(tableName: string) {
  return localStorage.load<TColumnDefinition[]>(`${tableName}-columns`);
}

export function saveColumnsToStore(tableName: string, columns: TColumnDefinition[]) {
  return localStorage.save<TColumnDefinition[]>(`${tableName}-columns`, columns);
}

export function reorderArray<T>(array: T[], from: number, to: number): T[] {
  if (from === to) {
    return array.slice();
  }
  const result = [...array];
  result[from] = array[to];
  result[to] = array[from];
  return result;
}

export function renderPredicate(col: React.JSX.Element | null): col is React.JSX.Element {
  return col !== null;
}
