import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { IFreeCouponStatus } from './types';

interface IFreeCouponStatusWidgetProps {
  coupon: IFreeCouponStatus;
}

export default function FreeCouponStatusWidget({ coupon }: IFreeCouponStatusWidgetProps) {
  return (
    <Callout intent={Intent.PRIMARY} icon={IconNames.DOLLAR} title="Free coupon found:">
      <p>Coupon code: {coupon.code}</p>
      <p>Created at: {coupon.created_at}</p>
      <p>Duration: {coupon.duration}</p>
    </Callout>
  );
}
