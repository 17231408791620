import moment from 'moment';
import { TAppliedFilter, TFilterParams } from './types';

function parseEmptyStringValue(value: string | null): string {
  if (value) {
    return value;
  }
  if (value === null) {
    return '[null]';
  }
  return '[empty string]';
}

function convertEmptyStringValue(value: string): string {
  if (value === '[null]') {
    return 'null';
  }
  if (value === '[empty string]') {
    return '';
  }
  return value;
}

export function parseFilterParams(filters: TAppliedFilter): TFilterParams {
  const params = {};
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      let value = filters[key];
      if (typeof value === 'object' && value.length) {
        value = (value as Array<string>).map(convertEmptyStringValue).join(',');
      } else {
        value = convertEmptyStringValue(value);
      }
      params[key] = value;
    }
  }
  return params;
}

export function parseStringFilter(input: Array<string | null>): Array<string> {
  return input.map(parseEmptyStringValue);
}

export function formatTimestampFrom(date: Date) {
  return moment(date)
    .hours(0)
    .minutes(0)
    .seconds(0)
    .format('YYYY-MM-DDTHH:mm:ss');
}

export function formatTimestampTo(date: Date) {
  return moment(date)
    .hours(23)
    .minutes(59)
    .seconds(59)
    .format('YYYY-MM-DDTHH:mm:ss');
}
