import { Spinner, Tab, Tabs, TabsExpander } from '@blueprintjs/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { openFeedbackTabAction } from './actions';
import AppReportsTab from './appReports/AppReportsTab';
import ExerciseReportsTab from './exerciseReports/ExerciseReportsTab';
import './FeedbackConsole.scss';
import { isLoading } from './selectors';
import { TFeedbackTabs } from './types';
import UserFeedbackTab from './userFeedback/UserFeedbackTab';

interface IPageRouteParams {
  reportType?: TFeedbackTabs;
}

export default function FeedbackConsole() {
  const {
    params: { reportType },
  } = useRouteMatch<IPageRouteParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const navigate = (to: TFeedbackTabs) => history.push(`/feedback/${to}`);
  useEffect(() => {
    const tab = reportType ?? 'feedback';
    dispatch(openFeedbackTabAction(tab));
  }, [reportType]);
  const loading = useSelector(isLoading);
  // NOTE: we might want to use renderActiveTabPanelOnly={true} on tabs
  // if we run into a performance issue when rendering huge amount of data
  // currently all three tab panels are rendered just hidden with css means
  return (
    <Tabs className="feedback-console" id="feedback-tabs" onChange={navigate} large={true} selectedTabId={reportType}>
      <Tab className="console-tab" id="feedback" title="User feedback" panel={<UserFeedbackTab />} />
      <Tab className="console-tab" id="appreports" title="App reports" panel={<AppReportsTab />} />
      <Tab className="console-tab" id="exercisereports" title="Exercise Reports" panel={<ExerciseReportsTab />} />
      <TabsExpander />
      {loading ? <Spinner size={40} intent="warning" /> : null}
    </Tabs>
  );
}
