import * as ActionTypes from './actionTypes';
import { Themes } from './types';

export const restoreThemeAction: () => ActionTypes.IRestoreThemeAction = () => ({
  type: ActionTypes.THEME_RESTORE,
});

export const updateThemeAction: (theme: Themes) => ActionTypes.IUpdateThemeAction = (theme) => ({
  type: ActionTypes.THEME_UPDATE,
  theme,
});
