import * as ActionTypes from './actionTypes';
import { IUserStatGet } from './types';

export interface IUserStatsState {
  [userId: number]: IUserStatGet[];
}

export const initialState: IUserStatsState = {};

export default function userStatsReducer(state = initialState, action: ActionTypes.IUserStatsAction) {
  switch (action.type) {
    case ActionTypes.USER_STATS_UPDATE: {
      const { userId, stats } = action;
      return {
        ...state,
        [userId]: stats,
      };
    }
  }
  return state;
}
