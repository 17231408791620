import { all, call, fork, put, takeLatest, takeLeading } from '@redux-saga/core/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import { Unpromisify } from 'web_core_library/dist/services/httpService';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import Cache from './cacheService';
import Service from './service';
import {
  IAppReportAppliedFilter,
  IExerciseReportAppliedFilter,
  IUserFeedbackReportAppliedFilter,
  TFeedbackTabs,
  TFilters,
  TReportData,
} from './types';

export default function* watcher() {
  yield all([
    takeLeading(ActionTypes.FEEDBACK_INIT, handleInitSaga),
    takeLatest(ActionTypes.FEEDBACK_OPEN_TAB, handleTabLoading),
    takeLatest(ActionTypes.FEEDBACK_LOAD_REPORTS, handleReportsLoading),
  ]);
}

export function* handleInitSaga() {
  yield call(Service.init, ApiService);
  yield call(Cache.init);
  yield put(Actions.feedbackReadyAction());
}

export function* saveFiltersToCache(tab: TFeedbackTabs, filters: TFilters) {
  yield call(Cache.setFiltersCache, tab, filters);
}

export function* handleLoadingFeedbackReportFilters() {
  const result: TApiFetchResponse<typeof Service.getFeedbackReportFilters> = yield call(
    Service.getFeedbackReportFilters
  );
  yield call(saveFiltersToCache, 'feedback', result.data);
  yield put(Actions.updateUserFeedbackFiltersAction(result.data));
  yield put(Actions.loadingFinishedAction());
}

export function* handleLoadingAppReportFilters() {
  const result: TApiFetchResponse<typeof Service.getAppReportFilters> = yield call(Service.getAppReportFilters);
  yield call(saveFiltersToCache, 'appreports', result.data);
  yield put(Actions.updateUserFeedbackFiltersAction(result.data));
  yield put(Actions.loadingFinishedAction());
}

export function* handleLoadingExerciseReportFilters() {
  const result: TApiFetchResponse<typeof Service.getExerciseReportFilters> = yield call(
    Service.getExerciseReportFilters
  );
  yield call(saveFiltersToCache, 'exercisereports', result.data);
  yield put(Actions.updateUserFeedbackFiltersAction(result.data));
  yield put(Actions.loadingFinishedAction());
}

export function* preloadDataFromCache(tab: TFeedbackTabs) {
  const savedFilters: Unpromisify<ReturnType<typeof Cache.getFiltersFromCache>> = yield call(
    Cache.getFiltersFromCache,
    tab
  );
  if (savedFilters) {
    yield put(Actions.updateUserFeedbackFiltersAction(savedFilters));
  }
  const savedReports: Unpromisify<ReturnType<typeof Cache.getReportsFromCache>> = yield call(
    Cache.getReportsFromCache,
    tab
  );
  if (savedReports) {
    yield put(Actions.updateReportsAction(savedReports));
  }
}

export function* handleTabLoading({ tab, reload }: ActionTypes.IOpenFeedbackTabAction) {
  if (!reload) {
    // load from cache by default but not when excplicitly asked to reload
    yield call(preloadDataFromCache, tab);
  }
  switch (tab) {
    case 'feedback':
      yield fork(handleLoadingFeedbackReportFilters);
      break;
    case 'appreports':
      yield fork(handleLoadingAppReportFilters);
      break;
    case 'exercisereports':
      yield fork(handleLoadingExerciseReportFilters);
      break;
  }
}

export function* saveReportsToCache(tab: TFeedbackTabs, reports: TReportData[]) {
  yield call(Cache.setReportsCache, tab, reports);
}

export function* handleUserFeedbackReportsLoading(filters: IUserFeedbackReportAppliedFilter) {
  const result: TApiFetchResponse<typeof Service.loadFeedbackReports> = yield call(
    Service.loadFeedbackReports,
    filters
  );
  yield call(saveReportsToCache, 'feedback', result.data.reports);
  yield put(Actions.updateReportsAction(result.data.reports));
  yield put(Actions.loadingFinishedAction());
}

export function* handleAppReportsLoading(filters: IAppReportAppliedFilter) {
  const result: TApiFetchResponse<typeof Service.loadAppReports> = yield call(Service.loadAppReports, filters);
  yield call(saveReportsToCache, 'appreports', result.data.reports);
  yield put(Actions.updateReportsAction(result.data.reports));
  yield put(Actions.loadingFinishedAction());
}

export function* handleExerciseReportsLoading(filters: IExerciseReportAppliedFilter) {
  const result: TApiFetchResponse<typeof Service.loadExerciseReports> = yield call(
    Service.loadExerciseReports,
    filters
  );
  yield call(saveReportsToCache, 'exercisereports', result.data.reports);
  yield put(Actions.updateReportsAction(result.data.reports));
  yield put(Actions.loadingFinishedAction());
}

export function* handleReportsLoading({ tab, filters }: ActionTypes.ILoadReportsAction) {
  switch (tab) {
    case 'feedback':
      yield fork(handleUserFeedbackReportsLoading, filters);
      break;
    case 'appreports':
      yield fork(handleAppReportsLoading, filters);
      break;
    case 'exercisereports':
      yield fork(handleExerciseReportsLoading, filters);
      break;
  }
}
