import { CombinedState } from 'redux';
import { ICyclesState, statePropName } from './reducer';

export interface IAppState extends CombinedState<unknown> {
  // this feature state
  [statePropName]: ICyclesState;
}

export const getCyclesState = (state: IAppState) => state[statePropName];

export const isLoading = (state: IAppState) => getCyclesState(state).loading;

export const getCurrentUserId = (state: IAppState) => getCyclesState(state).userId;

export const getFinishedCycles = (state: IAppState) => getCyclesState(state).finishedCycles;

export const getFinishedSessions = (state: IAppState) => getCyclesState(state).finishedSessions;

export const getCurrentCycleId = (state: IAppState) => getCyclesState(state).currentCycleId;

export const getLastSessionId = (state: IAppState) => getCyclesState(state).currentCycleLastSessionId;

export const getCurrentCycleLength = (state: IAppState) => getCyclesState(state).currentCycleLength;

export const getCycles = (state: IAppState) => getCyclesState(state).trainingcycles;
