import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import { NotificationActions } from '../notifications';
import { reloadUserAction } from '../state/actions/details';
import * as UserActions from '../state/actions/users';
import { Role } from '../state/models/user';
import UserStats from '../state/models/userStat';
import * as StateSelectors from '../state/selectors';
import { ApiResponseAction } from '../state/types/actions';
import { NNProjects, RoleChange } from '../state/types/base';
import { ISubscriptionData, UserStatus } from '../state/types/state';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { ChangeStatusForm } from './reducer';
import * as Selectors from './selectors';

// common handler for changing a users role (premium)
export function* handleStatusChange(action: ActionTypes.IChangeStatusSubmitAction) {
  const userId = action.id;
  const project: NNProjects = yield select(Selectors.getChangeStatusDialogProject);
  const form: ChangeStatusForm = yield select(Selectors.getChangeStatusForm);
  const period = form.lifetime ? undefined : `${form.amount} ${form.period}`;
  const roles: RoleChange[] = [];
  switch (project) {
    case NNProjects.BTApp:
      roles.push({ id: Role.AppSubscriber, period });
      roles.push({ id: Role.FreeWorkouts, period });
      break;
    case NNProjects.BTAppWeb:
      roles.push({ id: Role.WebPremium, period });
      break;
    case NNProjects.EaseApp: {
      const easePremiumStatObject = UserStats.createEasePremiumStat(form.amount > 0 ? 1 : -1);
      yield put(UserActions.setUserStatsRequestAction(userId, [easePremiumStatObject]));
      const result2: ApiResponseAction = yield take([
        UserActions.USERS_SET_USER_STATS_SUCCESS,
        UserActions.USERS_SET_USER_STATS_FAIL,
      ]);
      if (result2.payload.response.isError()) {
        yield put(Actions.changeStatusFailed(userId));
        return;
      }
      roles.push({ id: Role.EasePremium, period });
      break;
    }
  }
  yield put(UserActions.patchUserRolesRequestAction(userId, roles));
  const result: ApiResponseAction = yield take([
    UserActions.USERS_PATCH_USER_ROLES_SUCCESS,
    UserActions.USERS_PATCH_USER_ROLES_FAIL,
  ]);
  if (result.payload.response.isError()) {
    yield put(Actions.changeStatusFailed(userId));
    return;
  }
  yield put(Actions.changeStatusSuccess(userId));
}

// common handler for canceling/termination of a premium/subscription
export function* handleStatusTerminate(action: ActionTypes.ITerminateStatusSubmitAction) {
  const userId = action.id;
  const project = yield select(Selectors.getChangeStatusDialogProject);
  const status: UserStatus = yield select(StateSelectors.getUserProjectStatus, userId, project);
  const isSubscription = status.isSubscription;
  const terminate = yield select(Selectors.getTerminateStatusImmediatelyValue);
  if (isSubscription) {
    let subscriptionId;
    switch (project) {
      case NNProjects.BTApp: {
        const subscription: ISubscriptionData = yield select(StateSelectors.getUserBTSubscription, userId);
        if (subscription) {
          subscriptionId = subscription.id;
        }
        break;
      }
      case NNProjects.BTAppWeb: {
        const subscription: ISubscriptionData = yield select(StateSelectors.getUserBTSubscription, userId);
        if (subscription) {
          subscriptionId = subscription.id;
        }
        break;
      }
      case NNProjects.EaseApp: {
        const subscription: ISubscriptionData = yield select(StateSelectors.getUserEaseSubscription, userId);
        if (subscription) {
          subscriptionId = subscription.id;
        }
        break;
      }
    }
    if (subscriptionId) {
      yield put(Actions.cancelSubscriptionAction(userId, subscriptionId, terminate));
    }
  } else {
    yield put(
      NotificationActions.showError(
        'Can cancel only subscriptions! To remove Role use negative amount on Change Premium form'
      )
    );
    yield put(Actions.changeStatusFailed(userId));
  }
}

export function* handleCancelSubscription(action: ActionTypes.ICancelSubscriptionAction) {
  const { id, subscriptionId, terminate } = action;
  yield put(UserActions.cancelSubscriptionRequestAction(id, subscriptionId, terminate));
  const result: ApiResponseAction = yield take([
    UserActions.USERS_CANCEL_SUBSCRIPTION_FAIL,
    UserActions.USERS_CANCEL_SUBSCRIPTION_SUCCESS,
  ]);
  if (result.payload.response.isError()) {
    yield put(Actions.changeStatusFailed(id));
    return;
  }
  yield put(Actions.changeStatusSuccess(id));
  yield put(
    NotificationActions.showSuccess(
      'Subscription was successfully cancelled/terminated. It might take some time till users data will change accordingly.'
    )
  );
}

export function* handleStatusChangeRefresh(
  action: ActionTypes.IChangeStatusSuccessAction | ActionTypes.IChangeStatusFailedAction
) {
  const userId = action.id;
  yield put(reloadUserAction(userId));
}

export function* handleStatusChangeFail() {
  yield put(NotificationActions.showError('Status change failed!'));
}

export default function* statusWatcher() {
  yield all([
    takeEvery(ActionTypes.STATUS_CHANGE_SUBMIT, handleStatusChange),
    takeEvery(ActionTypes.STATUS_TERMINATE_SUBMIT, handleStatusTerminate),
    takeEvery(ActionTypes.STATUS_CANCEL_SUBSCRIPTION, handleCancelSubscription),
    takeEvery([ActionTypes.STATUS_CHANGE_SUCCESS, ActionTypes.STATUS_CHANGE_FAIL], handleStatusChangeRefresh),
    takeEvery([ActionTypes.STATUS_CHANGE_FAIL], handleStatusChangeFail),
  ]);
}
