import { Action } from 'redux';
import { ICycleStatus } from './types';

export const CYCLES_INIT = '@CYCLES/CYCLES_INIT';
export type CYCLES_INIT_TYPE = typeof CYCLES_INIT;
export interface IInitCyclesAction extends Action<CYCLES_INIT_TYPE> {
  type: CYCLES_INIT_TYPE;
}

export const CYCLES_LOAD = '@CYCLES/CYCLES_LOAD';
export type CYCLES_LOAD_TYPE = typeof CYCLES_LOAD;
export interface ILoadCyclesAction extends Action<CYCLES_LOAD_TYPE> {
  type: CYCLES_LOAD_TYPE;
  userId: number;
}

export const CYCLES_STATS = '@CYCLES/CYCLES_STATS';
export type CYCLES_STATS_TYPE = typeof CYCLES_STATS;
export interface ICyclesStatsAction extends Action<CYCLES_STATS_TYPE> {
  type: CYCLES_STATS_TYPE;
  finishedCycles: number;
  finishedSessions: number;
  currentCycleId: number;
  currentCycleLastSessionId: number;
  currentCycleLength: number;
}

export const RESET_TRAINING = '@CYCLES/RESET_TRAINING';
export type RESET_TRAINING_TYPE = typeof RESET_TRAINING;
export interface IResetTrainingAction extends Action<RESET_TRAINING_TYPE> {
  type: RESET_TRAINING_TYPE;
}

export const LOAD_SESSION = '@CYCLES/LOAD_SESSION';
export type LOAD_SESSION_TYPE = typeof LOAD_SESSION;
export interface ILoadSessionAction extends Action<LOAD_SESSION_TYPE> {
  type: LOAD_SESSION_TYPE;
  cycleId: number;
  sessionId: number;
}

export const SESSION_UPDATE = '@CYCLES/SESSION_UPDATE';
export type SESSION_UPDATE_TYPE = typeof SESSION_UPDATE;
export interface IUpdateSessionAction extends Action<SESSION_UPDATE_TYPE> {
  type: SESSION_UPDATE_TYPE;
  cycle: ICycleStatus;
}

export type ICyclesActions =
  | IInitCyclesAction
  | ILoadCyclesAction
  | ICyclesStatsAction
  | IResetTrainingAction
  | ILoadSessionAction
  | IUpdateSessionAction;
