import { APP_GET_PRODUCTS_SUCCESS } from '../actions/products';
import { ApiResponsePayload, IAction, IBaseAction } from '../types/actions';
import { IProductNameData } from '../types/base';
import { IProductsState } from '../types/state';

export const initialState: IProductsState = {};

export default function productsReducer(state = initialState, action: IBaseAction): IProductsState {
  switch (action.type) {
    case APP_GET_PRODUCTS_SUCCESS: {
      const products = (action as IAction<ApiResponsePayload>).payload.response.data as IProductNameData;
      return {
        ...state,
        products: {
          Current: products.Current,
          Legacy: products.Legacy,
        },
      };
    }
  }
  return state;
}
