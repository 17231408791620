import { all, put, select, takeEvery } from 'redux-saga/effects';

import { searchUserByIdAction } from '../search/actions';
import { getUser } from '../state/selectors';
import * as ActionTypes from './actionTypes';

export function* loadUserHistorySaga(action: ActionTypes.ILoadUserHistoryAction) {
  const userId = action.userId;
  const user = yield select(getUser, userId);
  if (!user) {
    yield put(searchUserByIdAction(userId));
  }
}

export default function* historyWatcher() {
  yield all([takeEvery(ActionTypes.HISTORY_LOAD_USER, loadUserHistorySaga)]);
}
