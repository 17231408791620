import { FormGroup, SegmentedControl } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { getFiltersPremium } from '../../selectors';

interface IPremiumFilterProps {
  selectedValue: number | null;
  onChange: (value: number | null) => void;
}

export default function PremiumFilter({ selectedValue, onChange }: IPremiumFilterProps) {
  const availableValues = useSelector(getFiltersPremium);
  const changeHandler = (value: string) => {
    const numValue = Number(value);
    onChange(isNaN(numValue) ? null : numValue);
  };
  const options = availableValues.map((value) => ({ label: `${value < 2 ? !!value : value}`, value: `${value}` }));
  options.unshift({ label: 'None', value: 'null' });
  return (
    <FormGroup label="Premium status">
      <SegmentedControl options={options} value={`${selectedValue}`} onValueChange={changeHandler} small={true} />
    </FormGroup>
  );
}
