import { IApiService } from 'web_core_library';
import { TPossibleCouponStatus } from './types';

export function createCouponService() {
  let ApiService: IApiService;
  function getCouponStatus(coupon: string) {
    return ApiService.action<TPossibleCouponStatus>('coupons_status', {
      method: 'get',
      urlParams: {
        coupon,
      },
    });
  }
  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
    },
    getCouponStatus,
  };
  return service;
}

const CouponService = createCouponService();
export default CouponService;
