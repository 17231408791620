import { Action } from 'redux';
import { ApiResponse } from '../services/apiResponse';

export const MESSAGES_INIT = 'MESSAGES_INIT';
export type MESSAGES_INIT = typeof MESSAGES_INIT;
export interface IInitMessagesAction extends Action<MESSAGES_INIT> {
  type: MESSAGES_INIT;
  userId: number;
}

export const MESSAGES_OPEN_CONVERSATION = 'MESSAGES_OPEN_CONVERSATION';
export type MESSAGES_OPEN_CONVERSATION = typeof MESSAGES_OPEN_CONVERSATION;
export interface IOpenUserConversationAction extends Action<MESSAGES_OPEN_CONVERSATION> {
  type: MESSAGES_OPEN_CONVERSATION;
  userId?: number;
}

export const MESSAGES_ADMIN_MESSAGE_CHANGE = 'MESSAGES_ADMIN_MESSAGE_CHANGE';
export type MESSAGES_ADMIN_MESSAGE_CHANGE = typeof MESSAGES_ADMIN_MESSAGE_CHANGE;
export interface IChangeAdminMessageAction extends Action<MESSAGES_ADMIN_MESSAGE_CHANGE> {
  type: MESSAGES_ADMIN_MESSAGE_CHANGE;
  value: string;
}

export const MESSAGES_ADMIN_MESSAGE_SEND = 'MESSAGES_ADMIN_MESSAGE_SEND';
export type MESSAGES_ADMIN_MESSAGE_SEND = typeof MESSAGES_ADMIN_MESSAGE_SEND;
export interface ISendAdminMessageAction extends Action<MESSAGES_ADMIN_MESSAGE_SEND> {
  type: MESSAGES_ADMIN_MESSAGE_SEND;
}

export const MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS = 'MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS';
export type MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS = typeof MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS;
export interface ISendAdminMessageSuccessAction extends Action<MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS> {
  type: MESSAGES_ADMIN_MESSAGE_SEND_SUCCESS;
  response: ApiResponse;
}

export const MESSAGES_ADMIN_MESSAGE_SEND_FAIL = 'MESSAGES_ADMIN_MESSAGE_SEND_FAIL';
export type MESSAGES_ADMIN_MESSAGE_SEND_FAIL = typeof MESSAGES_ADMIN_MESSAGE_SEND_FAIL;
export interface ISendAdminMessageFailAction extends Action<MESSAGES_ADMIN_MESSAGE_SEND_FAIL> {
  type: MESSAGES_ADMIN_MESSAGE_SEND_FAIL;
  response: ApiResponse;
}

export type IMessagesActions =
  | IInitMessagesAction
  | IOpenUserConversationAction
  | IChangeAdminMessageAction
  | ISendAdminMessageAction
  | ISendAdminMessageSuccessAction
  | ISendAdminMessageFailAction;
