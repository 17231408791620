import { ActionCreator } from 'redux';

import { NNProjects } from '../state/types/base';
import * as Types from './actionTypes';
import { IntervalPeriod } from './reducer';

// toggle change project status (change users role) dialog
export const changeStatusDialogToggle: ActionCreator<Types.IChangeStatusToggleAction> = (project?: NNProjects) => ({
  type: Types.STATUS_CHANGE_DIALOG_TOGGLE,
  project,
});

// toggle lifetime checkbox
export const changeStatusLifetimeToggle: ActionCreator<Types.IChangeStatusLifetimeToggleAction> = () => ({
  type: Types.STATUS_CHANGE_LIFETIME_TOGGLE,
});

// change amount of periods for status change form
export const changeStatusAmountChange: ActionCreator<Types.IChangeStatusAmountAction> = (value: number) => ({
  type: Types.STATUS_CHANGE_AMOUNT_CHANGE,
  value,
});

// change period for status change form
export const changeStatusPeriodChange: ActionCreator<Types.IChangeStatusPeriodAction> = (value: IntervalPeriod) => ({
  type: Types.STATUS_CHANGE_PERIOD_CHANGE,
  value,
});

// submit status change form
export const changeStatusFormSubmit: ActionCreator<Types.IChangeStatusSubmitAction> = (id: number) => ({
  type: Types.STATUS_CHANGE_SUBMIT,
  id,
});

// status change process failed
export const changeStatusFailed: ActionCreator<Types.IChangeStatusFailedAction> = (id: number) => ({
  type: Types.STATUS_CHANGE_FAIL,
  id,
});

// status change process succeeded
export const changeStatusSuccess: ActionCreator<Types.IChangeStatusSuccessAction> = (id: number) => ({
  type: Types.STATUS_CHANGE_SUCCESS,
  id,
});

// toggle cancel/terminate project status (subscription) dialog
export const terminateStatusDialogToggle: ActionCreator<Types.ITerminateStatusToggleAction> = (
  project?: NNProjects
) => ({
  type: Types.STATUS_TERMINATE_DIALOG_TOGGLE,
  project,
});

// submit terminate status form
export const terminateStatusSubmit: ActionCreator<Types.ITerminateStatusSubmitAction> = (id: number) => ({
  type: Types.STATUS_TERMINATE_SUBMIT,
  id,
});

// terminate status process form immediately checkbox toggle
export const terminateStatusImmediatelyUpdate: ActionCreator<Types.ITerminateStatusImmediatelyUpdateAction> = (
  id: number
) => ({
  type: Types.STATUS_TERMINATE_IMMEDIATELY_UPDATE,
  id,
});

// cancel users subscription action
export const cancelSubscriptionAction: ActionCreator<Types.ICancelSubscriptionAction> = (
  id: number,
  subscriptionId: number,
  terminate: boolean
) => ({
  type: Types.STATUS_CANCEL_SUBSCRIPTION,
  id,
  subscriptionId,
  terminate,
});
