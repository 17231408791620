export interface ITrainingStatistics {
  currentCycleId?: number | null;
  currentCycleLastSessionId?: number | null;
  currentCycleLength?: number;
  finishedCycles: number;
  finishedSessions: number;
  numberAssessments: number;
  numberMigrated: number;
  numberWorkouts: number;
}

export interface ITrainingStatisticsApiResponse {
  trainingstatistics: ITrainingStatistics;
}

export enum ETrainingStatus {
  None = 0,
  Ongoing = 1,
  Skipped = 99,
  Finished = 100,
}

export interface IWorkoutStatus {
  workoutId: number;
  index: number;
  finishedTimestamp: number;
  status: ETrainingStatus;
}

export interface ISessionStatus {
  sessionId: number;
  domainId: number;
  finishedTimestamp: number;
  status: ETrainingStatus;
  workouts: IWorkoutStatus[];
}

export interface ICycleStatus {
  trainingId: number;
  cycleId: number;
  startTimestamp: number;
  finishedTimestamp: number;
  sessions: ISessionStatus[];
}

export interface ICycleStatusApiResponse {
  filter: any;
  trainingcycles: ICycleStatus[];
}
