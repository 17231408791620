import { Spinner, SpinnerSize } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { AuthSelectors } from '../auth';
import { AppState } from '../state/reducers';
import { getUser, isStarted } from '../state/selectors';
import { IExtendedUser } from '../state/types/state';
import { loadUserHistoryAction } from './actions';
import UserHistory from './UserHistory';

export interface OwnProps {
  userId: number;
}

export interface Props extends OwnProps {
  started: boolean;
  isAdmin: boolean;
  loaded: boolean;
  onLoad: (userId: number) => void;
}

export function UserHistoryContainer({ started, isAdmin, loaded, userId, onLoad }: Props) {
  React.useEffect(() => {
    if (userId && !loaded) {
      onLoad(userId);
    }
  }, [loaded, userId, onLoad]);
  if (!isAdmin) {
    return null;
  }
  if (!started || !loaded) {
    return <Spinner size={SpinnerSize.LARGE} />;
  }
  return <UserHistory userId={userId} />;
}

export const mapStateToProps = (state: AppState, props: OwnProps) => {
  const started = isStarted(state);
  const isAdmin = AuthSelectors.isUserAdmin(state);
  const selectedUser = getUser(state, props.userId);
  const loaded = !!selectedUser && !!selectedUser.id && !!(selectedUser as IExtendedUser).mail;
  return {
    started,
    isAdmin,
    loaded,
  };
};

export const mapDispatchToProps = {
  onLoad: loadUserHistoryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHistoryContainer);
