import Status from './models/status';
import Subscription from './models/subscription';
import { AppState } from './reducers';
import { NNProjects } from './types/base';
import { IExtendedUser, IUserSubscriptions } from './types/state';

export const isStarted = (state: AppState) => state.started;

export const getTheme = (state: AppState) => state.theme;

export const getUsersList = (state: AppState) => state.users;

export const getUser = (state: AppState, userId: number) => getUsersList(state)[userId];

export const getUserSubscriptions = (state: AppState, userId: number) => {
  const user = getUser(state, userId) as IUserSubscriptions;
  return user ? user.subscriptions : [];
};

export const getUserRoles = (state: AppState, userId: number) => {
  const user = getUser(state, userId) as IExtendedUser;
  const roles = user ? user.roles : [];
  return roles || [];
};

export const getUserEaseSubscription = (state: AppState, userId: number) =>
  Subscription.getEaseSubscription(getUserSubscriptions(state, userId));

export const getUserBTSubscription = (state: AppState, userId: number) =>
  Subscription.getBTSubscription(getUserSubscriptions(state, userId));

export const getUserSubscription = (state: AppState, userId: number, subscriptionId: string | null) =>
  Subscription.getSubscriptionById(subscriptionId, getUserSubscriptions(state, userId));

export const getUserDetailsState = (state: AppState) => state.details;

export const getUserDetails = (state: AppState, userId: number) => getUserDetailsState(state)[userId];

export const getUserProjectStatus = (state: AppState, userId: number, project: NNProjects) => {
  const details = getUserDetails(state, userId);
  return details ? details.statuses[project] : Status.getEmptyStatus(project);
};

export const getProductsState = (state: AppState) => state.products.products;

export const getProductTitle = (state: AppState, productId: string | number, legacy: boolean) => {
  const products = getProductsState(state);
  if (!products) {
    return '';
  }
  const branch = legacy ? products.Legacy : products.Current;
  return branch[+productId];
};

export const getAdminState = (state: AppState) => state.admin;
