import { UserStat } from '../types/base';

function createUserStat(
  statType: number,
  statId: number,
  statComponentId: number,
  valueInt: number = -1,
  valueTxt: string = '',
  operator: string = 'UPD'
) {
  const stat: UserStat = {
    statType,
    statId,
    statComponentId,
    valueInt,
    valueTxt,
    operator,
    time: -1,
  };
  return stat;
}

function createIntStat(statType: number, statId: number, statComponentId: number, value: number) {
  return createUserStat(statType, statId, statComponentId, value);
}

function createEasePremiumStat(value: number) {
  return createIntStat(1005, 0, 0, value);
}

const UserStats = {
  createEasePremiumStat,
};

export default UserStats;
