import * as UserActionsTypes from './actionTypes';

export interface UserActionsStatus {
  // newsletter flag
  newsletter: boolean;
  // loading newsletter state flag
  newsletterLoading: boolean;
  // base localized opxp url
  opxpUrl?: string;
  // base localized checkout url
  checkoutUrl?: string;
  // session token
  session?: string;
  // user has active subscription
  isActive: boolean;
}

export function getEmptyStatus() {
  return {
    newsletter: false,
    newsletterLoading: false,
    isActive: true,
  } as UserActionsStatus;
}
export interface UserActionsState {
  [userId: number]: UserActionsStatus;
  products: number[];
}

export const initialState: UserActionsState = {
  products: [],
};

export default function userActions(state = initialState, action: UserActionsTypes.UserActionsActions) {
  switch (action.type) {
    case UserActionsTypes.ACTIONS_GET_USER_NEWSLETTER:
    case UserActionsTypes.ACTIONS_TOGGLE_USER_NEWSLETTER: {
      const userId = action.userId;
      const userState = state[userId] || getEmptyStatus();
      return {
        ...state,
        [userId]: {
          ...userState,
          newsletterLoading: true,
        },
      };
    }
    case UserActionsTypes.ACTIONS_USER_NEWSLETTER_UPDATE: {
      const { userId, newsletter } = action as UserActionsTypes.IUpdateUserNewsletterAction;
      const userState = state[userId] || getEmptyStatus();
      return {
        ...state,
        [userId]: {
          ...userState,
          newsletter,
          newsletterLoading: false,
        },
      };
    }
    case UserActionsTypes.ACTIONS_USER_ACTIVE_UPDATE: {
      const { userId, isActive } = action as UserActionsTypes.IUpdateUserActiveAction;
      const userState = state[userId] || getEmptyStatus();
      return {
        ...state,
        [userId]: {
          ...userState,
          isActive,
        },
      };
    }
    case UserActionsTypes.ACTIONS_URL_UPDATE: {
      const { userId, opxpUrl, checkoutUrl } = action;
      const userState = state[userId] || getEmptyStatus();
      return {
        ...state,
        [userId]: {
          ...userState,
          opxpUrl,
          checkoutUrl,
        },
      };
    }
    case UserActionsTypes.ACTIONS_USER_SESSION_UPDATE: {
      const { userId, session } = action;
      const userState = state[userId] || getEmptyStatus();
      return {
        ...state,
        [userId]: {
          ...userState,
          session,
        },
      };
    }
    case UserActionsTypes.ACTIONS_UPDATE_PRODUCTS: {
      const products = action.products;
      return {
        ...state,
        products,
      };
    }
  }
  return state;
}
