import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersLastGameplay } from '../../selectors';

interface ILastGameplayFilterProps {
  selectedExercises: string[];
  onChange: (exercises: string[]) => void;
}

export default function LastGameplayFilter({ onChange, selectedExercises }: ILastGameplayFilterProps) {
  const availableExercises = useSelector(getFiltersLastGameplay);
  return (
    <StringListMultiSelector
      items={availableExercises}
      label="Last gameplay"
      onChange={onChange}
      selectedItems={selectedExercises}
    />
  );
}
