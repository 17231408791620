import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersOsVersions } from '../../selectors';

interface IOsVersionFilterProps {
  selectedOsVersions: string[];
  onChange: (osVersion: string[]) => void;
}

export default function OsVersionFilter({ onChange, selectedOsVersions }: IOsVersionFilterProps) {
  const availableOsVersions = useSelector(getFiltersOsVersions);
  return (
    <StringListMultiSelector
      items={availableOsVersions}
      label="OS version"
      onChange={onChange}
      selectedItems={selectedOsVersions}
    />
  );
}
