import { Alignment, Button, Card, Classes, Intent, Navbar, Spinner, SpinnerSize } from '@blueprintjs/core';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Actions from './actions';
import './CyclesWrapper.scss';
import CycleTree from './CycleTree';
import * as Selectors from './selectors';

export interface IOwnProps {
  userId: number;
}

export interface ICyclesWrapperProps extends IOwnProps {
  loading: boolean;
  finishedCycles: number;
  finishedSessions: number;
  currentCycleId: number;
  currentCycleLength: number;
  onOpen: (userId: number) => void;
  onResetTraining: () => void;
}

export const CyclesWrapperFC: FC<ICyclesWrapperProps> = ({
  userId,
  loading,
  finishedCycles,
  finishedSessions,
  currentCycleId,
  currentCycleLength,
  onOpen,
  onResetTraining,
}) => {
  const [uId, setUserId] = useState(0);
  useEffect(() => {
    if (!userId || userId === uId) {
      return;
    }
    onOpen(userId);
    setUserId(userId);
  }, [userId, uId, setUserId, onOpen]);
  if (loading) {
    return <Spinner intent={Intent.PRIMARY} size={SpinnerSize.LARGE} />;
  }
  const { HTML_TABLE, HTML_TABLE_STRIPED } = Classes;
  const tableClasses = `cycles-stats-table ${HTML_TABLE} ${HTML_TABLE_STRIPED}`;
  return (
    <div>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>Training statistics:</Navbar.Heading>
          <Navbar.Divider />
          <Button minimal={true} onClick={onResetTraining} intent={Intent.DANGER} icon="reset" text="Reset progress" />
        </Navbar.Group>
      </Navbar>
      <div className="cycles-info">
        <Card className="cycles-stats">
          <table className={tableClasses}>
            <thead>
              <tr>
                <th colSpan={2}>Current:</th>
                <th colSpan={2}>Finished:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Current cycle Id:</td>
                <td>{currentCycleId}</td>
                <td>Finished cycles:</td>
                <td>{finishedCycles}</td>
              </tr>
              <tr>
                <td>Current cycle length:</td>
                <td>{currentCycleLength}</td>
                <td>Finished sessions:</td>
                <td>{finishedSessions}</td>
              </tr>
            </tbody>
          </table>
        </Card>
        <Card className="cycles-details">
          <h5 className={Classes.HEADING}>Details:</h5>
          <CycleTree />
        </Card>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: Selectors.IAppState) => {
  const loading = Selectors.isLoading(state);
  const finishedCycles = Selectors.getFinishedCycles(state);
  const finishedSessions = Selectors.getCurrentCycleId(state);
  const currentCycleId = Selectors.getCurrentCycleId(state);
  const currentCycleLength = Selectors.getCurrentCycleLength(state);
  return {
    loading,
    finishedCycles,
    finishedSessions,
    currentCycleId,
    currentCycleLength,
  };
};

export const mapDispatchToProps = {
  onOpen: Actions.loadCycles,
  onResetTraining: Actions.resetTraining,
};

const CyclesWrapper = connect(mapStateToProps, mapDispatchToProps)(CyclesWrapperFC);
export default CyclesWrapper;
