import { combineReducers } from 'redux';
import { AuthReducer } from '../../auth';
import { CouponReducer } from '../../coupon';
import { CyclesReducer } from '../../cycles';
import { EmailsReducer } from '../../emailHistory';
import { FeedbackReducer } from '../../feedbackConsole';
import messages, { initialState as messagesState } from '../../messages/reducer';
import orders, { initialState as ordersState } from '../../orders/reducer';
import search, { initialState as searchState } from '../../search/reducer';
import status, { initialState as statusState } from '../../status/reducer';
import { ThemeReducer } from '../../theme';
import userActions, { initialState as userActionsState } from '../../userActions/reducer';
import { StatsReducer } from '../../userStats';
import admin, { initialState as adminState } from './admin';
import details, { initialState as detailsState } from './details';
import products, { initialState as productsState } from './products';
import started, { initialState as startedState } from './started';
import users, { initialState as usersState } from './users';

const combinedReducer = combineReducers({
  admin,
  [AuthReducer.statePropName]: AuthReducer.default,
  details,
  orders,
  products,
  search,
  started,
  status,
  [ThemeReducer.statePropName]: ThemeReducer.default,
  users,
  userActions,
  messages,
  userStats: StatsReducer.default,
  [EmailsReducer.statePropName]: EmailsReducer.default,
  [CyclesReducer.statePropName]: CyclesReducer.default,
  [CouponReducer.statePropName]: CouponReducer.default,
  [FeedbackReducer.statePropName]: FeedbackReducer.default,
});

export type AppState = ReturnType<typeof combinedReducer>;

export const initialState: AppState = {
  admin: adminState,
  [AuthReducer.statePropName]: AuthReducer.initialState,
  details: detailsState,
  search: searchState,
  started: startedState,
  status: statusState,
  [ThemeReducer.statePropName]: ThemeReducer.initialState,
  users: usersState,
  products: productsState,
  userActions: userActionsState,
  orders: ordersState,
  messages: messagesState,
  userStats: StatsReducer.initialState,
  [EmailsReducer.statePropName]: EmailsReducer.initialState,
  [CyclesReducer.statePropName]: CyclesReducer.initialState,
  [CouponReducer.statePropName]: CouponReducer.initialState,
  [FeedbackReducer.statePropName]: FeedbackReducer.initialState,
};

export default combinedReducer;
