import { Button, Callout } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './actions';
import { SESSION_EXPIRATION_THRESHOLD_MIN } from './constants';
import * as Selectors from './selectors';
import './SessionExpirationWarning.scss';
import { getMinutesLeft } from './sessionHelper';

export default function SessionExpirationWarning() {
  const dispatch = useDispatch();
  const loginHandler = () => dispatch(Actions.loginAction());
  const [minutesToExpire, setMinutesToExpire] = useState(999);
  const expires = useSelector(Selectors.getExpiration);

  const updateMinutesToExpire = useCallback(() => {
    if (!expires) {
      return;
    }
    const minutesLeft = getMinutesLeft(expires);
    setMinutesToExpire(minutesLeft);
    // toggle redirect if token expired while component is rendered
    // for example if user is afk
    if (minutesLeft < 0) {
      loginHandler();
    }
  }, [expires, setMinutesToExpire]);

  // set initial value for expiration
  useEffect(updateMinutesToExpire, []);

  // set timer to update value each half minute
  useEffect(() => {
    const timer = window.setInterval(updateMinutesToExpire, 30000);
    return () => window.clearInterval(timer);
  }, []);

  if (minutesToExpire > SESSION_EXPIRATION_THRESHOLD_MIN) {
    return null;
  }
  return (
    <Callout compact={true} intent="warning" className="session-expiration-warning">
      <p>
        Your session will expire in <strong>{minutesToExpire} minutes</strong>! Please consider relogin when you
        can:&nbsp;
        <Button small={true} intent="warning" rightIcon="log-out" text="relogin" onClick={loginHandler} />
      </p>
    </Callout>
  );
}
