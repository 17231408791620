import { APP_STARTED, TAppActions } from '../actions';

export const initialState = false;

/**
 * Reducer switching started flag when user restore action fired for success or failure
 */
export default function started(state = initialState, action: TAppActions) {
  switch (action.type) {
    case APP_STARTED:
      return true;
    default:
      return state;
  }
}
