import { Action } from 'redux';
import { Themes } from './types';

export const THEME_RESTORE = 'THEME_RESTORE';
export type THEME_RESTORE_TYPE = typeof THEME_RESTORE;
export interface IRestoreThemeAction extends Action<THEME_RESTORE_TYPE> {
  type: THEME_RESTORE_TYPE;
}

export const THEME_UPDATE = 'THEME_UPDATE';
export type THEME_UPDATE_TYPE = typeof THEME_UPDATE;
export interface IUpdateThemeAction extends Action<THEME_UPDATE_TYPE> {
  type: THEME_UPDATE_TYPE;
  theme: Themes;
}

export type TThemeActions = IRestoreThemeAction | IUpdateThemeAction;
