import find from 'lodash/find';
import map from 'lodash/map';
import moment from 'moment';
import { NNProjects } from '../types/base';
import { IExtendedUser } from '../types/state';

export enum Role {
  Moderator = 4,
  NotVerified = 9,
  WebPremium = 12,
  GroupManager = 19,
  AppSubscriber = 26,
  FreeWorkouts = 32,
  EasePremium = 38,
  Brain = 40,
  Jena = 41,
  BrainConcussion = 42,
  GroupAdmin = 50,
}

export const getRoles = (user: IExtendedUser) =>
  user.roles
    ? map(user.roles, (role) => {
        const { id, name, expiresAt } = role;
        return {
          expiresAt: expiresAt ? moment(expiresAt) : undefined,
          id,
          name,
        };
      })
    : [];

const getRole = (user: IExtendedUser, role: Role) => {
  if (user.userId) {
    return find(getRoles(user), ['id', role]);
  }
  return null;
};

const hasRole = (user: IExtendedUser, role: Role) => {
  return !!getRole(user, role);
};

const hasBTPremium = (user: IExtendedUser) => {
  return hasRole(user, Role.WebPremium);
};

const hasEasePremium = (user: IExtendedUser) => {
  return hasRole(user, Role.EasePremium);
};

const hasBTAppPremium = (user: IExtendedUser) => {
  return hasRole(user, Role.AppSubscriber) || hasRole(user, Role.FreeWorkouts);
};

const hasPremium = (user: IExtendedUser, project: NNProjects) => {
  switch (project) {
    case NNProjects.BTApp:
      return hasBTAppPremium(user);
    case NNProjects.BTAppWeb:
      return hasBTPremium(user);
    case NNProjects.EaseApp:
      return hasEasePremium(user);
  }
};

const getRoleExpiration = (user: IExtendedUser, project: NNProjects) => {
  let expiration: moment.Moment | undefined;
  switch (project) {
    case NNProjects.BTApp: {
      const appSubscriberRole = getRole(user, Role.AppSubscriber);
      if (appSubscriberRole) {
        expiration = appSubscriberRole.expiresAt;
      }
      const workoutsRole = getRole(user, Role.FreeWorkouts);
      if (workoutsRole) {
        expiration = workoutsRole.expiresAt;
      }
      break;
    }
    case NNProjects.BTAppWeb: {
      const btRole = getRole(user, Role.WebPremium);
      if (btRole) {
        expiration = btRole.expiresAt;
      }
      break;
    }
    case NNProjects.EaseApp: {
      const easeRole = getRole(user, Role.EasePremium);
      if (easeRole) {
        expiration = easeRole.expiresAt;
      }
      break;
    }
  }
  return expiration;
};

const User = {
  getRoleExpiration,
  hasBTAppPremium,
  hasBTPremium,
  hasEasePremium,
  hasPremium,
};

export default User;
