import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadReportsAction } from '../actions';
import AppVersionFilter from '../components/filters/AppVersionFilter';
import CountryFilter from '../components/filters/CountryFilter';
import DateRangeFilter from '../components/filters/DateRangeFilter';
import LanguagesFilter from '../components/filters/LanguagesFilter';
import LastGameplayFilter from '../components/filters/LastGameplayFilter';
import OsNameFilter from '../components/filters/OsNameFilter';
import OsVersionFilter from '../components/filters/OsVersionFilter';
import PremiumFilter from '../components/filters/PremiumFilter';
import FiltersSection from '../components/sections/FiltersSection';
import { DateRange, IAppReportAppliedFilter } from '../types';
import { formatTimestampFrom, formatTimestampTo } from '../utils';

export default function AppReportsFilters() {
  const [userId, setUserId] = useState<string>();
  const userIdChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserId(event.target.value || undefined);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedOsNames, setSelectedOsNames] = useState<string[]>([]);
  const [selectedOsVersions, setSelectedOsVersions] = useState<string[]>([]);
  const [selectedPremium, selectPremium] = useState<number | null>(null);
  const [appRange, setAppRange] = useState<[string | undefined, string | undefined]>([undefined, undefined]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [selectedExercises, setSelectedExercises] = useState<string[]>([]);

  const dispatch = useDispatch();
  const loadHandler = () => {
    const appliedfilters: IAppReportAppliedFilter = {};
    if (dateRange[0]) {
      appliedfilters.timeStampFrom = formatTimestampFrom(dateRange[0]);
    }
    if (dateRange[1]) {
      appliedfilters.timeStampTo = formatTimestampTo(dateRange[1]);
    }
    if (userId) {
      appliedfilters.uid = Number(userId);
    }
    if (selectedLanguages.length) {
      appliedfilters.language = selectedLanguages;
    }
    if (selectedOsNames.length) {
      appliedfilters.osName = selectedOsNames;
    }
    if (selectedOsVersions.length) {
      appliedfilters.osVersion = selectedOsVersions;
    }
    if (selectedPremium !== null) {
      appliedfilters.isPremium = selectedPremium;
    }
    if (appRange[0]) {
      appliedfilters.appVersionFrom = appRange[0];
    }
    if (appRange[1]) {
      appliedfilters.appVersionTo = appRange[1];
    }
    if (selectedCountries.length) {
      appliedfilters.country = selectedCountries;
    }
    if (selectedExercises.length) {
      appliedfilters.lastGameplay = selectedExercises;
    }
    dispatch(loadReportsAction('appreports', appliedfilters));
  };
  return (
    <FiltersSection tab="appreports">
      <div className="section-row">
        <FormGroup label="User ID" labelFor="uid-input">
          <InputGroup id="uid-input" onChange={userIdChangeHandler} value={userId} placeholder="" />
        </FormGroup>
        <PremiumFilter selectedValue={selectedPremium} onChange={selectPremium} />
        <LanguagesFilter selectedLanguages={selectedLanguages} onChange={setSelectedLanguages} />
        <CountryFilter selectedCountries={selectedCountries} onChange={setSelectedCountries} />
      </div>
      <div className="section-row">
        <LastGameplayFilter selectedExercises={selectedExercises} onChange={setSelectedExercises} />
      </div>
      <div className="section-row">
        <OsNameFilter selectedOsNames={selectedOsNames} onChange={setSelectedOsNames} />
        <OsVersionFilter selectedOsVersions={selectedOsVersions} onChange={setSelectedOsVersions} />
        <AppVersionFilter appRange={appRange} onChange={setAppRange} />
      </div>
      <div className="section-row">
        <DateRangeFilter dateRange={dateRange} onChange={setDateRange} />
      </div>
      <div className="section-row">
        <FormGroup>
          <Button intent="primary" icon="search-template" onClick={loadHandler}>
            load reports
          </Button>
        </FormGroup>
      </div>
    </FiltersSection>
  );
}
