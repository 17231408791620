import map from 'lodash/map';
import without from 'lodash/without';
import * as SearchActionTypes from './actionTypes';
import { USERS_LIST_PAGE_SIZE } from './constants';

// search form state
export interface UserSearchForm {
  email?: string;
  userId?: number;
  name?: string;
  order?: string;
}

// state of current search
export interface UserSearchResult {
  // search form state, null if not used yet
  form: UserSearchForm | null;
  // list of user ids to represent current sorting of received data
  list: number[];
  // if form was submitted before
  started: boolean;
  // running request flag
  loading: boolean;
  // id of user being selected
  selectedUser: number;
  // total number of found users (can be used for pagination)
  total: number;
}

export const initialState: UserSearchResult = {
  form: null,
  list: [],
  started: false,
  loading: false,
  selectedUser: 0,
  total: 0,
};

export const emtpyForm: UserSearchForm = {
  email: undefined,
  userId: undefined,
  name: undefined,
  order: undefined,
};

export default function search(state = initialState, action: SearchActionTypes.ISearchActions) {
  switch (action.type) {
    case SearchActionTypes.SEARCH_FORM_NAME_UPDATE: {
      const value = action.value;
      const name = String(value);
      return {
        ...state,
        form: {
          ...emtpyForm,
          name,
        },
      };
    }
    case SearchActionTypes.SEARCH_FORM_USERNAME_UPDATE: {
      const value = action.value;
      const email = String(value);
      return {
        ...state,
        form: {
          ...emtpyForm,
          email,
        },
      };
    }
    case SearchActionTypes.SEARCH_FORM_USERID_UPDATE: {
      const value = action.value;
      const userId = Number(value);
      return {
        ...state,
        form: {
          ...emtpyForm,
          userId,
        },
      };
    }
    case SearchActionTypes.SEARCH_FORM_ORDER_UPDATE: {
      const value = action.value;
      const order = String(value);
      return {
        ...state,
        form: {
          ...emtpyForm,
          order,
        },
      };
    }
    case SearchActionTypes.USERS_SEARCH_USERNAME:
    case SearchActionTypes.USERS_SEARCH_ID:
    case SearchActionTypes.USERS_SEARCH_NAME:
    case SearchActionTypes.USERS_SEARCH_ORDER: {
      return {
        ...state,
        loading: true,
        started: true,
      };
    }
    case SearchActionTypes.USERS_SEARCH_RESULTS: {
      const usersList = action.users;
      const list = map(usersList, 'id').slice(0, USERS_LIST_PAGE_SIZE - 1);
      const selectedUser = 0;
      const total = usersList.length;
      return {
        ...state,
        list,
        loading: false,
        selectedUser,
        total,
      };
    }
    case SearchActionTypes.USERS_SELECT_USER:
      return {
        ...state,
        selectedUser: action.id,
      };
    case SearchActionTypes.USERS_DESELECT_USER:
      return {
        ...state,
        selectedUser: 0,
      };
    case SearchActionTypes.USERS_SEARCH_ID_NOTFOUND: {
      const userId = action.id;
      // remove failed user from list
      const list = without(state.list, userId);
      return {
        ...state,
        list,
      };
    }
    case SearchActionTypes.USERS_REMOVE_USER: {
      const userId = action.id;
      // remove user from list
      const list = without(state.list, userId);
      return {
        ...state,
        list,
        // deselect user
        selectedUser: 0,
      };
    }
    default:
      return state;
  }
}
