import { IApiService } from 'web_core_library';
import { IUserStatPost } from './types';

export function createStatsService() {
  let ApiService: IApiService;
  let _ready = false;

  // api to get user stats
  function getStats(userId: number) {
    return ApiService.action('users_stats', {
      method: 'get',
      urlParams: {
        userId,
      },
    });
  }

  // api to save user stats
  function saveStats(userId: number, statsData: IUserStatPost[]) {
    return ApiService.action('users_stats', {
      method: 'patch',
      urlParams: {
        userId,
      },
      data: statsData,
    });
  }

  function isReady() {
    return _ready;
  }

  const service = {
    init: (ApiServiceInstance: IApiService) => {
      ApiService = ApiServiceInstance;
      _ready = true;
    },
    isReady,
    getStats,
    saveStats,
  };
  return service;
}

const UserStatsService = createStatsService();
export default UserStatsService;
