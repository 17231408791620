import 'url-polyfill';

export const UrlParameters = {
  // url where to return after login
  BackUrl: 'backUrl',
  // session token parameter
  SessionToken: 'session',
  // access token parameter
  AccessToken: 'signin',
};

export function createUrlManager(customUrl?: string) {
  const url = new URL(customUrl || window.location.href);
  function getUrl() {
    return url.href;
  }
  function getSearchParam(param: string) {
    console.log('searchParams', url.searchParams);
    return url.searchParams.get(param);
  }
  function setSearchParam(param: string, value: string) {
    return url.searchParams.set(param, value);
  }
  function deleteSearchParam(param: string) {
    return url.searchParams.delete(param);
  }
  function getSessionToken() {
    return getSearchParam(UrlParameters.SessionToken);
  }
  function deleteSessionToken() {
    deleteSearchParam(UrlParameters.SessionToken);
    return window.location.replace(getUrl());
  }
  function getAccessToken() {
    return getSearchParam(UrlParameters.AccessToken);
  }
  return {
    getSearchParam,
    setSearchParam,
    deleteSearchParam,
    getSessionToken,
    deleteSessionToken,
    getAccessToken,
  };
}

const urlManager = createUrlManager();
export default urlManager;

const authUrl = process.env.REACT_APP_AUTH_URL;

function redirectSSO(path: string) {
  if (!authUrl) {
    throw new Error('SSO url not set in environment!');
  }
  window.location.assign(`${authUrl}${path}`);
}

export function redirectLogin() {
  const thisUrl = encodeURIComponent(window.location.toString());
  redirectSSO(`/opxp/login/?backUrl=${thisUrl}`);
}

export function redirectLogout() {
  const thisUrl = encodeURIComponent(window.location.toString());
  redirectSSO(`/?backUrl=${thisUrl}&logout=1`);
}
