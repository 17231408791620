import { Action } from 'redux';
import { IUserStatGet, IUserStatPost } from './types';

export const USER_STATS_UPDATE = 'USER_STATS_UPDATE';
export type USER_STATS_UPDATE = typeof USER_STATS_UPDATE;
export interface IUpdateUserStatsAction extends Action<USER_STATS_UPDATE> {
  type: USER_STATS_UPDATE;
  userId: number;
  stats: IUserStatGet[];
}

export const USER_STATS_SAVE = 'USER_STATS_SAVE';
export type USER_STATS_SAVE = typeof USER_STATS_SAVE;
export interface ISaveUserStatsAction extends Action<USER_STATS_SAVE> {
  type: USER_STATS_SAVE;
  userId: number;
  stats: IUserStatPost[];
}

export const USER_STATS_LOAD = 'USER_STATS_LOAD';
export type USER_STATS_LOAD = typeof USER_STATS_LOAD;
export interface ILoadUserStatsAction extends Action<USER_STATS_LOAD> {
  type: USER_STATS_LOAD;
  userId: number;
}

export type IUserStatsAction = IUpdateUserStatsAction | ISaveUserStatsAction | ILoadUserStatsAction;
