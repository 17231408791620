import { useSelector } from 'react-redux';
import StringListMultiSelector from '../../../components/selectors/StringListSelector';
import { getFiltersOsNames } from '../../selectors';

interface IOsNameFilterProps {
  selectedOsNames: string[];
  onChange: (osName: string[]) => void;
}

export default function OsNameFilter({ onChange, selectedOsNames }: IOsNameFilterProps) {
  const availableOsNames = useSelector(getFiltersOsNames);
  return (
    <StringListMultiSelector
      items={availableOsNames}
      label="OS name"
      onChange={onChange}
      selectedItems={selectedOsNames}
    />
  );
}
