import { BlueprintProvider } from '@blueprintjs/core';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.scss';
import registerServiceWorker from './registerServiceWorker';
import Root from './Root';
import { startAppAction } from './state/actions';
import getStore from './state/store';

const store = getStore();
store.dispatch(startAppAction());

const container = document.getElementById('root');
if (!container) {
  throw new Error('Application root not found!');
}
const root = createRoot(container);
root.render(
  <BlueprintProvider>
    <Provider store={store}>
      <Root />
    </Provider>
  </BlueprintProvider>
);
registerServiceWorker();
