import { NonIdealState, Section, SectionCard } from '@blueprintjs/core';
import { PropsWithChildren, ReactNode } from 'react';

interface IReportsSectionProps extends PropsWithChildren {
  length?: number;
}

export default function ReportsSection({ children, length }: IReportsSectionProps) {
  let sectionTitle = 'Reports';
  let result: ReactNode = (
    <NonIdealState
      layout="horizontal"
      title="No reports loaded"
      description="Reports were not loaded yet. Try to set up filters and click the 'load reports' button above."
      icon="list-columns"
    />
  );
  if (typeof length !== 'undefined') {
    if (length) {
      sectionTitle = `Reports (total: ${length})`;
      result = children;
    } else {
      result = (
        <NonIdealState
          layout="horizontal"
          title="No reports found"
          description="No reports were found matching your filters. Try to change filters and click the 'load reports' button again."
          icon="search"
        />
      );
    }
  }
  return (
    <Section className="reports" title={sectionTitle} icon="database">
      <SectionCard className="reports-contents">{result}</SectionCard>
    </Section>
  );
}
