import UserFeedbackFilters from './UserFeedbackFilters';
import UserFeedbackReports from './UserFeedbackReports';

export default function UserFeedbackTab() {
  return (
    <div className="tab-contents">
      <UserFeedbackFilters />
      <UserFeedbackReports />
    </div>
  );
}
