import * as SearchActionTypes from '../../search/actionTypes';
import * as StatusActionTypes from '../../status/actionTypes';
import {
  UserStatusesUpdateActionPayload,
  USERS_DETAILS_FAILED,
  USERS_DETAILS_LOADED,
  USERS_STATUSES_UPDATE,
  USERS_USER_RELOAD,
} from '../actions/details';
import Status from '../models/status';
import { IAction, IBaseAction, UserDependentAction } from '../types/actions';
import { NNProjects } from '../types/base';
import { UserDetailsState, UserStatusesMap } from '../types/state';

export const initialState: UserDetailsState = {};

export const initialStatusesMap: UserStatusesMap = {
  [NNProjects.EaseApp]: Status.getEmptyStatus(NNProjects.EaseApp),
  [NNProjects.BTAppWeb]: Status.getEmptyStatus(NNProjects.BTAppWeb),
  [NNProjects.BTApp]: Status.getEmptyStatus(NNProjects.BTApp),
};

export default function details(state = initialState, action: IBaseAction): UserDetailsState {
  switch (action.type) {
    case SearchActionTypes.USERS_SELECT_USER: {
      const userId = (action as SearchActionTypes.ISearchSelectUserAction).id;
      const existingState = state[userId];
      if (!existingState) {
        // if data was not loaded yet - start loading
        return {
          ...state,
          [userId]: {
            loaded: false,
            statuses: initialStatusesMap,
          },
        };
      } else {
        // if data already in state - pass it as it is
        return state;
      }
    }
    case StatusActionTypes.STATUS_TERMINATE_SUBMIT:
    case StatusActionTypes.STATUS_CHANGE_SUBMIT: {
      const userId = (action as
        | StatusActionTypes.ITerminateStatusSubmitAction
        | StatusActionTypes.IChangeStatusSubmitAction).id;
      const existingState = state[userId] ?? { statuses: initialStatusesMap };
      return {
        ...state,
        [userId]: {
          ...existingState,
          loaded: false,
        },
      };
    }
    case USERS_USER_RELOAD: {
      const userId = (action as UserDependentAction).payload.id;
      const existingState = state[userId] ?? { statuses: initialStatusesMap };
      return {
        ...state,
        [userId]: {
          ...existingState,
          loaded: false,
        },
      };
    }
    case USERS_STATUSES_UPDATE: {
      const userId = (action as IAction<UserStatusesUpdateActionPayload>).payload.id;
      const userDetails = (action as IAction<UserStatusesUpdateActionPayload>).payload.details;
      const userDetailsState = state[userId] ?? { loaded: false };
      return {
        ...state,
        [userId]: {
          ...userDetailsState,
          statuses: userDetails,
        },
      };
    }
    case StatusActionTypes.STATUS_CHANGE_FAIL: {
      const userId = (action as StatusActionTypes.IChangeStatusFailedAction).id;
      const userDetailsState = state[userId] ?? { statuses: initialStatusesMap };
      return {
        ...state,
        [userId]: {
          ...userDetailsState,
          loaded: true,
        },
      };
    }
    case USERS_DETAILS_FAILED:
    case USERS_DETAILS_LOADED: {
      const userId = (action as UserDependentAction).payload.id;
      const userDetailsState = state[userId] ?? { statuses: initialStatusesMap };
      return {
        ...state,
        [userId]: {
          ...userDetailsState,
          loaded: true,
        },
      };
    }
    default:
      return state;
  }
}
