import { Action } from 'redux';
import { Role } from '../state/models/user';

export const PROFILE_LOAD_USER = 'PROFILE_LOAD_USER';
export type PROFILE_LOAD_USER = typeof PROFILE_LOAD_USER;
export interface ILoadUserProfileAction extends Action<PROFILE_LOAD_USER> {
  type: PROFILE_LOAD_USER;
  userId: number;
}

export const PROFILE_SAVE_USER = 'PROFILE_SAVE_USER';
export type PROFILE_SAVE_USER = typeof PROFILE_SAVE_USER;
export interface ISaveUserProfileAction extends Action<PROFILE_SAVE_USER> {
  type: PROFILE_SAVE_USER;
  userId: number;
  mail?: string;
  password?: string;
  language?: string;
  timezone?: string;
  changeConfirmation?: string;
}

export const PROFILE_REMOVE_ROLE = 'PROFILE_REMOVE_ROLE';
export type PROFILE_REMOVE_ROLE = typeof PROFILE_REMOVE_ROLE;
export interface IRemoveRoleAction extends Action<PROFILE_REMOVE_ROLE> {
  type: PROFILE_REMOVE_ROLE;
  userId: number;
  roleId: Role;
}

export const PROFILE_ADD_ROLE = 'PROFILE_ADD_ROLE';
export type PROFILE_ADD_ROLE = typeof PROFILE_ADD_ROLE;
export interface IAddRoleAction extends Action<PROFILE_ADD_ROLE> {
  type: PROFILE_ADD_ROLE;
  userId: number;
  roleId: Role;
}

export type IProfileAction = ILoadUserProfileAction | ISaveUserProfileAction | IRemoveRoleAction | IAddRoleAction;
