import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { NotificationActions } from '../notifications';
import { ApiResponse } from '../services/apiResponse';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import { IUserStatGet } from './types';
import UserStatsService from './userStatsService';

export function* fetchStats(userId: number) {
  let response;
  try {
    response = new ApiResponse(yield call(UserStatsService.getStats, userId));
  } catch (error) {
    response = new ApiResponse((error as HttpError).baseError ?? error);
  }
  if (response.isError()) {
    yield put(NotificationActions.showWarning('User stats loading failed. User does not exist?'));
    return;
  }
  const stats = response.data.stats as IUserStatGet[];
  yield put(Actions.updateUserStatsAction(userId, stats));
}

export function* loadUserStatsSaga({ userId }: ActionTypes.ILoadUserStatsAction) {
  if (!UserStatsService.isReady()) {
    UserStatsService.init(ApiService);
  }
  yield fork(fetchStats, userId);
}

export function* saveUserStatsSaga(action: ActionTypes.ISaveUserStatsAction) {
  if (!UserStatsService.isReady()) {
    UserStatsService.init(ApiService);
  }
  const { userId, stats } = action;
  let response;
  try {
    response = new ApiResponse(yield call(UserStatsService.saveStats, userId, stats));
  } catch (error) {
    response = new ApiResponse((error as HttpError).baseError ?? error);
  }
  if (response.isError()) {
    yield put(NotificationActions.showError('User stats saving failed!'));
    return;
  }
  yield fork(fetchStats, userId);
}

export default function* userStatsWatcher() {
  yield all([
    takeEvery(ActionTypes.USER_STATS_LOAD, loadUserStatsSaga),
    takeEvery(ActionTypes.USER_STATS_SAVE, saveUserStatsSaga),
  ]);
}
