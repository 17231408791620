import * as ActionTypes from './actionTypes';
import { IUserStatGet, IUserStatPost } from './types';
import { parseStat, convertIUserStats2IUserStatPosts } from './utils';

// update user stats for given user
export const updateUserStatsAction: (userId: number, stats: IUserStatGet[]) => ActionTypes.IUpdateUserStatsAction = (
  userId,
  stats
) => ({
  type: ActionTypes.USER_STATS_UPDATE,
  userId,
  stats,
});

// save user stats for given user
export const saveUserStatsAction: (userId: number, stats: IUserStatPost[]) => ActionTypes.ISaveUserStatsAction = (
  userId,
  stats
) => ({
  type: ActionTypes.USER_STATS_SAVE,
  userId,
  stats,
});

// load user stats for given user
export const loadUserStatsAction: (userId: number) => ActionTypes.ILoadUserStatsAction = (userId) => ({
  type: ActionTypes.USER_STATS_LOAD,
  userId,
});

export const saveUserStatById: (
  userId: number,
  statId: string,
  value: number | string
) => ActionTypes.ISaveUserStatsAction = (userId, statId, value) => {
  const stat = parseStat(statId, value);
  const postStats = convertIUserStats2IUserStatPosts([stat]);
  return saveUserStatsAction(userId, postStats);
};
