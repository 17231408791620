import * as SearchActionTypes from '../../search/actionTypes';
import {
  ADMIN_CHECK_NEWSLETTER_MULTIPLE,
  ADMIN_CHECK_NEWSLETTER_RESULT,
  ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE_SUCCESS,
  NewsletterCheckResultPayload,
  ADMIN_SIGNIN_AS_USER_RESPONSE,
  UserTokenResponsePayload,
} from '../actions/admin';
import { IAction, IBaseAction } from '../types/actions';
import { AdminState } from '../types/state';

export const initialState: AdminState = {};

export default function admin(state = initialState, action: IBaseAction) {
  switch (action.type) {
    case SearchActionTypes.USERS_SEARCH_USERNAME:
    case SearchActionTypes.USERS_SEARCH_ID:
      return {};
    case ADMIN_CHECK_NEWSLETTER_MULTIPLE:
      return {
        multiple: true,
      };
    case ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE_SUCCESS:
      return {
        newsletter: false,
      };
    case ADMIN_CHECK_NEWSLETTER_RESULT:
      const newsletter = (action as IAction<NewsletterCheckResultPayload>).payload.newsletter;
      return {
        newsletter,
      };
    case ADMIN_SIGNIN_AS_USER_RESPONSE: {
      const { session } = (action as IAction<UserTokenResponsePayload>).payload;
      return {
        session,
      };
    }
  }
  return state;
}
