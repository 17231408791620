import forOwn from 'lodash/forOwn';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiService, HttpError } from 'web_core_library';
import { NotificationActions } from '../../notifications';
import { getSearchForm } from '../../search/selectors';
import AdminService from '../../services/adminService';
import { ApiResponse } from '../../services/apiResponse';
import { HttpStatusCode } from '../../services/constants';
import {
  adminCheckNewsletterResultAction,
  adminNewsletterMultipleAction,
  adminNewsletterUnsubscribeSuccessAction,
  adminSigninAsUserResponseAction,
  ADMIN_CHECK_NEWSLETTER,
  ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE,
  ADMIN_INIT,
  ADMIN_SIGNIN_AS_USER_REQUEST,
  NewsletterCheckPayload,
} from '../actions/admin';
import { IAction, UserDependentAction } from '../types/actions';
import { NewsletterStatus } from '../types/base';

export function* handleServiceInit() {
  yield call(AdminService.init, ApiService);
}

// check if email is subscribed to our newsletter
export function* checkNewsletterSaga(action: IAction<NewsletterCheckPayload>) {
  let apiResponse: ApiResponse;
  try {
    const email = action.payload.mail;
    const response = yield call(AdminService.getNewsletterStatus, email);
    apiResponse = new ApiResponse(response);
  } catch (error) {
    apiResponse = new ApiResponse((error as HttpError).baseError ?? error);
  }
  if (apiResponse.isSuccess()) {
    const newsletterStatus: NewsletterStatus = apiResponse.data;
    let newsletter = false;
    forOwn(newsletterStatus, (status: boolean) => {
      newsletter = newsletter || status;
    });
    yield put(adminCheckNewsletterResultAction(newsletter));
  } else {
    if (apiResponse.status === HttpStatusCode.InvalidData) {
      yield put(adminNewsletterMultipleAction());
    } else {
      yield put(NotificationActions.showError(`Newsletter status fetching failed: ${apiResponse.statusText}`));
    }
  }
}

// unsubscribe email from newsletter
export function* unsubscribeNewsletterSaga() {
  try {
    const { email } = yield select(getSearchForm);
    const response = yield call(AdminService.unsubscribeEmail, email);
    const apiResponse = new ApiResponse(response);
    if (apiResponse.isSuccess()) {
      yield put(adminNewsletterUnsubscribeSuccessAction());
    }
  } catch (error) {
    yield put(NotificationActions.showError(`API request failed: ${error.message}`));
  }
}

export function* signinAsUserSaga(action: UserDependentAction) {
  const id = action.payload.id;
  let token = '';
  let apiResponse: ApiResponse;
  try {
    const response = yield call(AdminService.getSwitchToken, id);
    apiResponse = new ApiResponse(response);
  } catch (error) {
    console.error(error);
    apiResponse = new ApiResponse((error as HttpError).baseError ?? error);
    yield put(NotificationActions.showError(error.message ?? 'Error during fetching users token!'));
  }
  if (apiResponse.isSuccess()) {
    const { result } = apiResponse.data;
    const { sessionToken } = result;
    token = sessionToken;
  }
  yield put(adminSigninAsUserResponseAction(id, token));
}

export default function* adminWatcher() {
  yield all([
    takeEvery(ADMIN_INIT, handleServiceInit),
    takeEvery(ADMIN_CHECK_NEWSLETTER, checkNewsletterSaga),
    takeEvery(ADMIN_CHECK_NEWSLETTER_UNSUBSCRIBE, unsubscribeNewsletterSaga),
    takeEvery(ADMIN_SIGNIN_AS_USER_REQUEST, signinAsUserSaga),
  ]);
}
