import * as ActionTypes from './actionTypes';
import { ICycleStatus } from './types';
import { mergeCycles } from './utils';

export interface ICyclesState {
  userId: number;
  loading: boolean;
  finishedCycles: number;
  finishedSessions: number;
  currentCycleId: number;
  currentCycleLastSessionId: number;
  currentCycleLength: number;
  trainingcycles: ICycleStatus[];
}

export const initialState: ICyclesState = {
  userId: 0,
  loading: true,
  finishedCycles: 0,
  finishedSessions: 0,
  currentCycleId: 0,
  currentCycleLastSessionId: 0,
  currentCycleLength: 0,
  trainingcycles: [],
};

export const statePropName = 'cycles';

export default function cyclesReducer(
  state: ICyclesState = initialState,
  action: ActionTypes.ICyclesActions
): ICyclesState {
  switch (action.type) {
    case ActionTypes.CYCLES_LOAD: {
      const { userId } = action;
      return {
        // reset state
        ...initialState,
        // store userId
        userId,
      };
    }
    case ActionTypes.CYCLES_STATS: {
      const {
        finishedCycles,
        finishedSessions,
        currentCycleId,
        currentCycleLength,
        currentCycleLastSessionId,
      } = action;
      return {
        ...state,
        loading: false,
        finishedCycles,
        finishedSessions,
        currentCycleId,
        currentCycleLength,
        currentCycleLastSessionId,
      };
    }
    case ActionTypes.SESSION_UPDATE: {
      const { cycle } = action;
      return {
        ...state,
        trainingcycles: mergeCycles(state.trainingcycles, [cycle]),
      };
    }
    case ActionTypes.RESET_TRAINING: {
      return {
        ...state,
        loading: true,
      };
    }
  }
  return state;
}
