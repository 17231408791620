import ExerciseReportsFilters from './ExerciseReportsFilters';
import ExerciseReportsReports from './ExerciseReportsReports';

export default function ExerciseReportsTab() {
  return (
    <div className="tab-contents">
      <ExerciseReportsFilters />
      <ExerciseReportsReports />
    </div>
  );
}
