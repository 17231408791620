// known http status codes
export const HttpStatusCode = {
  Created: 201,
  Forbidden: 403,
  InternalError: 0,
  InvalidData: 422,
  MethodNotAllowed: 405,
  NoContent: 204,
  NotFound: 404,
  Ok: 200,
  ServiceUnavailable: 503,
  TokenExpired: 412,
  TooManyRequests: 429,
  Unauthorized: 401,
  Unknown: 500,
};

export const KeyboardKeys = {
  Enter: 13,
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function isProduction() {
  const domain = window.location.hostname;
  const isHeroku = /heroku/i.test(domain);
  const isDev = /dev\./i.test(domain);
  return !isLocalhost && !isHeroku && !isDev;
}

export function getChargebeeUrl() {
  if (isProduction()) {
    return 'https://neuronation.chargebee.com/d/subscriptions/';
  }
  return 'https://neuronation-test.chargebee.com/d/subscriptions/';
}
