import { Button, IconName } from '@blueprintjs/core';
import { PropsWithChildren } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface INavButtonProps {
  icon?: IconName;
  to: string;
  exact?: boolean;
}

export default function NavButton({ icon, to, exact = false, children }: PropsWithChildren<INavButtonProps>) {
  const history = useHistory();
  const match = useRouteMatch({
    path: to,
    exact,
  });
  const active = !!match;
  const navigate = () => history.push(to);
  return (
    <Button icon={icon} active={active} onClick={navigate}>
      {children}
    </Button>
  );
}
