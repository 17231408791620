import { FormGroup } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { useSelector } from 'react-redux';
import { formatDate, parseDate } from '../../../services/dateUtils';
import { getFiltersDateRange } from '../../selectors';
import { DateRange } from '../../types';

interface IDateRangeFilterProps {
  dateRange: DateRange;
  onChange: (range: DateRange) => void;
}

export default function DateRangeFilter({ dateRange, onChange }: IDateRangeFilterProps) {
  const [timestampFrom, timestampTo] = useSelector(getFiltersDateRange);
  return (
    <FormGroup label="Select date range for reports:">
      <DateRangeInput
        startInputProps={{ placeholder: 'from' }}
        endInputProps={{ placeholder: 'to' }}
        value={dateRange}
        onChange={onChange}
        parseDate={parseDate}
        formatDate={formatDate}
        minDate={parseDate(timestampFrom)}
        maxDate={parseDate(timestampTo)}
        allowSingleDayRange={true}
      />
    </FormGroup>
  );
}
