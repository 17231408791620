import { ActionType, IAction, IBaseAction } from '../types/actions';

/**
 * factory function to create actions in consistent way
 * @param type type of the action to create
 * @param argNames list of arguments names to be provided to the action (can be undefined)
 */
export function actionBuilder<P = any>(type: ActionType, ...argNames: string[]) {
  return (...args: any[]) => {
    const action: IBaseAction = { type };
    if (argNames && argNames.length > 0) {
      const payload = {};
      argNames.forEach((arg, index) => {
        // @ts-ignore
        payload[argNames[index]] = args[index];
      });
      (action as IAction<typeof payload>).payload = payload as P;
    }
    return action;
  };
}
