import { Button, Section, SectionCard } from '@blueprintjs/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openFeedbackTabAction } from '../../actions';
import { TFeedbackTabs } from '../../types';

interface IFiltersSectionProps extends React.PropsWithChildren {
  tab: TFeedbackTabs;
  onReload?: () => void;
}

export default function FiltersSection({ children, tab, onReload }: IFiltersSectionProps) {
  const dispatch = useDispatch();
  const reloadFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(openFeedbackTabAction(tab, true));
    onReload && onReload();
  };

  return (
    <Section
      className="filters"
      title="Choose filters:"
      collapsible={true}
      icon="filter-list"
      rightElement={<Button minimal={true} icon="refresh" onClick={reloadFilters} />}>
      <SectionCard className="section">{children}</SectionCard>
    </Section>
  );
}
