import filter from 'lodash/filter';
import find from 'lodash/find';
import last from 'lodash/last';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { SubscriptionProjects } from '../types/base';
import { ILegacySubscription, ISubscription, ISubscriptionData } from '../types/state';

const parseSingleSubscription = (subscription: ISubscriptionData) => {
  const { createdAt, expiresAt, nextCharge, trialEndsAt } = subscription;
  const parsedSubscription = {
    ...subscription,
    createdAt: moment(createdAt),
    expiresAt: moment(expiresAt),
    nextCharge: nextCharge ? moment(nextCharge) : undefined,
    trialEndsAt: trialEndsAt ? moment(trialEndsAt) : undefined,
  };
  return parsedSubscription as ISubscription;
};

const parseSubscriptions = (subscriptions: ISubscriptionData[]) => {
  return map(subscriptions, parseSingleSubscription);
};

const filterSubscriptionByProject = (subscriptions: ISubscription[], project: SubscriptionProjects) => {
  return filter(subscriptions, (s) => {
    if (project !== SubscriptionProjects.Neuronation) {
      return (s as ILegacySubscription).project === project;
    }
    return (s as ILegacySubscription).project === project || (s as any).project === undefined;
  });
};

const sortSubscriptionsByCreationDate = (subscriptions: ISubscription[]) => {
  return sortBy(subscriptions, (s: ISubscription) => s.createdAt.valueOf());
};

const getActualProjectSubscription = (subscriptions: ISubscriptionData[], project: SubscriptionProjects) => {
  return last(sortSubscriptionsByCreationDate(filterSubscriptionByProject(parseSubscriptions(subscriptions), project)));
};

const getEaseSubscription = (subscriptions: ISubscriptionData[]) => {
  const easeSub = getActualProjectSubscription(subscriptions, SubscriptionProjects.Ease);
  const bundleSub = getActualProjectSubscription(subscriptions, SubscriptionProjects.NNAndEaseBundle);
  // take one subscription which is defined
  let subscription = easeSub || bundleSub;
  // if both subscriptions are present
  if (easeSub && bundleSub) {
    // get the lates one
    subscription = easeSub.expiresAt && easeSub.expiresAt.isSameOrAfter(bundleSub.expiresAt) ? easeSub : bundleSub;
  }
  return subscription;
};

const getBTSubscription = (subscriptions: ISubscriptionData[]) => {
  const btSub = getActualProjectSubscription(subscriptions, SubscriptionProjects.Neuronation);
  const bundleSub = getActualProjectSubscription(subscriptions, SubscriptionProjects.NNAndEaseBundle);
  // take one subscription which is defined
  let subscription = btSub || bundleSub;
  // if both subscriptions are present
  if (btSub && bundleSub) {
    // get the lates one
    subscription = btSub.expiresAt && btSub.expiresAt.isSameOrAfter(bundleSub.expiresAt) ? btSub : bundleSub;
  }
  return subscription;
};

const getSubscriptionById = (id: string | null, subscriptions: ISubscriptionData[]) => {
  const subscriptionId = id ? +id : 0;
  const subscription = find(subscriptions, ['id', subscriptionId]);
  if (!subscription) {
    return;
  }
  return parseSingleSubscription(subscription);
};

const Subscription = {
  getBTSubscription,
  getEaseSubscription,
  getSubscriptionById,
};

export default Subscription;
