import { all, call, put, takeEvery } from 'redux-saga/effects';
import { TApiFetchResponse } from 'web_core_library';
import { NotificationActions } from '../notifications';
import { updateEmailHistoryAction } from './actions';
import * as ActionTypes from './actionTypes';
import EmailHistoryService from './emailHistoryService';
import { sortEmailsByDate } from './utils';

export function* loadEmailHistorySaga(action: ActionTypes.ILoadUserEmailsAction) {
  try {
    const userId = action.userId;
    const result: TApiFetchResponse<typeof EmailHistoryService.getEmailHistory> = yield call(
      EmailHistoryService.getEmailHistory,
      userId
    );
    const error = result.data.error;
    if (error) {
      yield put(NotificationActions.showError(error));
    }
    const emails = sortEmailsByDate(result.data.emails);
    yield put(updateEmailHistoryAction(emails));
  } catch (error) {
    const internalMessage = (error as Error).message;
    yield put(NotificationActions.showError(`Failed to fetch emails! ${internalMessage}`));
    yield put(updateEmailHistoryAction([]));
  }
}

export default function* emailHistoryWatcher() {
  yield all([takeEvery(ActionTypes.EMAILS_LOAD_USER, loadEmailHistorySaga)]);
}
