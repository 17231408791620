import { FormGroup, InputGroup } from '@blueprintjs/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardKeys } from '../services/constants';
import { searchCouponAction } from './actions';
import { getCoupon } from './selectors';

export default function CouponSearchInput() {
  const dispatch = useDispatch();
  const [coupon, setCoupon] = React.useState<string>('');
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => setCoupon(e.target.value);
  const keyHandler: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.charCode !== KeyboardKeys.Enter) {
      return;
    }
    dispatch(searchCouponAction(coupon));
  };
  const submittedCoupon = useSelector(getCoupon);
  React.useEffect(() => {
    // clear coupon value when reset after another search
    setCoupon(submittedCoupon || '');
  }, [submittedCoupon]);
  return (
    <FormGroup label="Coupon" labelFor="coupon-input">
      <InputGroup
        id="coupon-input"
        onChange={changeHandler}
        onKeyPress={keyHandler}
        value={coupon}
        large={true}
        placeholder="Coupon"
      />
    </FormGroup>
  );
}
