import { AppState } from '../state/reducers';
import { getUserProjectStatus } from '../state/selectors';

export const getChangeUserStatusDialogState = (state: AppState) => state.status.open;

export const getChangeStatusForm = (state: AppState) => state.status.form;

export const getChangeStatusDialogProject = (state: AppState) => state.status.project;

export const getTerminateStatusAlertState = (state: AppState) => state.status.terminate;

export const getTerminateStatusImmediatelyValue = (state: AppState) => state.status.immediately;

export const getStatusCreatedDate = (state: AppState, userId: number) => {
  const project = getChangeStatusDialogProject(state);
  if (!project) {
    return;
  }
  const status = getUserProjectStatus(state, userId, project);
  return status.createdAt;
};
