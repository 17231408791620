import { AxiosError } from 'axios';
import { all, call, put, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import { NotificationActions } from '../notifications';
import { searchUserByIdAction, updateNameFieldAction } from '../search/actions';
import { HttpStatusCode } from '../services/constants';
import * as Actions from './actions';
import * as ActionTypes from './actionTypes';
import CouponService from './service';

export default function* couponWatcher() {
  yield all([
    takeEvery(ActionTypes.COUPON_INIT, handleServiceInit),
    takeLatest(ActionTypes.COUPON_SEARCH, handleSearchCoupon),
  ]);
}

export function* handleServiceInit() {
  yield call(CouponService.init, ApiService);
}

export function* searchForUser(userId: number) {
  yield put(searchUserByIdAction(userId));
  // we can add additional handling for non existing users here
}

export function* handleSearchCoupon({ coupon }: ActionTypes.ISearchCouponAction) {
  // reset normal search form
  yield put(updateNameFieldAction(''));
  try {
    // fetch coupon status from BE
    const response: TApiFetchResponse<typeof CouponService.getCouponStatus> = yield call(
      CouponService.getCouponStatus,
      coupon
    );
    yield put(Actions.couponSearchResultAction(response.data));
    const userId = response.data.used_by_user_id;
    if (userId !== null) {
      yield spawn(searchForUser, userId);
    }
  } catch (error) {
    if ((error as AxiosError).response?.status === HttpStatusCode.NotFound) {
      // handle not found error explicitly
      yield put(Actions.couponNotFoundAction());
      yield put(NotificationActions.showError('Coupon not found!'));
      return;
    }
    const message = (error as AxiosError).response?.statusText || error.message;
    yield put(NotificationActions.showError(message));
  }
}
